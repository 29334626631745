import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Switch,
    Tabs,
    Tooltip,
    Typography,
    Upload
} from 'antd';
import {CopyOutlined, MinusCircleOutlined, UploadOutlined} from '@ant-design/icons';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    addNewDraftSitePages,
    addNewPostingBackgroundImg,
    addNewPostingBannerImg,
    addNewPostingFeatureImg,
    addNewPostingStaticImage,
    addNewUrl,
    addSitePagesHistory,
    deleteSitePagesHistory,
    deleteStaticImage,
    editPostingImages,
    editSitePages,
    getAllSectionsCategory,
    getLanguages,
    getSidebars,
    getSitePagesHistory,
    pageChecker,
    removeUrl,
    searchDraftSitePages,
    searchPageID,
    searchSitePages,
    searchUrl,
    takeControlPageChecker,
    updateDraftSitePages,
    updatePageChecker
} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import Keys from "../../_config";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {html} from "@codemirror/lang-html";

function truncateString(str, num) {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}

export default function EditPosting() {

    const [languageList, setLanguageList] = useState(false);
    const [siteDetails, setSiteDetails] = useState(false);
    const [friendlyURL, setFriendlyURL] = useState(false);
    const [currentFriendlyURL, setCurrentFriendlyURL] = useState(false);
    const [friendlyURLInvalid, setFriendlyURLInvalid] = useState(false);
    const [pageIDs, setPageID] = useState("");
    const [currentPageID, setCurrentPageID] = useState("");
    const [pageIDInvalid, setPageIDInvalid] = useState(false);
    const [sidebarList, setSidebarList] = useState(false);
    const [pageActive, setPageActive] = useState();
    const [sectionCategory, setSectionCategory] = useState(false);

    const [historyIDs, setHistoryIDs] = useState([]);
    const [versionID, setVersionID] = useState("");
    const [commitMessage, setCommitMessage] = useState("No Message");
    const [saveData, setSaveData] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommitModalOpen, setIsCommitModalOpen] = useState(false);

    const [draftID, setDraftID] = useState("");
    const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const [javascriptCount, setJavascriptCount] = useState([]);
    const [javascriptCountStatus, setJavascriptCountStatus] = useState("");
    const [pageActiveUUID, setPageActiveUUID] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const {TextArea} = Input;
    const {Option} = Select;
    const [formRef] = Form.useForm();
    const {Text, Link} = Typography;
    let {infoID} = useParams();

    const pushLanguagesData = async (item, values) => {


        let custom = {}
        let title = {}
        let shortDescription = {}
        let metaDescription = {}
        let metaKeywords = {}
        let banner = {}
        let crmImage = {}
        let otherImage1 = {}
        let otherImage2 = {}
        let otherImage3 = {}
        let otherImage4 = {}

        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        //title
        var titleClass = "title-" + item.code
        var titleDetails = {
            language: item.code,
            title: values[titleClass],
        }
        title = titleDetails

        //Short Description
        var descriptionShortClass = "shortDescription-" + item.code
        var descriptionShortDetails = {
            language: item.code,
            description: values[descriptionShortClass],
        }
        shortDescription = descriptionShortDetails;

        //Meta Description
        var descriptionClass = "seoDescription-" + item.code
        var descriptionDetails = {
            language: item.code,
            description: values[descriptionClass],
        }
        metaDescription = descriptionDetails;

        var keywordsClass = "seoKeyword-" + item.code
        var keywordsDetails = {
            language: item.code,
            keywords: values[keywordsClass],
        }
        metaKeywords = keywordsDetails;

        var bannerClass = "banner-" + item.code

        let oldBannerPath = siteDetails.bannerImage !== undefined && siteDetails.bannerImage.find((res) => res.language === item.code) && siteDetails.bannerImage.find((res) => res.language === item.code).bannerPath !== false && siteDetails.bannerImage.find((res) => res.language === item.code).bannerPath !== undefined ? siteDetails.bannerImage.find((res) => res.language === item.code).bannerPath : ""

        if (values[bannerClass] !== undefined && values[bannerClass].file.originFileObj !== undefined) {

            let oldPath = oldBannerPath

            if (oldBannerPath !== "") {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(oldPath)[0]
                })
            }

            await addNewPostingBannerImg({
                bannerImage: values[bannerClass].file.originFileObj
            }).then((res) => {
                var bannerDetails = {
                    language: item.code,
                    bannerPath: res,
                }
                banner = bannerDetails;
            })

        } else {
            banner = siteDetails.bannerImage && siteDetails.bannerImage.length > 0 && siteDetails.bannerImage.find((res) => res.language === item.code) ? siteDetails.bannerImage.find((res) => res.language === item.code) : ""
        }


        var CrmImageClass = "crm-image-" + item.code

        let oldCrmImagePath = siteDetails.crmImage !== undefined && siteDetails.crmImage.find((res) => res.language === item.code) && siteDetails.crmImage.find((res) => res.language === item.code).crmImage !== false && siteDetails.crmImage.find((res) => res.language === item.code).crmImage !== undefined ? siteDetails.crmImage.find((res) => res.language === item.code).crmImage : ""


        if (values[CrmImageClass] !== undefined && values[CrmImageClass].file.originFileObj !== undefined) {

            let oldPath = oldCrmImagePath

            if (oldCrmImagePath !== "") {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(oldPath)[0]
                })
            }

            await addNewPostingStaticImage({
                image: values[CrmImageClass].file.originFileObj
            }).then((res) => {
                var crmImageDetails = {
                    language: item.code,
                    path: res,
                }
                crmImage = crmImageDetails;
            })

        } else {
            crmImage = siteDetails.crmImage && siteDetails.crmImage.length > 0 && siteDetails.crmImage.find((res) => res.language === item.code) ? siteDetails.crmImage.find((res) => res.language === item.code) : ""

        }

        var Other1ImageClass = "other-image-1-" + item.code

        let oldOther1ImagePath = siteDetails.otherImage1 !== undefined && siteDetails.otherImage1.find((res) => res.language === item.code) && siteDetails.otherImage1.find((res) => res.language === item.code).otherImage1 !== false && siteDetails.otherImage1.find((res) => res.language === item.code).otherImage1 !== undefined ? siteDetails.otherImage1.find((res) => res.language === item.code).otherImage1 : ""

        if (values[Other1ImageClass] !== undefined && values[Other1ImageClass].file.originFileObj !== undefined) {

            let oldPath = oldOther1ImagePath

            if (oldOther1ImagePath !== "") {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(oldPath)[0]
                })
            }

            await addNewPostingStaticImage({
                image: values[Other1ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage1Details = {
                    language: item.code,
                    path: res,
                }
                otherImage1 = otherImage1Details;
            })

        } else {
            otherImage1 = siteDetails.otherImage1 && siteDetails.otherImage1.length > 0 && siteDetails.otherImage1.find((res) => res.language === item.code) ? siteDetails.otherImage1.find((res) => res.language === item.code) : ""
        }

        //new
        var Other2ImageClass = "other-image-2-" + item.code

        let oldOther2ImagePath = siteDetails.otherImage2 !== undefined && siteDetails.otherImage2.find((res) => res.language === item.code) && siteDetails.otherImage2.find((res) => res.language === item.code).otherImage2 !== false && siteDetails.otherImage2.find((res) => res.language === item.code).otherImage2 !== undefined ? siteDetails.otherImage2.find((res) => res.language === item.code).otherImage2 : ""

        if (values[Other2ImageClass] !== undefined && values[Other2ImageClass].file.originFileObj !== undefined) {

            let oldPath = oldOther2ImagePath

            if (oldOther2ImagePath !== "") {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(oldPath)[0]
                })
            }

            await addNewPostingStaticImage({
                image: values[Other2ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage2Details = {
                    language: item.code,
                    path: res,
                }
                otherImage2 = otherImage2Details;
            })

        } else {
            otherImage2 = siteDetails.otherImage2 && siteDetails.otherImage2.length > 0 && siteDetails.otherImage2.find((res) => res.language === item.code) ? siteDetails.otherImage2.find((res) => res.language === item.code) : ""
        }

        //new
        var Other3ImageClass = "other-image-3-" + item.code

        let oldOther3ImagePath = siteDetails.otherImage3 !== undefined && siteDetails.otherImage3.find((res) => res.language === item.code) && siteDetails.otherImage3.find((res) => res.language === item.code).otherImage3 !== false && siteDetails.otherImage3.find((res) => res.language === item.code).otherImage3 !== undefined ? siteDetails.otherImage3.find((res) => res.language === item.code).otherImage3 : ""

        if (values[Other3ImageClass] !== undefined && values[Other3ImageClass].file.originFileObj !== undefined) {

            let oldPath = oldOther3ImagePath

            if (oldOther3ImagePath !== "") {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(oldPath)[0]
                })
            }

            await addNewPostingStaticImage({
                image: values[Other3ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage3Details = {
                    language: item.code,
                    path: res,
                }
                otherImage3 = otherImage3Details;
            })

        } else {
            otherImage3 = siteDetails.otherImage3 && siteDetails.otherImage3.length > 0 && siteDetails.otherImage3.find((res) => res.language === item.code) ? siteDetails.otherImage3.find((res) => res.language === item.code) : ""
        }

        //new
        var Other4ImageClass = "other-image-4-" + item.code

        let oldOther4ImagePath = siteDetails.otherImage4 !== undefined && siteDetails.otherImage4.find((res) => res.language === item.code) && siteDetails.otherImage4.find((res) => res.language === item.code).otherImage4 !== false && siteDetails.otherImage4.find((res) => res.language === item.code).otherImage4 !== undefined ? siteDetails.otherImage4.find((res) => res.language === item.code).otherImage4 : ""

        if (values[Other4ImageClass] !== undefined && values[Other4ImageClass].file.originFileObj !== undefined) {

            let oldPath = oldOther4ImagePath

            if (oldOther4ImagePath !== "") {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(oldPath)[0]
                })
            }

            await addNewPostingStaticImage({
                image: values[Other4ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage4Details = {
                    language: item.code,
                    path: res,
                }
                otherImage4 = otherImage4Details;
            })

        } else {
            otherImage4 = siteDetails.otherImage4 && siteDetails.otherImage4.length > 0 && siteDetails.otherImage4.find((res) => res.language === item.code) ? siteDetails.otherImage4.find((res) => res.language === item.code) : ""
        }


        return {
            custom,
            shortDescription,
            metaDescription,
            metaKeywords,
            title,
            banner,
            crmImage,
            otherImage1,
            otherImage2,
            otherImage3,
            otherImage4
        }
    }

    const pushLanguagesDataDraft = async (item, values) => {

        let custom = {}
        let title = {}
        let shortDescription = {}
        let metaDescription = {}
        let metaKeywords = {}


        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        //title
        var titleClass = "title-" + item.code
        var titleDetails = {
            language: item.code,
            title: values[titleClass],
        }
        title = titleDetails

        //Short Description
        var descriptionShortClass = "shortDescription-" + item.code
        var descriptionShortDetails = {
            language: item.code,
            description: values[descriptionShortClass],
        }
        shortDescription = descriptionShortDetails;

        //Meta Description
        var descriptionClass = "seoDescription-" + item.code
        var descriptionDetails = {
            language: item.code,
            description: values[descriptionClass],
        }
        metaDescription = descriptionDetails;

        var keywordsClass = "seoKeyword-" + item.code
        var keywordsDetails = {
            language: item.code,
            keywords: values[keywordsClass],
        }
        metaKeywords = keywordsDetails;

        return {custom, shortDescription, metaDescription, metaKeywords, title}
    }

    const onFinish = async () => {

        let values = saveData

        let CustomDetails = [];
        let shortDescription = [];
        let MetaDescription = [];
        let MetaKeywords = [];
        let titles = [];
        let banner = [];
        let historyIds = [];
        let crmImage = [];
        let otherImage1 = [];
        let otherImage2 = [];
        let otherImage3 = [];
        let otherImage4 = [];

        if (Array.isArray(values?.scripts) && values?.scripts.length > 0) {
            values.scripts = await values?.scripts.filter(value => value !== null && value !=="" && value !== undefined);
        }

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
                shortDescription.push(v.shortDescription)
                MetaDescription.push(v.metaDescription)
                MetaKeywords.push(v.metaKeywords)
                titles.push(v.title)
                banner.push(v.banner)
                crmImage.push(v.crmImage)
                otherImage1.push(v.otherImage1)
                otherImage2.push(v.otherImage2)
                otherImage3.push(v.otherImage3)
                otherImage4.push(v.otherImage4)
            })
        })

        addSitePagesHistory({
            title: titles,
            sidebarID: values?.sidebarID,
            pageID: pageIDs,
            details: CustomDetails,
            scripts: values?.scripts,
            shortDescription: shortDescription,
            metaDescription: MetaDescription,
            metaKeywords: MetaKeywords,
        }).then(async (history) => {
            historyIds = historyIDs;
            let timeStamp = Math.round((new Date()).getTime() / 1000);
            let date = new Date(timeStamp * 1000)
            let lastModified = date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")
            await historyIds.push({
                timestamp: timeStamp,
                dateTime: lastModified,
                id: history._id,
                message: commitMessage
            })

            if (historyIds.length > 10) {
                let dateAry = [];
                historyIds.map((history, i) => {
                    dateAry.push(history.timestamp)
                })
                const oldest = dateAry.reduce((c, n) =>
                    n < c ? n : c
                );
                let deleteObj = historyIds.find(
                    (item) => item.timestamp === oldest
                )

                if (deleteObj) {
                    deleteSitePagesHistory({
                        documentID: deleteObj.id
                    }).then((res) => {
                        historyIds.splice(historyIds.indexOf(deleteObj), 1)
                        editSitePages(
                            {
                                title: titles,
                                sidebarID: values?.sidebarID,
                                dynamicPath: "/" + friendlyURL,
                                pageID: pageIDs,
                                categoryID: values?.categoryID,
                                details: CustomDetails,
                                scripts: values?.scripts,
                                shortDescription: shortDescription,
                                metaDescription: MetaDescription,
                                metaKeywords: MetaKeywords,
                                active: pageActive,
                                documentID: infoID,
                                historyIDs: historyIds,
                                historyID: history._id,
                                draftStatus: "inactive",
                                lastModified: lastModified
                            }
                        ).then(async (res) => {


                            editPostingImages({
                                documentID: infoID,
                                bannerImage: banner,
                                crmImage: crmImage,
                                otherImage1: otherImage1,
                                otherImage2: otherImage2,
                                otherImage3: otherImage3,
                                otherImage4: otherImage4
                            })


                            if (values?.featureImage) {
                                addNewPostingFeatureImg({
                                    documentID: infoID,
                                    file: values?.featureImage.file.originFileObj,
                                }).then((res) => {
                                    if (siteDetails.featureImage !== undefined) {
                                        deleteStaticImage({
                                            fileName: /[^/]*$/.exec(siteDetails.featureImage)[0]
                                        })
                                    }
                                })

                            }

                            if (values?.backgroundImage) {
                                addNewPostingBackgroundImg({
                                    documentID: infoID,
                                    file: values?.backgroundImage.file.originFileObj,
                                }).then((res) => {
                                    if (siteDetails.backgroundImage !== undefined) {
                                        deleteStaticImage({
                                            fileName: /[^/]*$/.exec(siteDetails.backgroundImage)[0]
                                        })
                                    }
                                })

                            }

                            if (values?.seoUrl !== currentFriendlyURL) {
                                removeUrl({
                                    path: "/" + currentFriendlyURL
                                }).then((res) => {
                                    addNewUrl({
                                        path: "/" + values?.seoUrl,
                                        type: "Site Pages",
                                        referralID: infoID,
                                    })
                                })

                            }

                            // navigate(-1)
                            await setSiteDetails(false)
                            setIsCommitModalOpen(false)
                            dispatchSitePageDetails()
                            message.success('Article updated successfully');
                        })
                    })
                }

            } else {
                editSitePages(
                    {
                        title: titles,
                        sidebarID: values?.sidebarID,
                        dynamicPath: "/" + friendlyURL,
                        pageID: pageIDs,
                        categoryID: values?.categoryID,
                        details: CustomDetails,
                        scripts: values?.scripts,
                        shortDescription: shortDescription,
                        metaDescription: MetaDescription,
                        metaKeywords: MetaKeywords,
                        active: pageActive,
                        documentID: infoID,
                        historyIDs: historyIds,
                        historyID: history._id,
                        draftStatus: "inactive",
                        lastModified: lastModified
                    }
                ).then(async (res) => {



                    editPostingImages({
                        documentID: infoID,
                        bannerImage: banner,
                        crmImage: crmImage,
                        otherImage1: otherImage1,
                        otherImage2: otherImage2,
                        otherImage3: otherImage3,
                        otherImage4: otherImage4
                    })


                    if (values?.featureImage) {
                        addNewPostingFeatureImg({
                            documentID: infoID,
                            file: values?.featureImage.file.originFileObj,
                        }).then((res) => {
                            if (siteDetails.featureImage !== undefined) {
                                deleteStaticImage({
                                    fileName: /[^/]*$/.exec(siteDetails.featureImage)[0]
                                })
                            }
                        })

                    }

                    if (values?.backgroundImage) {
                        addNewPostingBackgroundImg({
                            documentID: infoID,
                            file: values?.backgroundImage.file.originFileObj,
                        }).then((res) => {
                            if (siteDetails.backgroundImage !== undefined) {
                                deleteStaticImage({
                                    fileName: /[^/]*$/.exec(siteDetails.backgroundImage)[0]
                                })
                            }
                        })

                    }

                    if (values?.seoUrl !== currentFriendlyURL) {
                        removeUrl({
                            path: "/" + currentFriendlyURL
                        }).then((res) => {
                            addNewUrl({
                                path: "/" + values?.seoUrl,
                                type: "Site Pages",
                                referralID: infoID,
                            })
                        })

                    } else {
                        searchUrl({
                            path: "/" + currentFriendlyURL
                        }).then((res) => {
                            if (res === null) {
                                addNewUrl({
                                    path: "/" + currentFriendlyURL,
                                    type: "Site Pages",
                                    referralID: infoID,
                                })
                            }
                        })
                    }

                    // navigate(-1)
                    await setSiteDetails(false)
                    setIsCommitModalOpen(false)
                    dispatchSitePageDetails()
                    message.success('Article updated successfully');
                })
            }


        })
    };

    const onFinish2 = async (values) => {
        // let values = saveData
        if (Array.isArray(values?.scripts) && values?.scripts.length > 0) {
            values.scripts = await values?.scripts.filter(value => value !== null && value !=="" && value !== undefined);
        }
        let CustomDetails = [];
        let shortDescription = [];
        let MetaDescription = [];
        let MetaKeywords = [];
        let titles = [];
        let banner = [];
        let crmImage = [];
        let otherImage1 = [];
        let otherImage2 = [];
        let otherImage3 = [];
        let otherImage4 = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
                shortDescription.push(v.shortDescription)
                MetaDescription.push(v.metaDescription)
                MetaKeywords.push(v.metaKeywords)
                titles.push(v.title)
                banner.push(v.banner)
                crmImage.push(v.crmImage)
                otherImage1.push(v.otherImage1)
                otherImage2.push(v.otherImage2)
                otherImage3.push(v.otherImage3)
                otherImage4.push(v.otherImage4)
            })
        })

        let timeStamp = Math.round((new Date()).getTime() / 1000);
        let date = new Date(timeStamp * 1000)
        let lastModified = date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")
        editSitePages(
            {
                title: titles,
                sidebarID: values?.sidebarID,
                dynamicPath: "/" + friendlyURL,
                pageID: pageIDs,
                categoryID: values?.categoryID,
                details: CustomDetails,
                scripts: values?.scripts,
                shortDescription: shortDescription,
                metaDescription: MetaDescription,
                metaKeywords: MetaKeywords,
                active: pageActive,
                documentID: infoID,
                draftStatus: "inactive",
                lastModified: lastModified

            }
        ).then(async (res) => {

            editPostingImages({
                documentID: infoID,
                bannerImage: banner,
                crmImage: crmImage,
                otherImage1: otherImage1,
                otherImage2: otherImage2,
                otherImage3: otherImage3,
                otherImage4: otherImage4
            })


            if (values?.featureImage) {
                addNewPostingFeatureImg({
                    documentID: infoID,
                    file: values?.featureImage.file.originFileObj,
                }).then((res) => {
                    if (siteDetails.featureImage !== undefined) {
                        deleteStaticImage({
                            fileName: /[^/]*$/.exec(siteDetails.featureImage)[0]
                        })
                    }
                })

            }

            if (values?.backgroundImage) {
                addNewPostingBackgroundImg({
                    documentID: infoID,
                    file: values?.backgroundImage.file.originFileObj,
                }).then((res) => {
                    if (siteDetails.backgroundImage !== undefined) {
                        deleteStaticImage({
                            fileName: /[^/]*$/.exec(siteDetails.backgroundImage)[0]
                        })
                    }
                })

            }

            if (values?.seoUrl !== currentFriendlyURL) {
                removeUrl({
                    path: "/" + currentFriendlyURL
                }).then((res) => {
                    addNewUrl({
                        path: "/" + values?.seoUrl,
                        type: "Site Pages",
                        referralID: infoID,
                    })
                })

            } else {
                searchUrl({
                    path: "/" + currentFriendlyURL
                }).then((res) => {

                    if (res === null) {
                        addNewUrl({
                            path: "/" + currentFriendlyURL,
                            type: "Site Pages",
                            referralID: infoID,
                        })
                    }
                })
            }

            // navigate(-1)
            dispatchSitePageDetails()
            message.success('Article updated successfully');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const dispatchSitePageDetails = () => {

        searchSitePages({
            documentID: infoID
        }).then(async (response) => {

            await formRef.setFieldsValue({
                pageID: response.pageID,
                sidebarID: response.sidebarID,
                seoUrl: response.dynamicPath.substring(1),
                scripts: response?.scripts?.filter(value => value !== null && value !=="" && value !== undefined),
            });

            getAllSectionsCategory({
                sectionID: response.sectionID
            }).then(async (response) => {
                response.map((res, i) => {
                    if (res.type === "sub") {
                        let mainTitle = response.find((item) => item._id === res.mainCategoryID).title
                        response[i]["mainCategoryTitle"] = mainTitle
                    }

                })
                setSectionCategory(response)
            });

            await setSiteDetails(response)

            setFriendlyURL(response.dynamicPath.substring(1))
            setCurrentFriendlyURL(response.dynamicPath.substring(1))
            setPageID(response.pageID)
            setCurrentPageID(response.pageID)
            setPageActive(response.active)
            setHistoryIDs(response.historyIDs !== undefined && response.historyIDs)

            setDraftID(response.draftID !== undefined && response.draftID)
            if (response.draftStatus !== undefined) {
                if (response.draftStatus === "inactive") {

                    setIsReady(true)
                } else {
                    setIsDraftModalOpen(true)

                    setIsReady(false)
                }
            } else {

                setIsReady(true)
            }


            if (response.draftID === undefined || response.draftID === "") {
                addNewDraftSitePages({

                    sitePageID: infoID,

                }).then((res) => {

                    setDraftID(res._id)
                    editSitePages({
                        documentID: infoID,
                        draftID: res._id
                    })
                })
            }

        });
    };

    const dispatchSidebars = () => {
        getSidebars().then(async (response) => {
            setSidebarList(response)
        });
    };

    const handleFriendlyUrl = (e) => {
        handleDraftSaving()
        let friendlyURL = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')
        if (currentFriendlyURL !== friendlyURL) {
            setFriendlyURL(friendlyURL)
            searchUrl({
                path: "/" + friendlyURL
            }).then((res) => {
                if (res === null) {
                    setFriendlyURLInvalid(false)
                } else {
                    setFriendlyURLInvalid(true)
                }
            })
        } else {
            setFriendlyURLInvalid(false)
            setFriendlyURL(friendlyURL)
        }
    }

    const handlePageID = (e) => {
        handleDraftSaving()
        if (currentPageID !== e.target.value) {
            let pageID = e.target.value.toLowerCase()
                .replace(/ /g, '-').replace(/[-]+/g, '-')
                .replace(/[^\w-]+/g, '');

            searchPageID({pageID: pageID}).then((res) => {
                if (res !== null) {
                    setPageIDInvalid(true)
                } else {
                    setPageIDInvalid(false)
                }
            })

            setPageID(pageID)
        } else {
            setPageIDInvalid(false)
            setPageID(e.target.value)
        }

    }

    const onChangePageActive = (e) => {

        if (pageActive === true) {
            setPageActive(false)
        } else {
            setPageActive(true)
        }
        handleDraftSaving()
    }

    const onChangeCommitMessage = (e) => {

        setCommitMessage(e.target.value)
    }

    const versionControlChanges = (e) => {
        setVersionID(e.target.value)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        getSitePagesHistory({
            documentID: versionID
        }).then((res) => {
            editSitePages(
                {
                    title: res.title,
                    sidebarID: res.sidebarID,
                    // pageID: res.pageID,
                    scripts: res.scripts,
                    details: res.details,
                    shortDescription: res.shortDescription !== undefined ? res.shortDescription : "",
                    metaDescription: res.metaDescription,
                    metaKeywords: res.metaKeywords,
                    documentID: infoID,
                    historyID: versionID,
                }
            ).then((res) => {
                navigate(0)
                message.success('Data restored successfully');
                setIsModalOpen(false);
            })
        })


    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalCommit = async () => {
        setSaveData(formRef.getFieldsValue(true))
        setIsCommitModalOpen(true)
    };

    const handleCancelModalCommit = () => {
        setIsCommitModalOpen(false)
    };

    let draftStatus = 200;

    const comparePreviousData = (item, prev, current) => {
        let status = {}

        if (JSON.stringify(prev[item]) !== JSON.stringify(current[item])) {
            status = {
                notSame: true
            }
        }

        return status
    };

    const handleDraftSaving = async () => {

        handlePageChecker()

        let currentData = formRef.getFieldsValue(true);
        let previousData = siteDetails;

        delete previousData['categoryID'];
        delete previousData['defaultPath'];
        delete previousData['historyID'];
        delete previousData['historyIDs'];
        delete previousData['isDefault'];
        delete previousData['__v'];
        delete previousData['_id'];
        delete previousData['type'];

        let CustomDetails = [];
        let shortDescription = [];
        let MetaDescription = [];
        let MetaKeywords = [];
        let titles = [];


        if (draftID !== "" && currentData["title-en"] !== undefined) {

            await Promise.all(languageList.list.map((item, index) => pushLanguagesDataDraft(item, currentData))).then((res) => {

                res.map((v, i) => {
                    CustomDetails.push(v.custom)
                    shortDescription.push(v.shortDescription)
                    MetaDescription.push(v.metaDescription)
                    MetaKeywords.push(v.metaKeywords)
                    titles.push(v.title)
                })

            })

            let currentDataMassage = {
                active: pageActive,
                dynamicPath: "/" + currentData.seoUrl,
                details: CustomDetails,
                shortDescription: shortDescription,
                metaDescription: MetaDescription,
                metaKeywords: MetaKeywords,
                pageID: currentData.pageID,
                scripts: currentData.scripts,
                title: titles,
                sidebarID: currentData.sidebarID,
            }

            let checkingValue = ["active", "dynamicPath", "details", "metaDescription", "metaKeywords", "pageID", "scripts", "title"]

            let result = [];
            Promise.all(checkingValue.map((item, i) => comparePreviousData(item, previousData, currentDataMassage))).then((res) => {
                res.map((v, i) => {
                    if (Object.keys(v).length !== 0) {
                        result.push(v)
                    }
                })
            })


            updateDraftSitePages({
                documentID: draftID,
                title: currentDataMassage.title,
                sidebarID: currentDataMassage.sidebarID,
                sitePageID: infoID,
                pageID: currentDataMassage.pageID,
                dynamicPath: currentDataMassage.dynamicPath,
                details: currentDataMassage.details,
                scripts: currentDataMassage.scripts,
                shortDescription: currentDataMassage.shortDescription !== undefined ? currentDataMassage.shortDescription : "",
                metaDescription: currentDataMassage.metaDescription,
                metaKeywords: currentDataMassage.metaKeywords,
                categoryID: "",
                featureImage: "",
                backgroundImage: "",
                active: currentDataMassage.active,
            }).then((res) => {
                editSitePages({
                    documentID: infoID,
                    draftStatus: result.length > 0 ? "active" : "inactive"
                })
            })

        }


    }

    const restoreDraft = async () => {
        searchDraftSitePages({
            documentID: draftID
        }).then(async (res) => {
            let draftObj = res;

            draftObj['bannerImage'] = siteDetails['bannerImage']
            draftObj['backgroundImage'] = siteDetails['backgroundImage']
            draftObj['featureImage'] = siteDetails['featureImage']
            draftObj['categoryID'] = siteDetails['categoryID']
            draftObj['defaultPath'] = siteDetails['defaultPath']
            draftObj['historyID'] = siteDetails['historyID']
            draftObj['historyIDs'] = siteDetails['historyIDs']
            draftObj['isDefault'] = siteDetails['isDefault']
            draftObj['_id'] = siteDetails['_id']
            draftObj['type'] = siteDetails['type']
            draftObj['lastModified'] = siteDetails['lastModified']

            formRef.setFieldsValue({
                pageID: res.pageID,
                sidebarID: res.sidebarID,
                scripts: res.scripts.length > 0 && res.scripts,
                seoUrl: res.dynamicPath.substring(1)
            });

            setCurrentFriendlyURL(siteDetails.dynamicPath.substring(1))
            setCurrentPageID(siteDetails.pageID)
            setPageID(res.pageID)
            setFriendlyURL(res.dynamicPath.substring(1))
            setPageActive(res.active)
            await setSiteDetails(draftObj)
            setIsDraftModalOpen(false)
            setIsReady(true)
        })
    }

    const cancelDraft = async () => {
        editSitePages({
            documentID: infoID,
            draftStatus: "inactive"
        }).then((res) => {
            setIsDraftModalOpen(false)
            setIsReady(true)
        })

    }

    const FeatureImage = [
        {
            uid: '-1',
            name: "feature image",
            status: 'done',
            url: Keys.SERVER_URL + siteDetails.featureImage,
            thumbUrl: Keys.SERVER_URL + siteDetails.featureImage,
        }
    ];

    const copyFeatureImage = async () => {
        await navigator.clipboard.writeText(Keys.SERVER_URL + siteDetails.featureImage);
        message.success('Feature Image URL copied');
    }

    const BackgroundImage = [
        {
            uid: '-1',
            name: "background image",
            status: 'done',
            url: Keys.SERVER_URL + siteDetails.backgroundImage,
            thumbUrl: Keys.SERVER_URL + siteDetails.backgroundImage,
        }
    ];

    const copyBackgroundImage = async () => {
        await navigator.clipboard.writeText(Keys.SERVER_URL + siteDetails.backgroundImage);
        message.success('Background Image URL copied');
    }

    const detail = languageList.list && siteDetails && languageList.list.map((lang, index) => {


        var bannerPath = siteDetails.bannerImage && siteDetails.bannerImage.find((res) => res.language === lang.code) && siteDetails.bannerImage.find((res) => res.language === lang.code).bannerPath
        let bannerImage = [
            {
                uid: '-1',
                name: `banner-${lang.code}`,
                status: 'done',
                url: Keys.SERVER_URL + bannerPath,
                thumbUrl: Keys.SERVER_URL + bannerPath,
            }
        ];

        var crmPath = siteDetails.crmImage && siteDetails.crmImage.find((res) => res.language === lang.code) && siteDetails.crmImage.find((res) => res.language === lang.code).path
        let crmImage = [
            {
                uid: '-1',
                name: `crm-image-${lang.code}`,
                status: 'done',
                url: Keys.SERVER_URL + crmPath,
                thumbUrl: Keys.SERVER_URL + crmPath,
            }
        ];

        var otherImage1Path = siteDetails.otherImage1 && siteDetails.otherImage1.find((res) => res.language === lang.code) && siteDetails.otherImage1.find((res) => res.language === lang.code).path
        let otherImage1 = [
            {
                uid: '-1',
                name: `other-image-1-${lang.code}`,
                status: 'done',
                url: Keys.SERVER_URL + otherImage1Path,
                thumbUrl: Keys.SERVER_URL + otherImage1Path,
            }
        ];

        var otherImage2Path = siteDetails.otherImage2 && siteDetails.otherImage2.find((res) => res.language === lang.code) && siteDetails.otherImage2.find((res) => res.language === lang.code).path
        let otherImage2 = [
            {
                uid: '-1',
                name: `other-image-2-${lang.code}`,
                status: 'done',
                url: Keys.SERVER_URL + otherImage2Path,
                thumbUrl: Keys.SERVER_URL + otherImage2Path,
            }
        ];

        var otherImage3Path = siteDetails.otherImage3 && siteDetails.otherImage3.find((res) => res.language === lang.code) && siteDetails.otherImage3.find((res) => res.language === lang.code).path
        let otherImage3 = [
            {
                uid: '-1',
                name: `other-image-3-${lang.code}`,
                status: 'done',
                url: Keys.SERVER_URL + otherImage3Path,
                thumbUrl: Keys.SERVER_URL + otherImage3Path,
            }
        ];

        var otherImage4Path = siteDetails.otherImage4 && siteDetails.otherImage4.find((res) => res.language === lang.code) && siteDetails.otherImage4.find((res) => res.language === lang.code).path
        let otherImage4 = [
            {
                uid: '-1',
                name: `other-image-4-${lang.code}`,
                status: 'done',
                url: Keys.SERVER_URL + otherImage4Path,
                thumbUrl: Keys.SERVER_URL + otherImage4Path,
            }
        ];

        const copyBannerImage = async () => {
            await navigator.clipboard.writeText(Keys.SERVER_URL + bannerPath);
            message.success('Banner Image URL copied');
        }
        const copyCrmImage = async () => {
            await navigator.clipboard.writeText(Keys.SERVER_URL + crmPath);
            message.success('Crm Image URL copied');
        }
        const copyOtherImage1 = async () => {
            await navigator.clipboard.writeText(Keys.SERVER_URL + otherImage1Path);
            message.success('Other Image 1 URL copied');
        }
        const copyOtherImage2 = async () => {
            await navigator.clipboard.writeText(Keys.SERVER_URL + otherImage2Path);
            message.success('Other Image 1 URL copied');
        }
        const copyOtherImage3 = async () => {
            await navigator.clipboard.writeText(Keys.SERVER_URL + otherImage3Path);
            message.success('Other Image 1 URL copied');
        }
        const copyOtherImage4 = async () => {
            await navigator.clipboard.writeText(Keys.SERVER_URL + otherImage4Path);
            message.success('Other Image 1 URL copied');
        }
        return (
            <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"} key={"detail-" + index}
                          forceRender={true}>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Title:
                    </Col>
                    <Col span={20}>
                        <Form.Item
                            name={"title-" + lang.code}
                            rules={[{
                                required: true,
                                message: 'Please input title-' + lang.code
                            }]}
                            initialValue={siteDetails && siteDetails.title.find((res) => res.language === lang.code) !== undefined ? siteDetails.title.find((res) => res.language === lang.code).title : ""}
                        >
                            <Input onKeyUp={handleDraftSaving}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Details:
                    </Col>
                    <Col span={20}>
                        <Form.Item name={"detail-" + lang.code}
                                   initialValue={siteDetails && siteDetails.details.find((res) => res.language === lang.code) !== undefined ? siteDetails.details.find((res) => res.language === lang.code).details : ""}>
                            {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000 ? 20 : 10}/>*/}
                            <CodeMirror
                                value=""
                                height={window.screen.height >= 1000 ? "500px" : "400px"}
                                extensions={[html()]}
                                onKeyUp={handleDraftSaving}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Short Descriptions:
                    </Col>
                    <Col span={20}>
                        <Form.Item name={"shortDescription-" + lang.code}
                                   initialValue={siteDetails && siteDetails.shortDescription && siteDetails.shortDescription.find((res) => res.language === lang.code) !== undefined ? siteDetails.shortDescription.find((res) => res.language === lang.code).description : ""}>
                            <TextArea onKeyUp={handleDraftSaving} rows={5}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Meta Descriptions:
                    </Col>
                    <Col span={20}>
                        <Form.Item name={"seoDescription-" + lang.code}
                                   initialValue={siteDetails && siteDetails.metaDescription.find((res) => res.language === lang.code) !== undefined ? siteDetails.metaDescription.find((res) => res.language === lang.code).description : ""}>
                            <TextArea onKeyUp={handleDraftSaving} rows={5}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Meta Keywords:
                    </Col>
                    <Col span={20}>
                        <Form.Item name={"seoKeyword-" + lang.code}
                                   initialValue={siteDetails && siteDetails.metaKeywords.find((res) => res.language === lang.code) !== undefined ? siteDetails.metaKeywords.find((res) => res.language === lang.code).keywords : ""}>
                            <TextArea onKeyUp={handleDraftSaving} rows={5}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>

                    <Col span={4}>
                        Banner Image:
                    </Col>
                    <Col span={18}>
                        <Form.Item name={"banner-" + lang.code}>
                            <Upload
                                listType="picture"
                                maxCount={1}
                                onRemove={false}
                                defaultFileList={siteDetails.bannerImage && siteDetails.bannerImage.find((res) => res.language === lang.code) ? [...bannerImage] : false}
                            >
                                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                            </Upload>

                        </Form.Item>
                    </Col>
                    {
                        siteDetails.bannerImage && siteDetails.bannerImage.find((res) => res.language === lang.code) ? (
                            <Tooltip placement="bottom" title={"Copy Banner Image"}><Button type="link"
                                                                                            onClick={copyBannerImage}
                                                                                            style={{
                                                                                                paddingLeft: "5px",
                                                                                                top: "5px",
                                                                                                right: "6.5%"
                                                                                            }}
                                                                                            className="mt-5"><CopyOutlined/></Button></Tooltip>
                        ) : ""
                    }

                </Row>
                <Row gutter={[16, 16]}>

                    <Col span={4}>
                        Crm Image:
                    </Col>
                    <Col span={18}>
                        <Form.Item name={"crm-image-" + lang.code}>
                            <Upload
                                listType="picture"
                                maxCount={1}
                                onRemove={false}
                                defaultFileList={siteDetails.crmImage && siteDetails.crmImage.find((res) => res.language === lang.code) ? [...crmImage] : false}
                            >
                                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                            </Upload>

                        </Form.Item>
                    </Col>
                    {
                        siteDetails.crmImage && siteDetails.crmImage.find((res) => res.language === lang.code) ? (
                            <Tooltip placement="bottom" title={"Copy Crm Image"}><Button type="link"
                                                                                         onClick={copyCrmImage} style={{
                                paddingLeft: "5px",
                                top: "5px",
                                right: "6.5%"
                            }} className="mt-5"><CopyOutlined/></Button></Tooltip>
                        ) : ""
                    }
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Other Image 1:
                    </Col>
                    <Col span={18}>
                        <Form.Item name={"other-image-1-" + lang.code}>
                            <Upload
                                listType="picture"
                                maxCount={1}
                                onRemove={false}
                                defaultFileList={siteDetails.otherImage1 && siteDetails.otherImage1.find((res) => res.language === lang.code) ? [...otherImage1] : false}
                            >
                                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                            </Upload>

                        </Form.Item>
                    </Col>
                    {
                        siteDetails.otherImage1 && siteDetails.otherImage1.find((res) => res.language === lang.code) ? (
                            <Tooltip placement="bottom" title={"Copy Other Image 1"}><Button type="link"
                                                                                             onClick={copyOtherImage1}
                                                                                             style={{
                                                                                                 paddingLeft: "5px",
                                                                                                 top: "5px",
                                                                                                 right: "6.5%"
                                                                                             }}
                                                                                             className="mt-5"><CopyOutlined/></Button></Tooltip>
                        ) : ""
                    }
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Other Image 2:
                    </Col>
                    <Col span={18}>
                        <Form.Item name={"other-image-2-" + lang.code}>
                            <Upload
                                listType="picture"
                                maxCount={1}
                                onRemove={false}
                                defaultFileList={siteDetails.otherImage2 && siteDetails.otherImage2.find((res) => res.language === lang.code) ? [...otherImage2] : false}
                            >
                                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                            </Upload>

                        </Form.Item>
                    </Col>
                    {
                        siteDetails.otherImage2 && siteDetails.otherImage2.find((res) => res.language === lang.code) ? (
                            <Tooltip placement="bottom" title={"Copy Other Image 2"}><Button type="link"
                                                                                             onClick={copyOtherImage2}
                                                                                             style={{
                                                                                                 paddingLeft: "5px",
                                                                                                 top: "5px",
                                                                                                 right: "6.5%"
                                                                                             }}
                                                                                             className="mt-5"><CopyOutlined/></Button></Tooltip>
                        ) : ""
                    }
                </Row>
                <Row gutter={[16, 16]}>

                    <Col span={4}>
                        Other Image 3:
                    </Col>
                    <Col span={18}>
                        <Form.Item name={"other-image-3-" + lang.code}>
                            <Upload
                                listType="picture"
                                maxCount={1}
                                onRemove={false}
                                defaultFileList={siteDetails.otherImage3 && siteDetails.otherImage3.find((res) => res.language === lang.code) ? [...otherImage3] : false}

                            >
                                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                            </Upload>

                        </Form.Item>
                    </Col>
                    {
                        siteDetails.otherImage3 && siteDetails.otherImage3.find((res) => res.language === lang.code) ? (
                            <Tooltip placement="bottom" title={"Copy Other Image 3"}><Button type="link"
                                                                                             onClick={copyOtherImage3}
                                                                                             style={{
                                                                                                 paddingLeft: "5px",
                                                                                                 top: "5px",
                                                                                                 right: "6.5%"
                                                                                             }}
                                                                                             className="mt-5"><CopyOutlined/></Button></Tooltip>
                        ) : ""
                    }
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        Other Image 4:
                    </Col>
                    <Col span={18}>
                        <Form.Item name={"other-image-4-" + lang.code}>
                            <Upload
                                listType="picture"
                                maxCount={1}
                                onRemove={false}
                                defaultFileList={siteDetails.otherImage4 && siteDetails.otherImage4.find((res) => res.language === lang.code) ? [...otherImage4] : false}
                            >
                                <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                            </Upload>

                        </Form.Item>
                    </Col>
                    {
                        siteDetails.otherImage4 && siteDetails.otherImage4.find((res) => res.language === lang.code) ? (
                            <Tooltip placement="bottom" title={"Copy Other Image 4"}><Button type="link"
                                                                                             onClick={copyOtherImage4}
                                                                                             style={{
                                                                                                 paddingLeft: "5px",
                                                                                                 top: "5px",
                                                                                                 right: "6.5%"
                                                                                             }}
                                                                                             className="mt-5"><CopyOutlined/></Button></Tooltip>
                        ) : ""
                    }
                </Row>


            </Tabs.TabPane>
        )
    })

    const handleJavascriptCount = async () => {
        handleDraftSaving()
        let jsTextarea = document.getElementsByClassName("jsTextarea");
        let ary = []
        let status = [];
        for (let i = 0; i < jsTextarea.length; i++) {
            let currentData = formRef.getFieldsValue(true);
            let jsLength = currentData.scripts[i].length
            ary.push({
                className: "jsTextarea-" + i,
                length: jsLength,
                status: jsLength > 10000 ? "error" : true,
                maxLength: 10000
            })
            if (jsLength > 10000) {
                status.push("error")
            }

        }
        if (status.length > 0) {
            setJavascriptCountStatus("error")
        } else {
            setJavascriptCountStatus("")
        }
        setJavascriptCount(ary)
    }

    const pageNotAvailable = () => {
        setPageActiveUUID(false)
        navigate(-1)
    }

    const handleTakeControl = () => {
        let uuids = localStorage.getItem("uuid")
        takeControlPageChecker({
            ip: uuids,
            currentPage: location.pathname
        }).then((res) => {
            updatePageChecker({
                currentPage: res,
                isActive: true,
                ip: uuids
            }).then((r) => {
                setPageActiveUUID(false)
            })

        })
    }

    const handlePageChecker = () => {
        let uuids = localStorage.getItem("uuid")
        pageChecker({
            currentPage: location.pathname,
            ip: uuids
        }).then((res) => {
            if (res.status === "available") {
                setPageActiveUUID(false)
                updatePageChecker({
                    currentPage: location.pathname,
                    isActive: true,
                    ip: uuids
                })
            } else {
                setPageActiveUUID(true)
            }
        })
    }

    useEffect(() => {
        dispatchLanguages();
        dispatchSitePageDetails();
        dispatchSidebars();
    }, []);

    return (
        <>

            <div className="sitepages">
                <div className="site-layout-background">
                    {
                        isReady === true ? (
                            <Form
                                name="siteCreate"
                                onFinish={onFinish2}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                form={formRef}
                            >
                                <Row>
                                    <Col span={8}>
                                        <h3>Edit Article</h3>
                                    </Col>
                                    <Col span={16} className="text-end">
                                        <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                            Back
                                        </Button>
                                        {
                                            historyIDs.length > 0 && (
                                                <Button shape="round" className="ms-2" onClick={showModal}>
                                                    Revert Previous Version
                                                </Button>
                                            )
                                        }
                                        <Button className="ms-2" shape="round" onClick={showModalCommit}
                                                disabled={friendlyURLInvalid === true || pageIDInvalid === true ? true : false || javascriptCountStatus === "error"}>
                                            Save as new version
                                        </Button>

                                        <Button type="primary" className="ms-2" shape="round" htmlType="submit"
                                                disabled={friendlyURLInvalid === true || pageIDInvalid === true ? true : false || javascriptCountStatus === "error"}>
                                            Save Changes

                                        </Button>
                                    </Col>
                                </Row>

                                <Col>
                                    Last saved as new
                                    version: <b>{historyIDs.length > 0 && historyIDs.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)[0].dateTime}</b><br/>
                                    Last saved
                                    changes: <b>{siteDetails.lastModified !== undefined ? siteDetails.lastModified : "-"}</b>
                                </Col>


                                {siteDetails && languageList.status === 200 && languageList.list.length > 0 ? (
                                    <Col>
                                        <Tabs>
                                            <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                                <Row gutter={[16, 16]}>
                                                    <Col span={12}>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                PageID:
                                                            </Col>
                                                            <Col>
                                                                <Form.Item
                                                                    name="pageID"
                                                                    validateStatus={pageIDInvalid === true ? "error" : ""}
                                                                    help={pageIDs !== "" ? pageIDInvalid === true ? "PageID is already in use" : "Your Page ID is " + pageIDs : ""}
                                                                >
                                                                    <Input onKeyUp={handlePageID}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </div>
                                                        {sectionCategory && sectionCategory ?
                                                            <div className="mb-2">
                                                                <Col className="mb-2">
                                                                    Category:
                                                                </Col>
                                                                <Col>
                                                                    <Form.Item name="categoryID"
                                                                               initialValue={siteDetails.categoryID}>
                                                                        <Select
                                                                            mode="multiple"
                                                                            allowClear
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            onChange={handleDraftSaving}
                                                                            placeholder="Please select"
                                                                        >
                                                                            {
                                                                                sectionCategory && sectionCategory.map((sec, index) => (
                                                                                    <Option value={sec._id}
                                                                                            key={index + 1}>
                                                                                        {sec.type === "sub" ? sec.mainCategoryTitle + " > " + sec.title : sec.title}
                                                                                    </Option>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </div> :
                                                            ""
                                                        }
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Sidebar:
                                                            </Col>
                                                            <Col>
                                                                {sidebarList && sidebarList ? (
                                                                    <Form.Item name="sidebarID">
                                                                        <Select
                                                                            onChange={handleDraftSaving}
                                                                            placeholder="Select an option"
                                                                            allowClear
                                                                        >
                                                                            <Option value="0">No Sidebar</Option>
                                                                            {
                                                                                sidebarList && sidebarList.map((page, index) => (
                                                                                    <Option value={page._id}
                                                                                            key={index + 1}>{page.title}</Option>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                ) : ""}

                                                            </Col>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Friendly URL:
                                                            </Col>
                                                            <Col>
                                                                <Form.Item
                                                                    name="seoUrl"
                                                                    validateStatus={friendlyURLInvalid === true ? "error" : ""}
                                                                    help={friendlyURLInvalid === true ? "This URL is already in use" : ""}
                                                                >
                                                                    <Input onKeyUp={handleFriendlyUrl}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </div>


                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Feature Image:
                                                            </Col>
                                                            <Col>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col span={20}>
                                                                        <Form.Item name={"featureImage"}>
                                                                            <Upload
                                                                                listType="picture"
                                                                                maxCount={1}
                                                                                defaultFileList={siteDetails.featureImage ? [...FeatureImage] : false}
                                                                            >
                                                                                <Button icon={<UploadOutlined/>}>Upload
                                                                                    (Max:
                                                                                    1)</Button>
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    {
                                                                        siteDetails.featureImage ? (
                                                                            <Tooltip placement="bottom"
                                                                                     title={"Copy Feature Image"}><Button
                                                                                type="link" onClick={copyFeatureImage}
                                                                                style={{
                                                                                    paddingLeft: "5px",
                                                                                    top: "5px",
                                                                                    right: "13%"
                                                                                }}
                                                                                className="mt-5"><CopyOutlined/></Button></Tooltip>
                                                                        ) : ""
                                                                    }


                                                                </Row>

                                                            </Col>
                                                        </div>


                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Background Image :
                                                            </Col>
                                                            <Col>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col span={20}>
                                                                        <Form.Item name={"backgroundImage"}>
                                                                            <Upload
                                                                                listType="picture"
                                                                                maxCount={1}
                                                                                defaultFileList={siteDetails.backgroundImage ? [...BackgroundImage] : false}
                                                                            >
                                                                                <Button icon={<UploadOutlined/>}>Upload
                                                                                    (Max:
                                                                                    1)</Button>
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    {siteDetails.backgroundImage ? (
                                                                        <Tooltip placement="bottom"
                                                                                 title={"Copy Background Image"}><Button
                                                                            type="link" onClick={copyBackgroundImage}
                                                                            style={{
                                                                                paddingLeft: "5px",
                                                                                top: "5px",
                                                                                right: "13%"
                                                                            }}
                                                                            className="mt-5"><CopyOutlined/></Button></Tooltip>
                                                                    ) : ""
                                                                    }

                                                                </Row>
                                                            </Col>

                                                        </div>

                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Is Active:
                                                            </Col>
                                                            <Col>
                                                                <Form.Item valuePropName="checked">
                                                                    <Switch defaultChecked={pageActive}
                                                                            onChange={onChangePageActive}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </div>

                                                    </Col>
                                                    <Col span={12}>
                                                        <Card title="Friendly URL">
                                                            <p>{window.location.origin}/#/{friendlyURL}</p>
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                JavaScript:
                                                            </Col>
                                                            <Col>
                                                                <Form.List
                                                                    name="scripts"
                                                                >
                                                                    {(fields, {add, remove}, {errors}) => (
                                                                        <>
                                                                            {fields.map((field, index) => {

                                                                                let className = "jsTextarea-" + index;
                                                                                let length = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).length;
                                                                                let status = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).status;
                                                                                let maxLength = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).maxLength;
                                                                                return (
                                                                                    <Form.Item
                                                                                        required={false}
                                                                                        key={field.key}
                                                                                    >
                                                                                        <div className="row">
                                                                                            <div className="col-11">
                                                                                                <Form.Item
                                                                                                    {...field}
                                                                                                    style={{marginBottom: "7px"}}
                                                                                                >
                                                                                                    <CodeMirror
                                                                                                        className={"jsTextarea jsTextarea-" + index}
                                                                                                        value=""
                                                                                                        height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                                                                        extensions={[javascript()]}
                                                                                                        onKeyUp={handleJavascriptCount}
                                                                                                    />
                                                                                                    {/*<TextArea*/}
                                                                                                    {/*    className={"jsTextarea jsTextarea-" + index}*/}
                                                                                                    {/*    onKeyUp={handleJavascriptCount}*/}
                                                                                                    {/*    spellCheck={false}*/}
                                                                                                    {/*    rows={window.screen.height >= 1000 ? 20 : 10}/>*/}
                                                                                                </Form.Item>
                                                                                                {
                                                                                                    length > 0 && status !== "error" ? (
                                                                                                        <Text
                                                                                                            type="secondary">{length}/{maxLength} Characters</Text>
                                                                                                    ) : length > 0 ? (
                                                                                                        <Text
                                                                                                            type="danger">{length}/{maxLength} Characters</Text>
                                                                                                    ) : ""
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-1">
                                                                                                {fields.length > 1 ? (
                                                                                                    <MinusCircleOutlined
                                                                                                        className="dynamic-delete-button"
                                                                                                        onClick={() => remove(field.name)}
                                                                                                    />
                                                                                                ) : null}
                                                                                            </div>

                                                                                        </div>
                                                                                    </Form.Item>
                                                                                )
                                                                            })}
                                                                            <Form.Item>
                                                                                <Button
                                                                                    type="dashed"
                                                                                    onClick={() => add()}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                    }}
                                                                                >
                                                                                    Add Javascript
                                                                                </Button>

                                                                            </Form.Item>
                                                                        </>
                                                                    )}
                                                                </Form.List>
                                                            </Col>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </Tabs.TabPane>

                                            {detail}


                                        </Tabs>
                                    </Col>
                                ) : (languageList.status === 200) ? (
                                    <Col className="mt-5">
                                        <EmptyLanguage/>
                                    </Col>
                                ) : ""
                                }


                            </Form>
                        ) : ""
                    }
                </div>
            </div>

            <Modal title="Version Control" visible={isModalOpen} onOk={handleOk} okText={"Revert"}
                   onCancel={handleCancel}>
                <div>
                    <Radio.Group className="w-100" onChange={versionControlChanges}>

                        {
                            historyIDs.length > 0 && historyIDs.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1).map((item, i) => (
                                <Radio.Button
                                    className={siteDetails.historyID === item.id ? "w-100 version-control-active" : "w-100"}
                                    value={item.id}>
                                    <div className="row">
                                        <div className="col-6">
                                            <Tooltip placement="right" title={item.message}>
                                                {item.message ? truncateString(item.message, 20) : "No Message"}
                                            </Tooltip>

                                        </div>
                                        <div className="col-6 text-end">
                                            {item.dateTime}
                                        </div>
                                    </div>
                                </Radio.Button>
                            ))
                        }

                    </Radio.Group>
                </div>
            </Modal>
            <Modal title="Commit Changes" visible={isCommitModalOpen} onOk={onFinish} okText={"Commit"}
                   destroyOnClose={true}
                   onCancel={handleCancelModalCommit}>
                <div>
                    <Row gutter={[16, 16]}>
                        <Col span={4}>
                            Message:
                        </Col>
                        <Col span={20}>
                            <Form.Item name={"commit"}>
                                <TextArea onKeyUp={onChangeCommitMessage} rows={5}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Modal title="Draft recovery" visible={isDraftModalOpen} onOk={restoreDraft} onCancel={cancelDraft}>
                <div>
                    <Alert
                        message="Warning"
                        description="Do you want to restore unsaved draft?"
                        type="warning"
                        showIcon
                    />
                </div>
            </Modal>

            <Modal title="Warning" visible={pageActiveUUID}
                   okText={"Take Control"}
                   destroyOnClose={true}
                   onOk={handleTakeControl} onCancel={pageNotAvailable}
            >
                <div>
                    <Alert
                        message="Warning"
                        description="Someone is currently editing this page"
                        type="warning"
                        showIcon
                    />
                </div>
            </Modal>
        </>
    );


};

