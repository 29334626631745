import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, message} from 'antd';
import React, {useEffect, useState} from 'react';
import {getBrandSetting, loginUser} from "../../service";
import {useNavigate} from "react-router-dom";
import Keys from "../../_config";


export default function Login() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [brandSetting, setBrandSetting] = useState("");
    const onFinish = (values) => {
        loginUser({username: values?.email, password: values?.password}).then((response) => {
            if (response.success === true) {
                form.resetFields();
                localStorage.setItem("userID", response?.userID)
                localStorage.setItem("a_t", response?.token)
                message.success('Login Successfully');
                navigate("/admin/home")
            } else {
                localStorage.setItem("a_t", "")
                message.error('Authentication failed');
            }
        });
    };

    const dispatchBrandSetting = () => {
        getBrandSetting().then(async (response) => {
            if (response.list.length > 0) {
                setBrandSetting(response.list[0])
            }
        });
    };

    useEffect(() => {
        dispatchBrandSetting();
        form.resetFields();
    }, []);

    return (
        <div id="login-form" className="container-fluid">
            <div className="row auth-login-form ">
                {/*<div className="col-md-4 auth-foundation-logo-section pt-5 text-center">*/}
                {/*    <img src={nodeLogo} className="auth-login-foundation-logo"*/}
                {/*         alt="foundation logo"/>*/}
                {/*</div>       */}
                <div className="col-md-4"></div>
                <div className="col-md-4 py-5 login-section">


                    <Form
                        name="normal_login"
                        form={form}
                        onFinish={onFinish}
                        className="login-form auth-foundation-login pt-5 mt-5"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        {
                            brandSetting.logoUrl && (
                                <div className="col-12 text-center mb-4">
                                    <img src={Keys.SERVER_URL + brandSetting.logoPath} className="auth-login-image"
                                         alt="test1"/>
                                </div>
                            )
                        }
                        <Form.Item
                            type="email"
                            name="email"
                            id="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
                        </Form.Item>
                        <Form.Item
                            type="password"
                            name="password"
                            id="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="auth-login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

        </div>
    );
}

// export default Login;