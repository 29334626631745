import axios from "axios";
import constants from "../context/constants";
import Keys from "../_config";
import {compressFile, cssMinifier} from "./public_function";

//Auth API Start
export async function getUser() {
    return await axios({
        method: "get",
        url: `${Keys.API_URL}/api/admin/getUser`,
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchUser(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchUser`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function loginUser(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/login`,
        data: {
            username: data.username,
            password: data.password,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function verifyToken(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/checkToken`,
        data: {
            token: data.token,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function logoutUser() {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/logout`,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Auth API End

//Section API Start
export async function getSections() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSections`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getSectionsCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSectionsCategory`,
        crossdomain: true,
        data: {
            type: data.type,
            mainCategoryID: data.mainCategoryID,
            sectionID: data.sectionID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getSectionsCategoryByID(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSectionsCategoryByID`,
        crossdomain: true,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getAllSectionsCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getAllSectionsCategory`,
        crossdomain: true,
        data: {
            sectionID: data.sectionID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function addNewSections(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewSections`,
        data: {
            title: data.title,
            slug: data.slug,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function editSections(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/editSections`,
        data: {
            title: data.title,
            slug: data.slug,
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSections(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSections`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteSections(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteSections`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionCategory`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionCategoryTitleName(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionCategoryTitleName`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addNewSectionCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewSectionCategory`,
        data: {
            title: data.title,
            sectionID: data.sectionID,
            mainCategoryID: data.mainCategoryID,
            type: data.type,
            slug: data.slug,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addNewSectionCategoryImage(data) {
    const formData = new FormData();
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    const compressFileData = await compressFile(data.file)
    formData.append("documentID", data.documentID);
    formData.append("image", compressFileData.file);
    formData.append("url", url);
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewSectionCategoryImage`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function editSectionCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/editSectionCategory`,
        data: {
            documentID: data.documentID,
            title: data.title,
            mainCategoryID: data.mainCategoryID,
            type: data.type,
            slug: data.slug,
            active: data.active,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function updateSectionCategoryMainCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateSectionCategoryMainCategory`,
        data: {
            documentID: data.documentID,
            newID: data.newID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteSectionCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteSectionCategory`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteSectionCategoryImage(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteSectionCategoryImage`,
        data: {
            ImageName: data.ImageName,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionBySlug(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionBySlug`,
        data: {
            slug: data.slug,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getSubSectionCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSubSectionCategory`,
        data: {
            mainCategoryID: data.mainCategoryID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionCategoryBySlug(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionCategoryBySlug`,
        data: {
            slug: data.slug,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionCategoryBySection(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionCategoryBySection`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionMainCategoryBySection(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionMainCategoryBySection`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionPostingBySection(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionPostingBySection`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSectionPostingBySectionCategory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSectionPostingBySectionCategory`,
        data: {
            documentID: data.documentID,
            categoryID: data.categoryID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Section API End

//Posting API Start
export async function getPosting(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getPosting`,
        data: {
            sectionID: data.sectionID,
        },
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getPostingListing(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getPostingListing`,
        data: {
            sectionID: data.sectionID,
        },
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function addNewPosting(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewPosting`,
        data: {
            title: data.title,
            sidebarID: data.sidebarID,
            pageID: data.pageID,
            type: "Posting",
            sectionID: data.sectionID,
            categoryID: data.categoryID,
            bannerImage: data.bannerImage,
            crmImage: data.crmImage,
            otherImage1: data.otherImage1,
            otherImage2: data.otherImage2,
            otherImage3: data.otherImage3,
            otherImage4: data.otherImage4,
            defaultPath: data.defaultPath,
            dynamicPath: data.dynamicPath,
            details: data.details,
            scripts: data.scripts,
            shortDescription: data.shortDescription,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            historyIDs: data.historyIDs,
            historyID: data.historyID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addNewPostingFeatureImg(data) {
    const formData = new FormData();
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    const compressFileData = await compressFile(data.file)
    formData.append("documentID", data.documentID);
    formData.append("featureImage", compressFileData.file);
    formData.append("url", url);
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewPostingFeatureImg`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addNewPostingBackgroundImg(data) {
    const formData = new FormData();
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    const compressFileData = await compressFile(data.file)
    formData.append("documentID", data.documentID);
    formData.append("backgroundImage", compressFileData.file);
    formData.append("url", url);
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewPostingBackgroundImg`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addNewPostingStaticImage(data) {
    const formData = new FormData();
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    const compressFileData = await compressFile(data.image)
    formData.append("image", compressFileData.file);
    formData.append("url", url);
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewPostingStaticImage`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addNewPostingBannerImg(data) {
    const formData = new FormData();
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    const compressFileData = await compressFile(data.bannerImage)
    formData.append("bannerImage", compressFileData.file);
    formData.append("url", url);
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewPostingBannerImg`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function editPostingImages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/editPostingImages`,
        data: {
            documentID: data.documentID,
            bannerImage: data.bannerImage,
            crmImage: data.crmImage,
            otherImage1: data.otherImage1,
            otherImage2: data.otherImage2,
            otherImage3: data.otherImage3,
            otherImage4: data.otherImage4,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Posting API End

//Site Pages API Start
export async function getSitePages() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSitePages`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getSitePagesListing() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSitePagesListing`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getAllSitePages() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getAllSitePages`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function addNewSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewSitePages`,
        data: {
            title: data.title,
            sidebarID: data.sidebarID,
            pageID: data.pageID,
            type: "Site Pages",
            defaultPath: data.defaultPath,
            dynamicPath: data.dynamicPath,
            details: data.details,
            script: data.script,
            scripts: data.scripts,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            historyIDs: data.historyIDs,
            historyID: data.historyID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function editSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/editSitePages`,
        data: {
            documentID: data.documentID,
            historyID: data.historyID,
            draftID: data.draftID,
            draftStatus: data.draftStatus,
            historyIDs: data.historyIDs,
            title: data.title,
            sidebarID: data.sidebarID,
            pageID: data.pageID,
            categoryID: data.categoryID,
            dynamicPath: data.dynamicPath,
            details: data.details,
            script: data.script,
            scripts: data.scripts,
            shortDescription: data.shortDescription,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            active: data.active,
            lastModified: data.lastModified
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function updateSitePagesHistory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateSitePagesHistory`,
        data: {
            documentID: data.documentID,
            title: data.title,
            sidebarID: data.sidebarID,
            pageID: data.pageID,
            details: data.details,
            script: data.script,
            shortDescription: data.shortDescription,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            status: data.status,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addSitePagesHistory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addSitePagesHistory`,
        data: {
            title: data.title,
            sidebarID: data.sidebarID,
            pageID: data.pageID,
            details: data.details,
            script: data.script,
            scripts: data.scripts,
            metaDescription: data.metaDescription,
            shortDescription: data.shortDescription,
            metaKeywords: data.metaKeywords,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function addNewDraftSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewDraftSitePages`,
        data: {
            title: data.title,
            sidebarID: data.sidebarID,
            sitePageID: data.sitePageID,
            pageID: data.pageID,
            dynamicPath: data.dynamicPath,
            details: data.details,
            script: data.script,
            shortDescription: data.shortDescription,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            categoryID: data.categoryID,
            featureImage: data.featureImage,
            backgroundImage: data.backgroundImage,
            bannerImage: data.bannerImage,
            active: data.active,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function updateDraftSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateDraftSitePages`,
        data: {
            title: data.title,
            sidebarID: data.sidebarID,
            documentID: data.documentID,
            sitePageID: data.sitePageID,
            pageID: data.pageID,
            dynamicPath: data.dynamicPath,
            details: data.details,
            script: data.script,
            scripts: data.scripts,
            shortDescription: data.shortDescription,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            categoryID: data.categoryID,
            featureImage: data.featureImage,
            backgroundImage: data.backgroundImage,
            bannerImage: data.bannerImage,
            active: data.active,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchDraftSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchDraftSitePages`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}


export async function deleteSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteSitePages`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteSitePagesHistory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteSitePagesHistory`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteDraftSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteDraftSitePages`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSitePages`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSitePagesWithFiltered(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSitePagesWithFiltered`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getSitePagesHistory(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSitePagesHistory`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}


export async function getDefaultSitePages(data) {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getDefaultSitePages`,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function updateSitePages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateSitePages`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchPageID(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchPageID`,
        data: {
            pageID: data.pageID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Site Pages API End

//Header API Start
export async function addNewHeaders(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewHeaders`,
        data: {
            title: data.title,
            defaultPath: data.defaultPath,
            details: data.details,
            script: data.script,
            link: data.link,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function editHeaders(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/editHeaders`,
        data: {
            documentID: data.documentID,
            title: data.title,
            details: data.details,
            script: data.script,
            link: data.link,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getHeaders() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getHeaders`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getHeadersListing() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getHeadersListing`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function updateHeaders(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateHeaders`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteHeaders(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteHeaders`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getDefaultHeaders() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getDefaultHeaders`,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchHeaders(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchHeaders`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Header API End

//Footer API Start
export async function addNewFooters(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewFooters`,
        data: {
            title: data.title,
            defaultPath: data.defaultPath,
            details: data.details,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function editFooters(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/editFooters`,
        data: {
            documentID: data.documentID,
            title: data.title,
            details: data.details
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getFooters() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getFooters`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getFootersListing() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getFootersListing`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function updateFooters(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateFooters`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteFooters(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteFooters`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getDefaultFooters() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getDefaultFooters`,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchFooters(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchFooters`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Footer API End

//Sidebar API Start
export async function addNewSidebars(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewSidebars`,
        data: {
            title: data.title,
            defaultPath: data.defaultPath,
            details: data.details,
            scripts: data.scripts,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function editSidebars(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/editSidebars`,
        data: {
            documentID: data.documentID,
            title: data.title,
            details: data.details,
            scripts: data.scripts,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getSidebars() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSidebars`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getSidebarsListing() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getSidebarsListing`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function updateSidebars(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateSidebars`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteSidebars(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteSidebars`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchSidebars(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchSidebars`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Sidebar API End

//Friendly URL API Start
export async function addNewUrl(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewUrl`,
        data: {
            path: data.path,
            type: data.type,
            referralID: data.referralID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function searchUrl(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/searchUrl`,
        data: {
            path: data.path,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getUrl() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getUrl`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function removeUrl(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/removeUrl`,
        data: {
            path: data.path,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Friendly URL API End

//Languages API Start
export async function addNewLanguage(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/addNewLanguage`,
        data: {
            title: data.title,
            code: data.code,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function updateNewLanguage(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateLanguage`,
        data: {
            documentID: data.documentID,
            title: data.title,
            code: data.code,
            active: data.active
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getLanguages() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getLanguages`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function deleteLanguages(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteLanguage`,
        data: {
            documentID: data.documentID,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Languages API End

//Css and Script API Start
export async function updateCustomCss(data) {

    let minifiedCss = "";

    try {

        minifiedCss = await cssMinifier(data.details)

        return await axios({
            method: "post",
            headers: {
                authorization: localStorage.getItem("a_t") || null,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            url: `${Keys.API_URL}/api/admin/updateCustomCss`,
            data: {
                details: data.details,
                minifiedCss: minifiedCss,
                historyID: data.historyID,
                currentHistoryId: data.currentHistoryId
            },
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });

    } catch (e) {

        throw e;

    }


}

export async function saveAsNewVersionCustomCss(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/saveAsNewVersionCustomCss`,
        data: {
            details: data.details,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getCustomCssVersion(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getCustomCssVersion`,
        data: {
            id: data.id,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function updateCustomScript(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateCustomScript`,
        data: {
            scripts: data.scripts,
            historyID: data.historyID,
            currentHistoryId: data.currentHistoryId
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function saveAsNewVersionCustomJs(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/saveAsNewVersionCustomJs`,
        data: {
            scripts: data.scripts,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getCustomJsVersion(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getCustomJsVersion`,
        data: {
            id: data.id,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getCustomCss() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getCustomCss`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function getCustomScript() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getCustomScript`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

//Css and Script API End

//Css and Script API Start
export async function updateBrandSetting(data) {
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateBrandSetting`,
        data: {
            title: data.title,
            url: url,
            logoFileName: data.logoFileName,
            faviconFileName: data.faviconFileName,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteBrandSettingLogo(data) {
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${url}/api/admin/deleteBrandSettingLogo`,
        data: {
            filename: data.filename,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function updateBrandSettingLogo(data) {
    const formData = new FormData();

    formData.append("image", data.image);

    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updateBrandSettingLogo`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getBrandSetting() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getBrandSetting`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

//Css and Script API End

//Media Library API Start
export async function createMediaLibrary(data) {
    const formData = new FormData();

    const compressFileData = await compressFile(data.file)

    formData.append("name", compressFileData.name);
    formData.append("file", compressFileData.file);
    formData.append("extension", compressFileData.extension);
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/createMediaLibrary`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getMediaLibrary() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getMediaLibrary`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function deleteMediaLibrary(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteMediaLibrary`,
        data: {
            documentID: data.documentID,
            fileName: data.fileName,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function deleteStaticImage(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteStaticImage`,
        data: {
            fileName: data.fileName,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//Media Library API End

//cdn API Start
export async function createCdnLibrary(data) {
    const formData = new FormData();
    const url = Keys.API_URL !== "" ? Keys.API_URL : "http://localhost:5000";
    formData.append("name", data.name);
    formData.append("file", data.file);
    formData.append("extension", data.extension);
    formData.append("type", data.type);
    formData.append("url", url);
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/createCdnLibrary`,
        data: formData,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function getCdnLibrary() {
    return await axios({
        method: "get",
        headers: {
            authorization: constants.tokenSecret,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/getCdnLibrary`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

export async function deleteCdnLibrary(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/deleteCdnLibrary`,
        data: {
            documentID: data.documentID,
            fileName: data.fileName,
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

//cdn API End

// Page Checker API Start
export async function updatePageChecker(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/updatePageChecker`,
        data: {
            currentPage: data.currentPage,
            isActive: data.isActive,
            ip: data.ip
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function pageChecker(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/pageChecker`,
        data: {
            currentPage: data.currentPage,
            ip: data.ip
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function removeInactivePageChecker(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/removeInactivePageChecker`,
        data: {
            ip: data.ip
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export async function takeControlPageChecker(data) {
    return await axios({
        method: "post",
        headers: {
            authorization: localStorage.getItem("a_t") || null,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${Keys.API_URL}/api/admin/takeControlPageChecker`,
        data: {
            ip: data.ip,
            currentPage: data.currentPage
        },
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

// Page Checker API End