import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Row, Tabs, Typography} from 'antd';

import {useNavigate} from "react-router-dom";
import {addNewSidebars, addNewUrl, getLanguages, searchUrl} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import CodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";
import {javascript} from "@codemirror/lang-javascript";
import {MinusCircleOutlined} from "@ant-design/icons";

export default function CreateSidebar() {

    const [languageList, setLanguageList] = useState(false);
    const [javascriptCount, setJavascriptCount] = useState([]);
    const [javascriptCountStatus, setJavascriptCountStatus] = useState("");
    const navigate = useNavigate();
    const {TextArea} = Input;
    const {Text, Link} = Typography;
    const [formRef] = Form.useForm();
    const pushLanguagesData = async (item, values) => {

        let custom = {}
        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        return {custom}
    }

    const onFinish = async (values) => {

        let CustomDetails = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
            })
        })

        let urlStatus;
        let defaultUrl;

        do {
            defaultUrl = "/sidebar/" + Math.floor(Math.random() * 9999);
            searchUrl({
                path: defaultUrl
            }).then((res) => {
                if (res === null) {
                    urlStatus = "not used"
                } else {
                    urlStatus = "used"
                }
            })

        } while (urlStatus === "used")


        addNewSidebars(
            {
                title: values?.title,
                defaultPath: defaultUrl,
                details: CustomDetails,
                scripts:values?.scripts
            }
        ).then((res) => {
            addNewUrl({
                path: defaultUrl,
                type: "Sidebar",
                referralID: res._id,
            })

            navigate(-1)
            message.success('Sidebar created successfully');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const handleJavascriptCount = async () => {
        let jsTextarea = document.getElementsByClassName("jsTextarea");
        let ary = []
        let status = [];
        for (let i = 0; i < jsTextarea.length; i++) {
            let currentData = formRef.getFieldsValue(true);
            let jsLength = currentData.scripts[i].length
            ary.push({
                className: "jsTextarea-" + i,
                length: jsLength,
                status: jsLength > 10000 ? "error" : true,
                maxLength: 10000
            })
            if (jsLength > 10000) {
                status.push("error")
            }

        }
        if (status.length > 0) {
            setJavascriptCountStatus("error")
        } else {
            setJavascriptCountStatus("")
        }
        setJavascriptCount(ary)
        console.log(ary)
    }

    useEffect(() => {
        dispatchLanguages();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        name="siteCreate"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={formRef}
                    >
                        <Row>
                            <Col span={12}>
                                <h3>New Sidebar</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit">
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        {languageList.status === 200 && languageList.list.length > 0 ? (
                            <Col>
                                <Tabs>
                                    <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                        <Row gutter={[16, 16]}>
                                            <Col span={4}>
                                                Title:
                                            </Col>
                                            <Col span={20}>
                                                <Form.Item
                                                    name="title" rules={[{ required: true,message:"Title is required" }]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={[16, 16]}>
                                            <Col span={4}>
                                                JavaScript:
                                            </Col>
                                            <Col span={20}>
                                                <Form.List
                                                    name="scripts"
                                                >
                                                    {(fields, {add, remove}, {errors}) => (
                                                        <>
                                                            {fields.map((field, index) => {

                                                                let className = "jsTextarea-" + index;
                                                                let length = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).length;
                                                                let status = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).status;
                                                                let maxLength = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).maxLength;
                                                                return (
                                                                    <Form.Item
                                                                        required={false}
                                                                        key={field.key}
                                                                    >
                                                                        <div className="row">
                                                                            <div className="col-11">
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    style={{marginBottom: "7px"}}
                                                                                >
                                                                                    <CodeMirror
                                                                                        className={"jsTextarea jsTextarea-" + index}
                                                                                        value=""
                                                                                        height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                                                        extensions={[javascript()]}
                                                                                        onKeyUp={handleJavascriptCount}
                                                                                    />
                                                                                    {/*<TextArea*/}
                                                                                    {/*    className={"jsTextarea jsTextarea-" + index}*/}
                                                                                    {/*    onKeyUp={handleJavascriptCount}*/}
                                                                                    {/*    spellCheck={false}*/}
                                                                                    {/*    rows={window.screen.height >= 1000 ? 20 : 10}/>*/}
                                                                                </Form.Item>
                                                                                {
                                                                                    status !== "error" ? (
                                                                                        <Text
                                                                                            type="secondary">{length}/{maxLength} Characters</Text>
                                                                                    ) : (
                                                                                        <Text
                                                                                            type="danger">{length}/{maxLength} Characters</Text>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <div className="col-1">
                                                                                {fields.length > 1 ? (
                                                                                    <MinusCircleOutlined
                                                                                        className="dynamic-delete-button"
                                                                                        onClick={() => remove(field.name)}
                                                                                    />
                                                                                ) : null}
                                                                            </div>

                                                                        </div>
                                                                    </Form.Item>
                                                                )
                                                            })}
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => add()}
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    Add Javascript
                                                                </Button>

                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </Col>
                                        </Row>

                                    </Tabs.TabPane>
                                    {languageList.list.map((lang, index) => (
                                        <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"} key={"detail-" + index}
                                                      forceRender={true}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Details:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"detail-" + lang.code}>
                                                        {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 20:10}/>*/}
                                                        <CodeMirror
                                                            value=""
                                                            height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                            extensions={[html()]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    ))}

                                </Tabs>
                            </Col>
                        ) : (languageList.status === 200) ? (
                            <Col className="mt-5">
                                <EmptyLanguage/>
                            </Col>
                        ) : ""
                        }


                    </Form>
                </div>
            </div>
        </>
    );


};

