//landing
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
//dashboard
import SitePages from "../pages/site_pages";
import Header from "../pages/header";
import Footer from "../pages/footer";
import Sidebar from "../pages/sidebar";
import MediaLibrary from "../pages/media_library";
import CdnLibrary from "../pages/cdn_library";
import SiteSection from "../pages/site_section";
import GeneralSetting from "../pages/general_setting";
import UserGuide from "../pages/user_guide";

export const landingMenu = [
    {
        path: '/admin/login',
        name: 'Login',
        components: <Login/>

    },
    {
        path: '/admin/register',
        name: 'Register',
        components: <Register/>
    }
];

export const dashbordMenu = [
    {
        path: '/admin/home',
        name: 'Site Pages',
        type: 'main',
        components: <SitePages/>
    },
    {
        path: '/admin/header',
        name: 'Header',
        type: 'main',
        components: <Header/>
    },
    {
        path: '/admin/footer',
        name: 'Footer',
        type: 'main',
        components: <Footer/>
    },
    {
        path: '/admin/sidebar',
        name: 'Sidebar',
        type: 'main',
        components: <Sidebar/>
    },
    {
        path: '/admin/cdn-library',
        name: 'Plug In',
        type: 'main',
        components: <CdnLibrary/>
    },
    {
        path: '/admin/media-library',
        name: 'Media Library',
        type: 'main',
        components: <MediaLibrary/>
    },
    {
        name: 'Webmaster',
        type: 'sub',
        sub: [
            {
            path: '/admin/setting',
            name: 'General Setting',
            components: <GeneralSetting/>
             },
            {
                path: '/admin/user-guide',
                name: 'User Guide',
                components: <UserGuide/>
            },
            {
                path: '/admin/sections',
                name: 'Site Section',
                components: <SiteSection/>
            }
        ]
    },
];