import axios from "axios";
import querystring from 'querystring';

export async function compressFile(file, compression = 0.8) {
    if (file.type.startsWith("image/")) {
        // Skip compression for GIF images
        if (file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "image/png") {
            return {
                file: file,
                name: file.name,
                extension: file.type.split("/")[1],
            };
        }

        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Load the file into a blob object
        const blob = await fetch(URL.createObjectURL(file)).then((res) => res.blob());

        // Create a new image object from the blob
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        // Wait for the image to load
        await new Promise((resolve) => {
            img.onload = () => resolve();
        });

        // Set the canvas dimensions to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a data URL with WebP compression
        const dataUrl = canvas.toDataURL("image/webp", compression);

        // Convert the data URL to a Blob object
        const compressedBlob = await fetch(dataUrl).then((res) => res.blob());

        // Create a new file from the Blob with a .webp extension
        const compressedFile = new File(
            [compressedBlob],
            file.name.replace(/\.\w+$/, "") + ".webp",
            {
                type: "image/webp",
                lastModified: Date.now(),
            }
        );

        return {
            file: compressedFile,
            name: compressedFile.name,
            extension: "webp",
        };
    } else {
        // Return the file if it's not an image
        return {
            file: file,
            name: file.name,
            extension: file.type.split("/")[1],
        };
    }
}

export async function cssMinifier(css) {
    const encodedCss = querystring.stringify({input: css});
    return await axios({
        method: "post",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: encodedCss,
        url: `https://www.toptal.com/developers/cssminifier/api/raw`,
        crossdomain: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

