import React, {useEffect, useState} from "react";
import {Button, Col, message, Popconfirm, Row, Select, Space, Table} from 'antd';
import {useNavigate} from "react-router-dom";
import {deleteSidebars, getSidebarsListing} from "../../service";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {LoadingScreen} from "../../layout/errorMessage";

const {Option} = Select;
export default function Sidebar() {
    const navigate = useNavigate();
    const [sidebarList, setSidebarList] = useState(false);

    const dispatchSidebars = () => {
        getSidebarsListing().then(async (response) => {
            setSidebarList(response)
        });
    };

    const deleteSidebar = (id) => {
        deleteSidebars({
            documentID: id,
        }).then((res) => {
            dispatchSidebars();
            message.success('Sidebar deleted successfully');
        });
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a href={window.location.origin+"/#"+record.defaultPath} target="_blank">
                        <Button>View</Button>
                    </a>
                    <Button type="primary" key={record.key}
                            onClick={() => navigate('/admin/sidebar-edit/' + record._id)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => deleteSidebar(record._id)}
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                    >
                        <Button type="danger">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        dispatchSidebars();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {
                        sidebarList !== false ? (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <h3>Sidebar</h3>
                                    </Col>
                                    <Col span={12} className="text-end">
                                        <Button type="primary" shape="round" onClick={() => navigate('/admin/sidebar-create')}>
                                            Add New Sidebar
                                        </Button>
                                    </Col>
                                </Row>

                                <Col className="mt-3">
                                    <Table dataSource={sidebarList} columns={columns}/>
                                </Col>

                            </>

                        ) : (
                            <LoadingScreen/>
                        )
                    }


                </div>
            </div>
        </>
    );
};

