import React, {useEffect, useState} from "react";
import {Button, Col, Form, Image, message, Modal, Row, Tooltip, Upload} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, InboxOutlined, InfoCircleFilled} from "@ant-design/icons";
import {createCdnLibrary, deleteCdnLibrary, getCdnLibrary} from "../../service";
import Keys from "../../_config";
import {EmptyData, LoadingScreen} from "../../layout/errorMessage";
import {useNavigate} from "react-router-dom";

const {Dragger} = Upload;
const {confirm} = Modal;

function truncateString(str, num) {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}

export default function CdnLibrary() {
    const [modalVisible, setModalVisible] = useState(false);
    const [cdnState, setCdnState] = useState([]);
    const [cdnLists, setCdnLists] = useState(false);
    const navigate = useNavigate();

    const handleOpenModal = () => {
        setModalVisible(true)
    }

    const handleRemoveCdn = (documentID, fileName) => {
        confirm({
            title: "Do you Want to delete this file?",
            icon: <ExclamationCircleOutlined/>,

            onOk() {
                deleteCdnLibrary({
                    documentID: documentID,
                    fileName: fileName,
                }).then((response) => {
                    message.success('File deleted successfully');
                    dispatchCdnLibrary()
                });
            },

            onCancel() {
            },
        });
    };

    const handleCreateCdn = async () => {

        let totalCdnLength = cdnState.length
        await cdnState.map((value, index) => {
            let fileExtension = value.name.substring(value.name.lastIndexOf('.') + 1, value.name.length)
            let type;
            if (fileExtension === "js") {
                type = "script"
            } else if (fileExtension === "css") {
                type = "css"
            }
            if (type === "script" || type === "css") {
                createCdnLibrary(
                    {
                        name: value.name,
                        file: value.originFileObj,
                        extension: fileExtension,
                        type: type,
                    }
                ).then((res) => {
                    if (index + 1 === totalCdnLength) {
                        setTimeout(dispatchCdnLibrary, 1000)
                    }
                })
            }

        })
        // dispatchCdnLibrary()
        message.success('File created successfully');
        setModalVisible(false)

    }

    const handleCancelMedia = () => {
        setModalVisible(false)
    }

    const uploadMedia = {
        name: 'media',
        multiple: false,
        maxCount: 1,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        beforeUpload: () => {
            return false;
        }
    };

    const normFile = (e) => {
        setCdnState(e?.fileList)
    };


    const cdnScriptList = Array.isArray(cdnLists) && cdnLists.map((media, index) => {

        if (media.type === "script") {
            const copy = async () => {
                await navigator.clipboard.writeText(Keys.MEDIA_URL + media.fileName);
                message.success('URL copied');
            }

            return (
                <>
                    <Col>
                        <Col>
                            <a href={media.fileUrl} target="_blank">
                                <Image alt="example" src={`${Keys.MEDIA_URL}js-icon.png`} preview={false}
                                       style={{height: "150px", objectFit: "cover"}}/>
                            </a>
                        </Col>
                        <Col className="text-center">
                            <Tooltip placement="bottom" title={media.originFileName}>
                                <span className="mt-3">{truncateString(media.originFileName, 13)}</span>
                            </Tooltip>
                            <a className="ms-1" onClick={() => {
                                handleRemoveCdn(media._id, media.fileName);
                            }}>
                                <Tooltip placement="bottom" title={`Remove Image`}>
                                    <DeleteOutlined key="delete"/>
                                </Tooltip>
                            </a>
                        </Col>
                    </Col>
                </>

            )
        }

    })

    const cdnCssList = Array.isArray(cdnLists) && cdnLists.map((media, index) => {

        if (media.type === "css") {
            const copy = async () => {
                await navigator.clipboard.writeText(Keys.MEDIA_URL + media.fileName);
                message.success('URL copied');
            }

            return (
                <>
                    <Col>
                        <Col>
                            <a href={Keys.SERVER_URL + media.fileUrl} target="_blank">
                                <Image alt="example" src={`${Keys.MEDIA_URL}css-icon.png`} preview={false}
                                       style={{height: "150px", objectFit: "cover"}}/>
                            </a>

                        </Col>
                        <Col className="text-center">
                            <Tooltip placement="bottom" title={media.originFileName}>
                                <span className="mt-3">{truncateString(media.originFileName, 13)}</span>
                            </Tooltip>
                            <a className="ms-1" onClick={() => {
                                handleRemoveCdn(media._id, media.fileName);
                            }}>
                                <Tooltip placement="bottom" title={`Remove File`}>
                                    <DeleteOutlined key="delete"/>
                                </Tooltip>
                            </a>
                        </Col>
                    </Col>
                </>

            )
        }

    })

    const dispatchCdnLibrary = () => {
        getCdnLibrary().then(async (response) => {
            if (response.length > 0) {
                setCdnLists(response)
            } else {
                setCdnLists("")
            }
        });
    };

    useEffect(() => {
        dispatchCdnLibrary();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {cdnLists !== false ?
                        <>
                            <Row className="mb-3">
                                <Col span={12}>
                                    <Row className="mb-3">
                                        <Col>
                                            <h3 className="me-2">Plug In</h3>
                                        </Col>
                                        <Col className="pt-1">
                                            <Tooltip placement="bottom" title={`Only Js and Css files can be uploaded`}>
                                                <InfoCircleFilled/>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} className="text-end">
                                    <Button type="primary" shape="round" onClick={handleOpenModal}>
                                        Add New Package
                                    </Button>
                                </Col>
                            </Row>

                            <Col>
                                {Array.isArray(cdnLists) && cdnLists !== false ?
                                    <>
                                        {
                                            cdnLists.find((item) => item.type === "script") && (
                                                <>
                                                    <Col span={12} className="mt-3 mb-3">
                                                        <h5>Script</h5>
                                                    </Col>
                                                    <Row gutter={[8, 8]}>
                                                        {cdnScriptList}
                                                    </Row>
                                                </>
                                            )
                                        }
                                        {
                                            cdnLists.find((item) => item.type === "css") && (
                                                <>
                                                    <Col span={12} className="mt-3 mb-3">
                                                        <h5>Css</h5>
                                                    </Col>
                                                    <Row gutter={[8, 8]}>
                                                        {cdnCssList}
                                                    </Row>
                                                </>
                                            )
                                        }


                                    </>
                                    :
                                    <Col className="text-center" style={{marginTop: "14%"}}>
                                        <EmptyData/>
                                    </Col>}
                            </Col>
                        </>

                        :
                        <LoadingScreen/>
                    }


                </div>
            </div>

            <Modal title="Add New Package" visible={modalVisible} onOk={handleCreateCdn} okText={"Save Changes"}
                   onCancel={handleCancelMedia} destroyOnClose={true}>
                <Form
                    name="mediaCreate"
                    autoComplete="off"
                >
                    <Col>

                        <Form.Item name="images" valuePropName="fileList"
                                   getValueFromEvent={normFile} noStyle>
                            <Dragger {...uploadMedia}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single upload only.<br/>
                                    Only Js and Css files can be uploaded
                                </p>
                            </Dragger>
                        </Form.Item>

                    </Col>
                </Form>
            </Modal>
        </>
    );
};

