import {Button, Col, Empty, Spin} from 'antd';
import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

export function EmptyLanguage() {
    const navigate = useNavigate();
    return (
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>Please add language to continue</span>
            }
        >
            <Button onClick={() => navigate("/admin/setting")} type="primary">Add Now</Button>
        </Empty>
    );
}

export function EmptyData() {
    return (
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>Data is empty</span>
            }
        >
        </Empty>
    );
}

export function LoadingScreen() {
    return (
        <div className="loadingCms">
            <Spin />
            <p className="mt-2">Loading...</p>
        </div>
    );
}

export function PathNotResolved() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname.split('/')[1] === "admin"){
            navigate("/admin/home");
        }
    }, []);
    return (
        <Col className="mb-5 mt-5">
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 200,
                }}
                description={
                    <span>Path not resolved</span>
                }
            >
            </Empty>
        </Col>
    );
}

