import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Row, Tabs, Tag, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from "react-router-dom";
import {
    addNewSectionCategory,
    addNewSectionCategoryImage,
    getLanguages,
    searchSectionCategory,
    searchSectionCategoryBySlug
} from "../../service";

export default function CreateSectionCategory() {
    const [languageList, setLanguageList] = useState(false);
    const [mainSectionCategory, setMainSectionCategory] = useState(false);
    let {infoID, type} = useParams();
    const navigate = useNavigate();
    const {TextArea} = Input;
    const [slugState, setSlugState] = useState("");
    const [slugInvalid, setSlugInvalid] = useState(false);

    const pushLanguagesData = async (item, values) => {
        let title = {}
        //title
        var titleClass = "title-" + item.code
        var titleDetails = {
            language: item.code,
            title: values[titleClass],
        }
        title = titleDetails

        return {title}
    }

    const onFinish = async (values) => {
        let titles = [];
        let sectionID = type === "main" ? infoID : mainSectionCategory.sectionID;
        let mainCategoryID = type === "main" ? 0 : infoID;
        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {
            res.map((v, i) => {
                titles.push(v.title)
            })
        })

        addNewSectionCategory({
            title: titles,
            sectionID: sectionID,
            mainCategoryID: mainCategoryID,
            type: type,
            slug: slugState,
        }).then((res) => {
            if (values?.img !== undefined && values?.img.file.originFileObj) {
                addNewSectionCategoryImage({
                    documentID: res,
                    file: values?.img.file.originFileObj,
                }).then((res) => {
                    navigate(-1)
                    message.success('Section Category created successfully');
                })
            } else {
                navigate(-1)
                message.success('Section Category created successfully');
            }


        })


    };

    const dispatchSearchSectionCategory = () => {
        type === "sub" && searchSectionCategory({
            documentID: infoID
        }).then((res) => {
            setMainSectionCategory(res)
        })
    }

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const onChangeSlug = (e) => {
        let slug = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')

        setSlugState(slug)
        setSlugInvalid(false)
        searchSectionCategoryBySlug({
            slug: slug
        }).then((res) => {
            if (res._id) {
                setSlugInvalid(true)
            }
        })

    }

    useEffect(() => {
        dispatchLanguages();
        dispatchSearchSectionCategory();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        name="siteCreate"
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col span={12}>
                                <h3>{type === "main" ? "New Main Category" : "New Sub Category"}</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit"
                                        disabled={slugInvalid === false ? false : true}>
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        <Col>
                            <Tabs>

                                <Tabs.TabPane tab={"General"} key={"general"} forceRender={true}>
                                    {languageList && languageList.list.map((lang, index) => (
                                        <Row gutter={[16, 16]}>
                                            <Col span={6}>
                                                Title <Tag color="blue" className="ms-2">{lang.title}</Tag> :
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={"title-" + lang.code}
                                                    rules={[{required: true, message: "Title is required"}]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row gutter={[16, 16]}>
                                        <Col span={6}>
                                            Param :
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={"slug"} rules={[{required: true, message: "Slug is required"}]}
                                                help={slugState !== "" && slugInvalid === false ? "Your param is " + slugState : "Invalid Param"}
                                                validateStatus={slugInvalid === true?"error":""}
                                            >
                                                <Input onKeyUp={onChangeSlug}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]}>
                                        <Col span={6}>
                                            Image :
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item name={"img"}>
                                                <Upload
                                                    listType="picture"
                                                    maxCount={1}
                                                >
                                                    <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                </Tabs.TabPane>


                            </Tabs>
                        </Col>


                    </Form>
                </div>
            </div>
        </>
    );


};

