import React, {useEffect, useState} from "react";
import {Button, Col, Form, message, Popconfirm, Row, Select, Space, Table, Tag} from 'antd';

import {Link, useNavigate} from "react-router-dom";
import {
    deleteDraftSitePages,
    deleteSitePages,
    deleteSitePagesHistory,
    getSitePagesListing,
    updateSitePages
} from "../../service";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {LoadingScreen} from "../../layout/errorMessage";

const {Option} = Select;
export default function SitePages() {
    const [sitePageList, setSitePageList] = useState(false);
    const navigate = useNavigate();

    const dispatchSitePages = () => {
        getSitePagesListing().then(async (response) => {
            setSitePageList(response)
        });
    };

    const deletePages = (id, historyIDs) => {
        deleteSitePages({
            documentID: id,
        }).then((res) => {
            if (historyIDs.length > 0) {
                historyIDs.map((res, i) => {
                    deleteSitePagesHistory({
                        documentID: res.id
                    })
                })

            }
            deleteDraftSitePages({
                documentID: id
            })
            dispatchSitePages();
            message.success('Site page deleted successfully');
        });
    }

    const handleDefaultPage = (value) => {

        updateSitePages({
            documentID: value
        }).then((res) => {
            message.success('Default page updated successfully');
        })
    }

    const columns = [
        {
            title: 'Title',
            key: 'title',
            render: (_, record) => (
                <Space size="middle">
                    {record?.title?.length > 0 && record?.title[0]?.title}
                </Space>
            )
        },
        {
            title: 'Status',
            key: 'active',
            render: (_, record) => (
                <Space size="middle">
                    {(record.active === true) ? <Tag color="success">
                        Active
                    </Tag> : <Tag color="error">
                        Not Active
                    </Tag>}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                const copyFriendlyUrl = async () => {
                    await navigator.clipboard.writeText(window.location.origin + "/#" + record.dynamicPath);
                    message.success('Friendly URL copied');
                }
                return (
                    <Space size="middle">
                        <Link to={record.defaultPath} target="_blank">
                            <Button>View</Button>
                        </Link>
                        <Button key={record.key} onClick={copyFriendlyUrl}>Copy Friendly URL</Button>
                        <Button type="primary" key={record.key}
                                onClick={() => navigate('/admin/sitepage-edit/' + record._id)}>Edit</Button>
                        <Popconfirm
                            title="Are you sure ?"
                            onConfirm={() => deletePages(record._id, record.historyIDs)}
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                        >
                            <Button type="danger">Delete</Button>
                        </Popconfirm>
                    </Space>
                )
            },
        },
    ];

    useEffect(() => {
        dispatchSitePages();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {
                        sitePageList !== false ? (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <h3>Site Pages</h3>
                                    </Col>
                                    <Col span={12} className="text-end">
                                        <Button type="primary" shape="round"
                                                onClick={() => navigate('/admin/sitepage-create')}>
                                            Add New Page
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form layout="vertical">
                                            {sitePageList && sitePageList ? (
                                                <Form.Item name="homepage" label="Select Home Page" className="w-100"
                                                           initialValue={(sitePageList && sitePageList.find(
                                                               (item) => item.isDefault === true)) ? sitePageList.find(
                                                               (item) => item.isDefault === true)._id : ""}>
                                                    <Select
                                                        placeholder="Select an option"
                                                        onChange={handleDefaultPage}
                                                        allowClear
                                                    >
                                                        {
                                                            sitePageList && sitePageList?.map((page, index) => (
                                                                <Option value={page?._id}
                                                                        key={index}>{page?.title?.length > 0 && page?.title[0]?.title}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            ) : ""}

                                        </Form>
                                    </Col>
                                </Row>

                                <Col>
                                    <Table dataSource={sitePageList} columns={columns}/>
                                </Col>
                            </>

                        ) : (
                            <LoadingScreen/>
                        )
                    }


                </div>
            </div>
        </>
    );
};

