import React, {useEffect, useState} from "react";

import {Button, Card, Col, Collapse, Form, Input, message, Row, Space, Tabs} from 'antd';
import {MinusCircleOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {addNewHeaders, addNewUrl, getLanguages, searchUrl} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import CodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";

const {Panel} = Collapse;

export default function CreateHeader() {

    const [languageList, setLanguageList] = useState(false);
    const navigate = useNavigate();
    const {TextArea} = Input;
    const [form] = Form.useForm();

    const pushLanguagesData = async (item, values) => {

        let custom = {}
        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        return {custom}
    }

    const onFinish = async (values) => {

        let CustomDetails = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
            })
        })

        let urlStatus;
        let defaultUrl;

        do {
            defaultUrl = "/header/" + Math.floor(Math.random() * 9999);
            searchUrl({
                path: defaultUrl
            }).then((res) => {
                if (res === null) {
                    urlStatus = "not used"
                } else {
                    urlStatus = "used"
                }
            })

        } while (urlStatus === "used")


        addNewHeaders(
            {
                title: values?.title,
                defaultPath: defaultUrl,
                details: CustomDetails,
                script:values?.script,
                link:values?.link,
            }
        ).then((res) => {
            addNewUrl({
                path: defaultUrl,
                type: "Header",
                referralID: res._id,
            })

            navigate(-1)
            message.success('Header created successfully');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };


    useEffect(() => {
        dispatchLanguages();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        form={form}
                        name="siteCreate"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <Col span={12}>
                                <h3>New Header</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit">
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        {languageList.status === 200 && languageList.list.length > 0 ? (
                            <Col>
                                <Tabs>
                                    <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                        <Row gutter={[16, 16]}>
                                            <Col span={4}>
                                                Title:
                                            </Col>
                                            <Col span={20}>
                                                <Form.Item
                                                    name="title"
                                                    rules={[{required: true, message: "Title is required"}]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Card size="small" title="Script CDN" style={{ width: "100%" }}>
                                            <Row gutter={[16, 16]} className="mt-3">
                                                <Col span={24}>
                                                    <Form.List name="script">
                                                        {(fields, {add, remove}) => (
                                                            <>
                                                                {fields.map(({ key, name, ...restField }) => (
                                                                    <Space
                                                                        key={key}
                                                                        style={{
                                                                            display: 'flex',
                                                                            marginBottom: 8,
                                                                        }}
                                                                        align="baseline"
                                                                    >
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'src']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing src',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Src" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'integrity']}
                                                                        >
                                                                            <Input placeholder="Integrity" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'crossorigin']}
                                                                        >
                                                                            <Input placeholder="Cross Origin" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'referrerpolicy']}
                                                                        >
                                                                            <Input placeholder="Referrer Policy" />
                                                                        </Form.Item>
                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                    </Space>
                                                                ))}

                                                                <Form.Item>
                                                                    <Button type="dashed" onClick={() => add()} block>
                                                                        Add Script CDN
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card size="small" title="Link CDN" style={{ width: "100%",marginTop:"3%" }}>
                                            <Row gutter={[16, 16]} className="mt-3">
                                                <Col span={24}>
                                                    <Form.List name="link">
                                                        {(fields, {add, remove}) => (
                                                            <>
                                                                {fields.map(({ key, name, ...restField }) => (
                                                                    <Space
                                                                        key={key}
                                                                        style={{
                                                                            display: 'flex',
                                                                            marginBottom: 8,
                                                                        }}
                                                                        align="baseline"
                                                                    >
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'href']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing Href',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Href" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'rel']}
                                                                        >
                                                                            <Input placeholder="Rel" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'integrity']}
                                                                        >
                                                                            <Input placeholder="Integrity" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'crossorigin']}
                                                                        >
                                                                            <Input placeholder="Cross Origin" />
                                                                        </Form.Item>

                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                    </Space>
                                                                ))}

                                                                <Form.Item>
                                                                    <Button type="dashed" onClick={() => add()} block>
                                                                        Add Link CDN
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </Col>
                                            </Row>
                                        </Card>


                                    </Tabs.TabPane>
                                    {languageList.list.map((lang, index) => (
                                        <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"} key={"detail-" + index}
                                                      forceRender={true}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Details:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"detail-" + lang.code}>
                                                        {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 20:10}/>*/}
                                                        <CodeMirror
                                                            value=""
                                                            height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                            extensions={[html()]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    ))}

                                </Tabs>
                            </Col>
                        ) : (languageList.status === 200) ? (
                            <Col className="mt-5">
                                <EmptyLanguage/>
                            </Col>
                        ) : ""
                        }


                    </Form>
                </div>
            </div>
        </>
    );


};

