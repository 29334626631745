import logo1 from "../assets/images/blueocean.svg"
import logo2 from "../assets/images/minos_logo_dark_primary-01.png"
import logo3 from "../assets/images/cronos.png"
import logo4 from "../assets/images/funda.svg"
import logo5 from "../assets/images/360TradeHub.svg"
import logo6 from "../assets/images/antos-logo_vertical_dark.svg"
import logo7 from "../assets/images/equinox.svg"
import logo8 from "../assets/images/exco_primary_dark.svg"
import logo9 from "../assets/images/ridgeway_secondary_dark.png"
import logo10 from "../assets/images/Allium.svg"
import logo11 from "../assets/images/lynk-logo.svg"
import logo12 from "../assets/images/metapro-loader.svg"
import logo13 from "../assets/images/QV_logo.svg"

const domain = window.location.hostname;
let Keys;

switch (domain) {
    case "www.blueoceanfin.com":
    case "blueoceanfin.com":
        Keys = {
            brandName: "Blue Ocean",
            API_URL: "https://cmsapi.blueoceanfin.com",
            SERVER_URL: "https://cmsapi.blueoceanfin.com",
            MEDIA_URL: "https://cmsapi.blueoceanfin.com/media/",
            LOGO: logo1,
            LOGO_CLASSNAME: "blueocean-logo",
            background: "#ffffff",
            description: "Blue Ocean is a leading provider and innovator of CFD trading, constantly pushing the boundaries through cutting-edge research and development."
        };
        break;
    case "www.blueocex.com":
    case "blueocex.com":
        Keys = {
            brandName: "Blue Ocean",
            API_URL: "https://cmsapi.blueocex.com",
            SERVER_URL: "https://cmsapi.blueocex.com",
            MEDIA_URL: "https://cmsapi.blueocex.com/media/",
            LOGO: logo1,
            LOGO_CLASSNAME: "blueocean-logo",
            background: "#ffffff",
            description: "Blue Ocean is a leading provider and innovator of CFD trading, constantly pushing the boundaries through cutting-edge research and development."
        };
        break;
    case "www.minosfutures.com":
    case "minosfutures.com":
        Keys = {
            brandName: "Minos Futures",
            API_URL: "https://cmsapi.minosfutures.com",
            SERVER_URL: "https://cmsapi.minosfutures.com",
            MEDIA_URL: "https://cmsapi.minosfutures.com/media/",
            LOGO: logo2,
            LOGO_CLASSNAME: "minos-logo",
            background: "#001D19",
            description: "Minos Limited has established a reputation as one of the most trustworthy brokers in the field, providing a diverse range of trading opportunities across commodities to traders globally."
        };
        break;
    case "www.minosbrokers.com":
    case "minosbrokers.com":
        Keys = {
            brandName: "Minos Futures",
            API_URL: "https://cmsapi.minosbrokers.com",
            SERVER_URL: "https://cmsapi.minosbrokers.com",
            MEDIA_URL: "https://cmsapi.minosbrokers.com/media/",
            LOGO: logo2,
            LOGO_CLASSNAME: "minos-logo",
            background: "#001D19",
            description: "Minos Limited has established a reputation as one of the most trustworthy brokers in the field, providing a diverse range of trading opportunities across commodities to traders globally."
        };
        break;
    case "www.cronoscap.com":
    case "cronoscap.com":
        Keys = {
            brandName: "Cronos",
            API_URL: "https://cmsapi.cronoscap.com",
            SERVER_URL: "https://cmsapi.cronoscap.com",
            MEDIA_URL: "https://cmsapi.cronoscap.com/media/",
            LOGO: logo3,
            LOGO_CLASSNAME: "cronos-logo",
            background: "#131313",
            description: "Cronoscap is a licensed and regulated online broker offering world-class trading services, education, and market insights to both novice and seasoned traders in the Forex and SFC markets globally."
        };
        break;
    case "www.cronoscap.net":
    case "cronoscap.net":
        Keys = {
            brandName: "Cronos",
            API_URL: "https://cmsapi.cronoscap.net",
            SERVER_URL: "https://cmsapi.cronoscap.net",
            MEDIA_URL: "https://cmsapi.cronoscap.net/media/",
            LOGO: logo3,
            LOGO_CLASSNAME: "cronos-logo",
            background: "#131313",
            description: "Cronoscap is a licensed and regulated online broker offering world-class trading services, education, and market insights to both novice and seasoned traders in the Forex and SFC markets globally."

        };
        break;
    case "www.fundamarkets.net":
    case "fundamarkets.net":
        Keys = {
            brandName: "Funda Markets",
            API_URL: "https://cmsapi.fundamarkets.net",
            SERVER_URL: "https://cmsapi.fundamarkets.net",
            MEDIA_URL: "https://cmsapi.fundamarkets.net/media/",
            LOGO: logo4,
            LOGO_CLASSNAME: "funda-logo",
            background: "#ffffff",
            description: "Funda is a globally licensed and regulated forex and CFD online broker providing world-class trading services, education and market insights to both novice and seasoned traders."
        };
        break;
    case "www.fundamarkets.com":
    case "fundamarkets.com":
        Keys = {
            brandName: "Funda Markets",
            API_URL: "https://cmsapi.fundamarkets.com",
            SERVER_URL: "https://cmsapi.fundamarkets.com",
            MEDIA_URL: "https://cmsapi.fundamarkets.com/media/",
            LOGO: logo4,
            LOGO_CLASSNAME: "funda-logo",
            background: "#ffffff",
            description: "Funda is a globally licensed and regulated forex and CFD online broker providing world-class trading services, education and market insights to both novice and seasoned traders."

        };
        break;
    case "www.360tradehub.com":
    case "360tradehub.com":
        Keys = {
            brandName: "360tradehub",
            API_URL: "https://cmsapi.360tradehub.com",
            SERVER_URL: "https://cmsapi.360tradehub.com",
            MEDIA_URL: "https://cmsapi.360tradehub.com/media/",
            LOGO: logo5,
            LOGO_CLASSNAME: "tradehub360-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.360th.net":
    case "360th.net":
        Keys = {
            brandName: "360tradehub",
            API_URL: "https://cmsapi.360th.net",
            SERVER_URL: "https://cmsapi.360th.net",
            MEDIA_URL: "https://cmsapi.360th.net/media/",
            LOGO: logo5,
            LOGO_CLASSNAME: "tradehub360-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.tradehub360.com":
    case "tradehub360.com":
        Keys = {
            brandName: "360tradehub",
            // API_URL:"https://cmsapi.tradehub360.com",
            // SERVER_URL:"https://cmsapi.tradehub360.com",
            // MEDIA_URL:"https://cmsapi.tradehub360.com/media/",
            API_URL: "https://cmsapi.360th.net",
            SERVER_URL: "https://cmsapi.360th.net",
            MEDIA_URL: "https://cmsapi.360th.net/media/",
            LOGO: logo5,
            LOGO_CLASSNAME: "tradehub360-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.antospnc.com":
    case "antospnc.com":
        Keys = {
            brandName: "Antospnc",
            API_URL: "https://cmsapi.antospnc.com",
            SERVER_URL: "https://cmsapi.antospnc.com",
            MEDIA_URL: "https://cmsapi.antospnc.com/media/",
            LOGO: logo6,
            LOGO_CLASSNAME: "antos-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.antos.io":
    case "antos.io":
        Keys = {
            brandName: "Antos",
            API_URL: "https://cmsapi.antos.io",
            SERVER_URL: "https://cmsapi.antos.io",
            MEDIA_URL: "https://cmsapi.antos.io/media/",
            LOGO: logo6,
            LOGO_CLASSNAME: "antos-logo",
            background: "#000000",
            description: ""
        };
        break;
    case "www.equinoxmarkets.com":
    case "equinoxmarkets.com":
        Keys = {
            brandName: "Equinox Markets",
            API_URL: "https://cmsapi.equinoxmarkets.com",
            SERVER_URL: "https://cmsapi.equinoxmarkets.com",
            MEDIA_URL: "https://cmsapi.equinoxmarkets.com/media/",
            LOGO: logo7,
            LOGO_CLASSNAME: "equinox-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.equinox-markets.com":
    case "equinox-markets.com":
        Keys = {
            brandName: "Equinox Markets",
            API_URL: "https://cmsapi.equinox-markets.com",
            SERVER_URL: "https://cmsapi.equinox-markets.com",
            MEDIA_URL: "https://cmsapi.equinox-markets.com/media/",
            LOGO: logo7,
            LOGO_CLASSNAME: "equinox-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.excotrades.com":
    case "excotrades.com":
        Keys = {
            brandName: "Excotrades",
            API_URL: "https://cmsapi.excotrades.com",
            SERVER_URL: "https://cmsapi.excotrades.com",
            MEDIA_URL: "https://cmsapi.excotrades.com/media/",
            LOGO: logo8,
            LOGO_CLASSNAME: "excotrades-logo",
            background: "#000000",
            description: "At Exco Limited, we are committed to providing you with an unparalleled trading experience. Our platform is built on cutting-edge technology, ensuring lightning-fast trade execution and minimal latency."
        };
        break;
    case "www.alliuminternational.com":
    case "alliuminternational.com":
        Keys = {
            brandName: "Alliuminternational",
            API_URL: "https://cmsapi.alliuminternational.com",
            SERVER_URL: "https://cmsapi.alliuminternational.com",
            MEDIA_URL: "https://cmsapi.alliuminternational.com/media/",
            LOGO: logo10,
            LOGO_CLASSNAME: "allium-logo",
            background: "#0B0B17",
            description: "Allium Limited is to provide traders with the utmost competitive spreads across all market conditions."
        };
        break;
    case "www.alliumtrades.com":
    case "alliumtrades.com":
        Keys = {
            brandName: "Alliuminternational",
            API_URL: "https://cmsapi.alliumtrades.com",
            SERVER_URL: "https://cmsapi.alliumtrades.com",
            MEDIA_URL: "https://cmsapi.alliumtrades.com/media/",
            LOGO: logo10,
            LOGO_CLASSNAME: "allium-logo",
            background: "#ffffff",
            description: "Allium Limited is to provide traders with the utmost competitive spreads across all market conditions."
        };
        break;
    case "localhost":
    case "www.ridgewaymarkets.com":
    case "ridgewaymarkets.com":
        Keys = {
            brandName: "Ridgeway Markets",
            API_URL: "https://cmsapi.ridgewaymarkets.com",
            SERVER_URL: "https://cmsapi.ridgewaymarkets.com",
            MEDIA_URL: "https://cmsapi.ridgewaymarkets.com/media/",
            LOGO: logo9,
            LOGO_CLASSNAME: "ridgeway-logo",
            background: "#100503",
            description: "Ridgeway stands at the forefront as a prominent facilitator, seamlessly connecting retail and institutional traders for unparalleled trading journeys. Our comprehensive solutions cater to a diverse spectrum of traders, encompassing beginners venturing into investments, active day traders, and skilled scalpers."
        };
        break;
    case "www.qvmarkets.com":
    case "qvmarkets.com":
        Keys = {
            brandName: "Quorvex Markets",
            API_URL: "https://cmsapi.qvmarkets.com",
            SERVER_URL: "https://cmsapi.qvmarkets.com",
            MEDIA_URL: "https://cmsapi.qvmarkets.com/media/",
            LOGO: logo13,
            LOGO_CLASSNAME: "qv-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.metapromarkets.com":
    case "metapromarkets.com":
        Keys = {
            brandName: "Metapro Markets",
            API_URL: "https://cmsapi.metapromarkets.com",
            SERVER_URL: "https://cmsapi.metapromarkets.com",
            MEDIA_URL: "https://cmsapi.metapromarkets.com/media/",
            LOGO: logo12,
            LOGO_CLASSNAME: "metapro-logo",
            background: "#010314",
            description: ""
        };
        break;
    //Staging
    case "stg.ridgewaymarkets.com":
        Keys = {
            brandName: "Ridgeway Markets",
            API_URL: "https://cmsapi-stg.ridgewaymarkets.com",
            SERVER_URL: "https://cmsapi-stg.ridgewaymarkets.com",
            MEDIA_URL: "https://cmsapi-stg.ridgewaymarkets.com/media/",
            LOGO: logo9,
            LOGO_CLASSNAME: "ridgeway-logo",
            background: "#100503",
            description: "Ridgeway stands at the forefront as a prominent facilitator, seamlessly connecting retail and institutional traders for unparalleled trading journeys. Our comprehensive solutions cater to a diverse spectrum of traders, encompassing beginners venturing into investments, active day traders, and skilled scalpers."
        };
        break;
    case "stg.alliumtrades.com":
        Keys = {
            brandName: "Alliuminternational",
            API_URL: "https://cmsapi-stg.alliumtrades.com",
            SERVER_URL: "https://cmsapi-stg.alliumtrades.com",
            MEDIA_URL: "https://cmsapi-stg.alliumtrades.com/media/",
            LOGO: logo10,
            LOGO_CLASSNAME: "allium-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "new.alliumtrades.com":
        Keys = {
            brandName: "Alliuminternational",
            API_URL: "https://cmsapi-stg-2.alliumtrades.com",
            SERVER_URL: "https://cmsapi-stg-2.alliumtrades.com",
            MEDIA_URL: "https://cmsapi-stg-2.alliumtrades.com/media/",
            LOGO: logo10,
            LOGO_CLASSNAME: "allium-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.alliuminternational.com":
        Keys = {
            brandName: "Alliuminternational",
            API_URL: "https://cmsapi-stg.alliuminternational.com",
            SERVER_URL: "https://cmsapi-stg.alliuminternational.com",
            MEDIA_URL: "https://cmsapi-stg.alliuminternational.com/media/",
            LOGO: logo10,
            LOGO_CLASSNAME: "allium-logo",
            background: "#0B0B17",
            description: ""
        };
        break;
    case "stg.cronoscap.com":
        Keys = {
            brandName: "Cronos",
            API_URL: "https://cmsapi-stg.cronoscap.com",
            SERVER_URL: "https://cmsapi-stg.cronoscap.com",
            MEDIA_URL: "https://cmsapi-stg.cronoscap.com/media/",
            LOGO: logo3,
            LOGO_CLASSNAME: "cronos-logo",
            background: "#131313",
            description: ""
        };
        break;
    case "stg.cronoscap.net":
        Keys = {
            brandName: "Cronos",
            API_URL: "https://cmsapi-stg.cronoscap.net",
            SERVER_URL: "https://cmsapi-stg.cronoscap.net",
            MEDIA_URL: "https://cmsapi-stg.cronoscap.net/media/",
            LOGO: logo3,
            LOGO_CLASSNAME: "cronos-logo",
            background: "#131313",
            description: ""
        };
        break;
    case "stg.blueoceanfin.com":
        Keys = {
            brandName: "Blue Ocean",
            API_URL: "https://cmsapi-stg.blueoceanfin.com",
            SERVER_URL: "https://cmsapi-stg.blueoceanfin.com",
            MEDIA_URL: "https://cmsapi-stg.blueoceanfin.com/media/",
            LOGO: logo1,
            LOGO_CLASSNAME: "blueocean-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.blueocex.com":
        Keys = {
            brandName: "Blue Ocean",
            API_URL: "https://cmsapi-stg.blueocex.com",
            SERVER_URL: "https://cmsapi-stg.blueocex.com",
            MEDIA_URL: "https://cmsapi-stg.blueocex.com/media/",
            LOGO: logo1,
            LOGO_CLASSNAME: "blueocean-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.fundamarkets.net":
        Keys = {
            brandName: "Funda Markets",
            API_URL: "https://cmsapi-stg.fundamarkets.net",
            SERVER_URL: "https://cmsapi-stg.fundamarkets.net",
            MEDIA_URL: "https://cmsapi-stg.fundamarkets.net/media/",
            LOGO: logo4,
            LOGO_CLASSNAME: "funda-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.fundamarkets.com":
        Keys = {
            brandName: "Funda Markets",
            API_URL: "https://cmsapi-stg.fundamarkets.com",
            SERVER_URL: "https://cmsapi-stg.fundamarkets.com",
            MEDIA_URL: "https://cmsapi-stg.fundamarkets.com/media/",
            LOGO: logo4,
            LOGO_CLASSNAME: "funda-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.antospnc.com":
        Keys = {
            brandName: "Antospnc",
            API_URL: "https://cmsapi-stg.antospnc.com",
            SERVER_URL: "https://cmsapi-stg.antospnc.com",
            MEDIA_URL: "https://cmsapi-stg.antospnc.com/media/",
            LOGO: logo6,
            LOGO_CLASSNAME: "antos-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.antos.io":
        Keys = {
            brandName: "Antos",
            API_URL: "https://cmsapi-stg.antos.io",
            SERVER_URL: "https://cmsapi-stg.antos.io",
            MEDIA_URL: "https://cmsapi-stg.antos.io/media/",
            LOGO: logo6,
            LOGO_CLASSNAME: "antos-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.excotrades.com":
        Keys = {
            brandName: "Excotrades",
            API_URL: "https://cmsapi-stg.excotrades.com",
            SERVER_URL: "https://cmsapi-stg.excotrades.com",
            MEDIA_URL: "https://cmsapi-stg.excotrades.com/media/",
            LOGO: logo8,
            LOGO_CLASSNAME: "excotrades-logo",
            background: "#000000",
            description: ""
        };
        break;
    case "stg.360tradehub.com":
        Keys = {
            brandName: "360tradehub",
            API_URL: "https://cmsapi-stg.360tradehub.com",
            SERVER_URL: "https://cmsapi-stg.360tradehub.com",
            MEDIA_URL: "https://cmsapi-stg.360tradehub.com/media/",
            LOGO: logo5,
            LOGO_CLASSNAME: "tradehub360-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.360th.net":
        Keys = {
            brandName: "360tradehub",
            API_URL: "https://cmsapi-stg.360th.net",
            SERVER_URL: "https://cmsapi-stg.360th.net",
            MEDIA_URL: "https://cmsapi-stg.360th.net/media/",
            LOGO: logo5,
            LOGO_CLASSNAME: "tradehub360-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.minosfutures.com":
        Keys = {
            brandName: "Minos Futures",
            API_URL: "https://cmsapi-stg.minosfutures.com",
            SERVER_URL: "https://cmsapi-stg.minosfutures.com",
            MEDIA_URL: "https://cmsapi-stg.minosfutures.com/media/",
            LOGO: logo2,
            LOGO_CLASSNAME: "minos-logo",
            background: "#001D19",
            description: ""
        };
        break;
    case "stg.axecap.co":
        Keys = {
            brandName: "Lynk Markets",
            API_URL: "https://cmsapi-stg-lynkmarkets.axecap.co",
            SERVER_URL: "https://cmsapi-stg-lynkmarkets.axecap.co",
            MEDIA_URL: "https://cmsapi-stg-lynkmarkets.axecap.co/media/",
            LOGO: logo11,
            LOGO_CLASSNAME: "lynk-logo",
            background: "#FFFFFF",
            description: ""

        };
        break;
    case "stg.equinoxmarkets.com":
        Keys = {
            brandName: "Equinox Markets",
            API_URL: "https://cmsapi-stg.equinoxmarkets.com",
            SERVER_URL: "https://cmsapi-stg.equinoxmarkets.com",
            MEDIA_URL: "https://cmsapi-stg.equinoxmarkets.com/media/",
            LOGO: logo7,
            LOGO_CLASSNAME: "equinox-logo",
            background: "#ffffff",
            description: ""

        };
        break;
    case "stg.equinox-markets.com":
        Keys = {
            brandName: "Equinox Markets",
            API_URL: "https://cmsapi-stg.equinox-markets.com",
            SERVER_URL: "https://cmsapi-stg.equinox-markets.com",
            MEDIA_URL: "https://cmsapi-stg.equinox-markets.com/media/",
            LOGO: logo7,
            LOGO_CLASSNAME: "equinox-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "stg.metapromarkets.com":
        Keys = {
            brandName: "Metapro Markets",
            API_URL: "https://cmsapi.metapromarkets.com",
            SERVER_URL: "https://cmsapi.metapromarkets.com",
            MEDIA_URL: "https://cmsapi.metapromarkets.com/media/",
            LOGO: logo12,
            LOGO_CLASSNAME: "metapro-logo",
            background: "#010314",
            description: ""
        };
        break;
    case "stg.qvmarkets.com":
        Keys = {
            brandName: "Quorvex Markets",
            API_URL: "https://cmsapi-stg.qvmarkets.com",
            SERVER_URL: "https://cmsapi-stg.qvmarkets.com",
            MEDIA_URL: "https://cmsapi-stg.qvmarkets.com/media/",
            LOGO: logo13,
            LOGO_CLASSNAME: "qv-logo",
            background: "#ffffff",
            description: ""
        };
        break;
    case "www.arconnect.ai":
    case "arconnect.ai":
        Keys = {
            brandName: "Arconnect",
            API_URL: "https://cmsapi.arconnect.ai",
            SERVER_URL: "https://cmsapi.arconnect.ai",
            MEDIA_URL: "https://cmsapi.arconnect.ai/media/",
            LOGO: null,
            LOGO_CLASSNAME: "qv-logo",
            background: "#ffffff",
            description: ""
        };
        break;
}

export default Keys;

