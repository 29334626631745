import {Alert, Button, Col, Image, Layout, Menu, message, Modal, Row,} from 'antd';
import React, {useEffect, useState} from 'react';
import {dashbordMenu} from '../route/routerMenu.js';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    getBrandSetting,
    getSections,
    logoutUser,
    removeInactivePageChecker,
    searchUser,
    takeControlPageChecker,
    updatePageChecker
} from "../service";
import Keys from "../_config";
import uuid from 'react-uuid';

const {Header, Content, Footer, Sider} = Layout;

const Index = props => {
    const [isLogin, setIsLogin] = useState(false);
    const [sectionList, setSectionList] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [brandSetting, setBrandSetting] = useState("");
    const [pageActive, setPageActive] = useState(false);
    const [uuids, setUuids] = useState("")

    //theme
    const [theme, setTheme] = useState('light');

    let bodySlot = props.children.props.children;

    const dispatchBrandSetting = () => {
        getBrandSetting().then(async (response) => {
            if (response.list.length > 0) {
                setBrandSetting(response.list[0])
            }
        });
    };

    //currentPath
    const currentPathName = location.pathname;

    const logout = () => {
        localStorage.removeItem("userID")
        localStorage.removeItem("a_t")
        message.success('Logout Successfully');
        navigate("/admin/login")
    };

    const checkIsLogin = () => {
        const userid = localStorage.getItem("userID")
        if (userid) {
            searchUser({
                documentID: userid
            }).then((res) => {
                if (res.status === "success") {
                    setIsLogin(true)
                } else {
                    localStorage.setItem("userID", "")
                    setIsLogin(false)
                    navigate("/admin/login");
                }
            })
        } else {
            setIsLogin(false)
            navigate("/admin/login");
        }
    };

    const dispatchSections = () => {
        getSections().then(async (response) => {
            setSectionList(response)
        });
    };

    const pageNotAvailable = () => {
        setPageActive(false)
        navigate(-1)
    }

    const handleTakeControl = () => {

        takeControlPageChecker({
            ip: uuids,
            currentPage: location.pathname
        }).then((res) => {
            updatePageChecker({
                currentPage: res,
                isActive: true,
                ip: uuids
            }).then((r) => {
                setPageActive(false)
            })

        })
    }

    const handleUUID = () => {
        let UUID;

        removeInactivePageChecker({
            ip: ""
        })

        if (localStorage.getItem("uuid") !== undefined && localStorage.getItem("uuid") !== "" && localStorage.getItem("uuid") !== null) {
            UUID = localStorage.getItem("uuid")

            setUuids(UUID)

        } else {

            UUID = uuid()
            localStorage.setItem("uuid", UUID)
            setUuids(UUID)
        }

        return UUID

    }

    const removePageChecker = (e) => {
        e.preventDefault();
        removeInactivePageChecker({
            ip: uuids
        }).then((res) => {
            setPageActive(false)
        })
    }

    // useEffect(() => {
    //
    //     let path = ["sitepage-edit", "header-edit", "footer-edit", "sidebar-edit", "editCategory", "postingEdit"]
    //     handleUUID()
    //
    //     if (uuids !== "") {
    //         if (path.includes(location.pathname.split('/')[2])) {
    //             window.addEventListener("beforeunload", removePageChecker, true)
    //             pageChecker({
    //                 currentPage: location.pathname,
    //                 ip: uuids
    //             }).then((res) => {
    //                 if (res.status === "available") {
    //                     setPageActive(false)
    //                     updatePageChecker({
    //                         currentPage: location.pathname,
    //                         isActive: true,
    //                         ip: uuids
    //                     })
    //                 } else {
    //                     setPageActive(true)
    //                 }
    //             })
    //
    //         } else {
    //             window.removeEventListener("beforeunload", removePageChecker, true)
    //             updatePageChecker({
    //                 currentPage: "",
    //                 isActive: true,
    //                 ip: uuids
    //             })
    //             setPageActive(false)
    //         }
    //     }
    //
    // }, [location])

    useEffect(() => {
        if (location.pathname === "/admin" || location.pathname === "/admin/") {
            navigate("/admin/home")
        }
        checkIsLogin();
        dispatchSections();
        dispatchBrandSetting();
    }, []);

    if (isLogin === true) {
        return (
            <>
                <Layout style={{minHeight: '100vh', width: '100vw', marginRight: '0'}} theme={theme}>
                    <Header className="site-layout-background" style={{padding: 0}} theme={theme}>
                        <Row justify="space-between" align="middle" gutter={16} style={{width: '100%'}}>
                            <Col>
                                <div className="logo" style={{color: 'white', height: '64px', margin: '0px 20px'}}>
                                    <Image
                                        width={50}
                                        preview={false}
                                        src={Keys.SERVER_URL + brandSetting.logoPath}
                                    />
                                </div>
                            </Col>
                            {/*<Col className="me-5">*/}
                            {/*    <Switch onChange={changeTheme} checkedChildren="dark" unCheckedChildren="light"/>*/}
                            {/*</Col>*/}

                        </Row>
                    </Header>
                    <Layout className="site-layout" theme={theme}>
                        <Sider theme={theme}
                               collapsed={false}> {/* collapsible onCollapse={(value) => setCollapsed(value)} */}
                            <Menu mode="inline" style={{width: '100%'}}
                                  defaultSelectedKeys={[dashbordMenu.findIndex(k => k.path === currentPathName).toString()]}
                                  defaultOpenKeys={['0']} theme={theme}>

                                <Col className="mt-3 px-3">
                                    <h6 style={{color: "#5E6278"}}>General</h6>
                                </Col>

                                {dashbordMenu.map((menuData, index) => {
                                    if (menuData.type === "main") {
                                        return (
                                            <Menu.Item key={index} theme={theme}>
                                                <Link to={menuData.path}>{menuData.name}</Link>
                                            </Menu.Item>
                                        )
                                    } else if (menuData.type === "sub") {
                                        return (
                                            <Menu.SubMenu title={menuData.name}>
                                                {
                                                    menuData.sub.map((subMenu, subIndex) => (
                                                        <Menu.Item key={'sub-' + subIndex} theme={theme}>
                                                            <Link to={subMenu.path}>{subMenu.name}</Link>
                                                        </Menu.Item>
                                                    ))
                                                }
                                            </Menu.SubMenu>
                                        )
                                    }
                                })}

                                {
                                    sectionList && sectionList.length > 0 ?
                                        <Col className="mt-3 px-3">
                                            <h6 style={{color: "#5E6278"}}>Sections</h6>
                                        </Col> : ""
                                }

                                {
                                    sectionList && sectionList.map((menuData, index) => {
                                        return (
                                            <Menu.SubMenu title={menuData.title}>
                                                <Menu.Item key={'category-' + index} theme={theme}>
                                                    <Link to={"/admin/categories/" + menuData._id}>Categories</Link>
                                                </Menu.Item>
                                                <Menu.Item key={'posting-' + index} theme={theme}>
                                                    <Link to={"/admin/posting/" + menuData._id}>Articles</Link>
                                                </Menu.Item>
                                            </Menu.SubMenu>
                                        )
                                    })
                                }

                                <Col className="mt-4 px-3">
                                    <Button type="danger" className="w-100"
                                            onClick={logout}>Logout</Button>
                                </Col>

                            </Menu>
                        </Sider>
                        <Content style={{margin: '10px 16px',}} theme={theme}>
                            {pageActive === false ? bodySlot : (
                                <div className="sitepages">
                                    <div className="site-layout-background">
                                    </div>
                                </div>
                            )}
                            <Footer style={{textAlign: 'center', padding: "0 50px"}} theme={theme}>
                                {brandSetting.title} ©{new Date().getFullYear()}
                            </Footer>
                        </Content>
                    </Layout>
                </Layout>
                <Modal title="Warning" visible={pageActive}
                       okText={"Take Control"}
                       destroyOnClose={true}
                       onOk={handleTakeControl} onCancel={pageNotAvailable}
                >
                    <div>
                        <Alert
                            message="Warning"
                            description="Someone is currently editing this page"
                            type="warning"
                            showIcon
                        />
                    </div>
                </Modal>
            </>
        );
    }
}

export default Index;
