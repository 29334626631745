import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography,
    Upload
} from 'antd';
import {
    addNewLanguage,
    deleteBrandSettingLogo,
    deleteLanguages,
    getBrandSetting,
    getCustomCss,
    getCustomCssVersion,
    getCustomJsVersion,
    getCustomScript,
    getLanguages,
    saveAsNewVersionCustomCss,
    saveAsNewVersionCustomJs,
    updateBrandSetting,
    updateBrandSettingLogo,
    updateCustomCss,
    updateCustomScript,
    updateNewLanguage
} from "../../service";
import languages from "../../context/languages";
import {MinusCircleOutlined, QuestionCircleOutlined, UploadOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import CodeMirror from '@uiw/react-codemirror';
import {javascript} from '@codemirror/lang-javascript';
import {css} from '@codemirror/lang-css';

function truncateString(str, num) {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}

export default function GeneralSetting() {
    const [isCreateLanguageModalOpen, setIsCreateLanguageModalOpen] = useState(false);
    const [isEditLanguageModalOpen, setIsEditLanguageModalOpen] = useState(false);
    const [languageList, setLanguageList] = useState(false);
    const [languageTitle, setLanguageTitle] = useState("");
    const [languageCode, setLanguageCode] = useState("");
    const [languageTitleEdit, setLanguageTitleEdit] = useState("");
    const [languageCodeEdit, setLanguageCodeEdit] = useState("");
    const [languageActiveEdit, setLanguageActiveEdit] = useState();
    const [languageID, setLanguageID] = useState("");
    const [customCss, setCustomCss] = useState("");
    const [customScript, setCustomScript] = useState("");
    const [oldLogoName, setOldLogoName] = useState("");
    const [oldLogoUrl, setOldLogoUrl] = useState("");
    const [oldFaviconName, setOldFaviconName] = useState("");
    const [oldFaviconUrl, setOldFaviconUrl] = useState("");
    const [brandTitle, setBrandTitle] = useState("");
    const [loadStatus, setLoadStatus] = useState("");
    const [loadCssStatus, setLoadCssStatus] = useState("");
    const [loadScriptStatus, setLoadScriptStatus] = useState("");
    const [javascriptCount, setJavascriptCount] = useState([]);
    const [javascriptCountStatus, setJavascriptCountStatus] = useState("");

    const [isCssCommitModalOpen, setIsCssCommitModalOpen] = useState(false);
    const [cssCommitMessage, setCssCommitMessage] = useState("");
    const [cssHistoryIDs, setCssHistoryIDs] = useState([]);
    const [currentCssHistoryID, setCurrentCssHistoryID] = useState("");
    const [isCssVersionModalOpen, setIsCssVersionModalOpen] = useState(false);
    const [cssVersionID, setCssVersionID] = useState("");

    const [isJsCommitModalOpen, setIsJsCommitModalOpen] = useState(false);
    const [jsCommitMessage, setJsCommitMessage] = useState("");
    const [jsHistoryIDs, setJsHistoryIDs] = useState([]);
    const [currentJsHistoryID, setCurrentJsHistoryID] = useState("");
    const [isJsVersionModalOpen, setIsJsVersionModalOpen] = useState(false);
    const [jsVersionID, setJsVersionID] = useState("");

    const navigate = useNavigate();

    const [formLanguagesCreate, formLanguagesEdit, formBrandSetting] = Form.useForm();
    const [formRef] = Form.useForm();

    const {TextArea} = Input;
    const {Text} = Typography;

    const languageColumns = [
        {
            title: 'Language Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Language Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Status',
            key: 'active',
            render: (_, record) => (
                <Space size="middle">
                    {(record.active === true) ? <Tag color="success">
                        Active
                    </Tag> : <Tag color="error">
                        Not Active
                    </Tag>}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" key={record.key}
                            onClick={() => showEditLanguageModal(record._id)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => deleteLanguage(record._id)}
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                    >
                        <Button type="danger">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const onFinishBrandSetting = async (values) => {

        let faviconFileName;
        let logoFileName;

        if (values?.logo !== undefined) {
            await updateBrandSettingLogo({
                image: values?.logo.file.originFileObj,
            }).then((res) => {
                if (oldLogoName.length > 0) {
                    deleteBrandSettingLogo({
                        filename: oldLogoName
                    })
                }
                logoFileName = res;

            })
        } else {
            logoFileName = oldLogoName
        }

        if (values?.favicon !== undefined) {
            await updateBrandSettingLogo({
                image: values?.favicon.file.originFileObj,
            }).then((res) => {
                if (oldFaviconName.length > 0) {
                    deleteBrandSettingLogo({
                        filename: oldFaviconName
                    })
                }
                faviconFileName = res;

            })
        } else {
            faviconFileName = oldFaviconName
        }

        updateBrandSetting({
            title: values?.brandName,
            logoFileName: logoFileName,
            faviconFileName: faviconFileName,
        }).then((res) => {
            message.success('Brand Setting updated successfully');
            navigate(0)
        })

    }

    const showCreateLanguageModal = () => {
        setIsCreateLanguageModalOpen(true);
    };

    const showEditLanguageModal = (id) => {
        setIsEditLanguageModalOpen(true);
        const LanguageEdit = languageList.find(obj => {
            return obj._id === id
        })
        setLanguageTitleEdit(LanguageEdit.title)
        setLanguageCodeEdit(LanguageEdit.code)
        setLanguageActiveEdit(LanguageEdit.active)
        setLanguageID(id)
    };

    const handleCreateLanguageModal = () => {
        if (languageCode.length > 0) {
            addNewLanguage({
                title: languageTitle,
                code: languageCode,
            }).then((response) => {
                dispatchLanguages();
                message.success('Languages created successfully');
                formLanguagesCreate.resetFields();
                setLanguageTitle("");
                setLanguageCode("");
                setIsCreateLanguageModalOpen(false);
            });
        } else {
            message.error('Please choose a language');
        }

    };

    const handleCancelLanguageModal = () => {
        setLanguageTitle("");
        setLanguageCode("");
        setIsCreateLanguageModalOpen(false);
        formLanguagesCreate.resetFields();
    };

    const handleEditLanguageModal = () => {
        updateNewLanguage({
            title: languageTitleEdit,
            code: languageCodeEdit,
            active: languageActiveEdit,
            documentID: languageID
        }).then((response) => {
            dispatchLanguages();
            message.success('Languages updated successfully');
            formLanguagesCreate.resetFields();
            setLanguageTitle("");
            setLanguageCode("");
            setIsEditLanguageModalOpen(false);
            setLanguageID("")
        });

    };

    const handleCancelEditLanguageModal = () => {
        setIsEditLanguageModalOpen(false);
        setLanguageID("")
    };

    const onChangeLanguageCode = (e) => {


        setLanguageTitle(languages.find((item) => item.code === e).name)

        setLanguageCode(e)
    }

    const onChangeEditLanguageCode = (e) => {


        setLanguageTitleEdit(languages.find((item) => item.code === e).name)
        setLanguageCodeEdit(e)
    }

    const onChangeEditLanguageActive = (e) => {
        if (languageActiveEdit === true) {
            setLanguageActiveEdit(false)
        } else {
            setLanguageActiveEdit(true)
        }
    }

    const onChangeCustomCss = (e) => {
        setCustomCss(e)
        // console.log(e)
    }

    const onChangeCustomScript = (e) => {
        setCustomScript(e)
    }

    const handleUpdateScript = () => {
        let currentData = formRef.getFieldsValue(true);

        currentData.customScript && currentData.customScript.length > 0 && currentData.customScript.map((data, index) => {
            if (data === null || data === undefined) {
                currentData.customScript[index] = ""
            }
        })

        updateCustomScript({
            scripts: currentData.customScript,
            currentHistoryId: ""
        }).then(async (res) => {
            await message.success('Custom Script created successfully');
        })
    }

    const handleUpdateCss = () => {
        updateCustomCss({
            details: customCss,
            currentHistoryId: ""
        }).then(async (res) => {
            await message.success('Custom Css created successfully');
        })
    }

    const deleteLanguage = (id) => {
        deleteLanguages({
            documentID: id,
        }).then((res) => {
            dispatchLanguages();
            message.success('Languages deleted successfully');
        });
    }

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response.list)
        });
    };

    const dispatchCssAndScript = async () => {
        getCustomCss().then(async (response) => {
            setCurrentCssHistoryID(response.list.length > 0 && response.list[0].currentHistoryId ? response.list[0].currentHistoryId : "")
            setCssHistoryIDs(response.list.length > 0 && response.list[0].historyID ? response.list[0].historyID : "")
            setCustomCss(response.list.length > 0 ? response.list[0].details : "")
            setLoadCssStatus(response.status)
        });
        getCustomScript().then(async (response) => {
            setCurrentJsHistoryID(response.list.length > 0 && response.list[0].currentHistoryId ? response.list[0].currentHistoryId : "")
            setJsHistoryIDs(response.list.length > 0 && response.list[0].historyID ? response.list[0].historyID : "")
            setCustomScript(response.list.length > 0 ? response.list[0].scripts : "")
            setLoadScriptStatus(response.status)
        });
    };

    const logoImage = [
        {
            uid: '-1',
            name: "Brand Logo",
            status: 'done',
            url: oldLogoUrl,
            thumbUrl: oldLogoUrl,
        }
    ];

    const faviconImage = [
        {
            uid: '-1',
            name: "Brand Favicon",
            status: 'done',
            url: oldFaviconUrl,
            thumbUrl: oldFaviconUrl,
        }
    ];

    const dispatchBrandSetting = () => {
        getBrandSetting().then(async (response) => {
            if (response.list.length > 0) {
                setBrandTitle(response.list[0].title)
                setOldLogoName(response.list[0].logoName)
                setOldLogoUrl(response.list[0].logoUrl)
                setOldFaviconName(response.list[0].faviconName)
                setOldFaviconUrl(response.list[0].faviconUrl)
            }
            setLoadStatus(response.status)
        });
    };

    const handleJavascriptCount = async () => {

        let jsTextarea = document.getElementsByClassName("jsTextarea");
        let ary = []
        let status = [];
        for (let i = 0; i < jsTextarea.length; i++) {
            let currentData = formRef.getFieldsValue(true);

            let jsLength = currentData.customScript[i].length
            ary.push({
                className: "jsTextarea-" + i,
                length: jsLength,
                status: jsLength > 10000 ? "error" : true,
                maxLength: 10000
            })
            if (jsLength > 10000) {
                status.push("error")
            }

        }
        if (status.length > 0) {
            setJavascriptCountStatus("error")
        } else {
            setJavascriptCountStatus("")
        }
        setJavascriptCount(ary)
        console.log(ary)
    }

    useEffect(() => {
        dispatchLanguages();
        dispatchCssAndScript();
        dispatchBrandSetting();
    }, []);

    const onChangeCssCommitMessage = (e) => {
        setCssCommitMessage(e.target.value)
    }

    const showCssModalCommit = async () => {
        setCssCommitMessage("")
        setIsCssCommitModalOpen(true)
    };

    const handleCancelCssModalCommit = () => {
        setIsCssCommitModalOpen(false)
        setCssCommitMessage("")
    };

    const handleSaveNewVersionCss = () => {

        saveAsNewVersionCustomCss({
            details: customCss
        }).then((history) => {
            let timeStamp = Math.round((new Date()).getTime() / 1000);
            let date = new Date(timeStamp * 1000)
            let lastModified = date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")

            let newVer = {
                timestamp: timeStamp,
                dateTime: lastModified,
                id: history._id,
                message: cssCommitMessage
            }

            updateCustomCss({
                details: customCss,
                historyID: newVer,
                currentHistoryId: history._id
            }).then(async (res) => {
                await handleCancelCssModalCommit()
                await message.success('Custom Css saved as new version');
                navigate(0)
            })

        })


    }

    const showCssModalVersion = async () => {
        setIsCssVersionModalOpen(true)
    };

    const handleCancelCssModalVersion = () => {
        setIsCssVersionModalOpen(false)
    };

    const handleCssVersionControlChanges = (e) => {
        setCssVersionID(e.target.value)
    }

    const handleCssVersionControl = () => {
        getCustomCssVersion({
            id: cssVersionID
        }).then((res) => {
            updateCustomCss({
                details: res.list.details,
                currentHistoryId: cssVersionID
            }).then(async (res) => {
                navigate(0)
                message.success('Custom Css Revert Successfully');
            })
        })
    }

    //JS
    const onChangeJsCommitMessage = (e) => {
        setJsCommitMessage(e.target.value)
    }

    const showJsModalCommit = async () => {
        setJsCommitMessage("")
        setIsJsCommitModalOpen(true)
    };

    const handleCancelJsModalCommit = () => {
        setIsJsCommitModalOpen(false)
        setJsCommitMessage("")
    };

    const handleSaveNewVersionJs = () => {

        let currentData = formRef.getFieldsValue(true);

        currentData.customScript && currentData.customScript.length > 0 && currentData.customScript.map((data, index) => {
            if (data === null || data === undefined) {
                currentData.customScript[index] = ""
            }
        })


        saveAsNewVersionCustomJs({
            scripts: currentData.customScript
        }).then((history) => {
            let timeStamp = Math.round((new Date()).getTime() / 1000);
            let date = new Date(timeStamp * 1000)
            let lastModified = date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")

            let newVer = {
                timestamp: timeStamp,
                dateTime: lastModified,
                id: history._id,
                message: jsCommitMessage
            }

            updateCustomScript({
                scripts: currentData.customScript,
                historyID: newVer,
                currentHistoryId: history._id
            }).then(async (res) => {
                await handleCancelJsModalCommit()
                await message.success('Custom Script created successfully');
                navigate(0)
            })

        })


    }

    const showJsModalVersion = async () => {
        setIsJsVersionModalOpen(true)
    };

    const handleCancelJsModalVersion = () => {
        setIsJsVersionModalOpen(false)
    };

    const handleJsVersionControlChanges = (e) => {
        setJsVersionID(e.target.value)
    }

    const handleJsVersionControl = () => {
        getCustomJsVersion({
            id: jsVersionID
        }).then((res) => {
            updateCustomScript({
                scripts: res.list.scripts,
                currentHistoryId: jsVersionID
            }).then(async (res) => {
                navigate(0)
                message.success('Custom Script Revert Successfully');
            })
        })
    }

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Tabs tabPosition={"left"}>
                        <Tabs.TabPane tab="Brand Settings" key="2" forceRender={true}>
                            {loadStatus && (
                                <Form
                                    name="brandSetting"
                                    autoComplete="off"
                                    form={formBrandSetting}
                                    initialValues={{brandName: brandTitle}}
                                    onFinish={onFinishBrandSetting}
                                >
                                    <Row className="mb-3">
                                        <Col span={12}>
                                            <h5>Brand Setting</h5>
                                        </Col>
                                        <Col span={12} className="text-end">
                                            <Button type="primary" shape="round" htmlType="submit">
                                                Save Changes
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]} className="mt-4">
                                        <Col span={4}>
                                            Brand Name :
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                name={"brandName"}
                                                rules={[{required: true, message: "Title is required"}]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]}>
                                        <Col span={4}>
                                            Logo :
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item name={"logo"} rules={oldLogoUrl === "" ? [{
                                                required: true,
                                                message: "Brand logo is required"
                                            }] : false}>
                                                <Upload
                                                    listType="picture"
                                                    maxCount={1}

                                                    defaultFileList={oldLogoUrl !== "" ? [...logoImage] : false}
                                                >
                                                    <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]}>
                                        <Col span={4}>
                                            Favicon :
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item name={"favicon"} rules={oldFaviconUrl === "" ? [{
                                                required: true,
                                                message: "Brand Favicon is required"
                                            }] : false}>
                                                <Upload
                                                    listType="picture"
                                                    maxCount={1}

                                                    defaultFileList={oldFaviconUrl !== "" ? [...faviconImage] : false}
                                                >
                                                    <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            )}


                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Language Settings" key="1" forceRender={true}>
                            <Row className="mb-3">
                                <Col span={12}>
                                    <h5>Languages Setting</h5>
                                </Col>
                                <Col span={12} className="text-end">
                                    <Button type="primary" shape="round" onClick={showCreateLanguageModal}>
                                        Add New Languages
                                    </Button>
                                </Col>
                            </Row>
                            <Col>
                                <Table dataSource={languageList} columns={languageColumns}/>
                            </Col>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="RESTful APIs" key="3">

                            <Col>
                                <h5>RESTful APIs</h5>
                            </Col>
                            <Col>
                                <Form
                                    layout="vertical"
                                    autoComplete="off"
                                    initialValues={{apikey: 'e01a4b78-d5ca-404e-a63c-7e8c032eeb38'}}
                                >
                                    <Form.Item
                                        name="apikey"
                                        label="API key"

                                    >
                                        <Input placeholder="input placeholder" disabled/>
                                    </Form.Item>
                                </Form>
                            </Col>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Custom Css" key="4">
                            {
                                loadCssStatus === 200 && (
                                    <Form
                                        layout="vertical"
                                        autoComplete="off"
                                        initialValues={{customCss: customCss}}
                                    >
                                        <Row>
                                            <Col span={6}>
                                                <h5>Custom Css</h5>
                                            </Col>
                                            <Col span={18} className="text-end">
                                                <Button shape="round" onClick={showCssModalVersion} className={"me-3"}>
                                                    Revert Previous Version
                                                </Button>
                                                <Button shape="round" onClick={showCssModalCommit} className={"me-3"}>
                                                    Save as New Version
                                                </Button>
                                                <Button type="primary" shape="round" onClick={handleUpdateCss}>
                                                    Save Changes
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Col className="mt-3">
                                            <Form.Item name="customCss">
                                                <CodeMirror
                                                    value=""
                                                    height={window.screen.height >= 1000 ? "700px" : "600px"}
                                                    extensions={[css()]}
                                                    onChange={onChangeCustomCss}
                                                />
                                                {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 25:15} onKeyUp={onChangeCustomCss}/>*/}
                                            </Form.Item>
                                        </Col>
                                    </Form>
                                )
                            }

                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Custom Script" key="5" forceRender={true}>
                            {
                                loadScriptStatus === 200 && (
                                    <Form
                                        form={formRef}
                                        name="formScripts"
                                        layout="vertical"
                                        autoComplete="off"
                                        initialValues={{customScript: customScript}}
                                    >
                                        <Row>
                                            <Col span={6}>
                                                <h5>Custom Script</h5>
                                            </Col>
                                            <Col span={18} className="text-end">
                                                <Button shape="round" onClick={showJsModalVersion} className={"me-3"}>
                                                    Revert Previous Version
                                                </Button>
                                                <Button shape="round" onClick={showJsModalCommit} className={"me-3"}>
                                                    Save as New Version
                                                </Button>
                                                <Button type="primary" shape="round" onClick={handleUpdateScript}
                                                        disabled={javascriptCountStatus === "error"}>
                                                    Save Changes
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Col className="mt-3">
                                            <Form.List
                                                name="customScript"
                                            >
                                                {(fields, {add, remove}, {errors}) => (
                                                    <>
                                                        {fields.map((field, index) => {

                                                            let className = "jsTextarea-" + index;
                                                            let length = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).length;
                                                            let status = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).status;
                                                            let maxLength = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).maxLength;
                                                            return (
                                                                <Form.Item
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-11">
                                                                            <Form.Item
                                                                                {...field}
                                                                                style={{marginBottom: "7px"}}
                                                                            >
                                                                                <CodeMirror
                                                                                    className={"jsTextarea jsTextarea-" + index}
                                                                                    value=""
                                                                                    height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                                                    extensions={[javascript()]}
                                                                                    onKeyUp={handleJavascriptCount}
                                                                                />
                                                                            </Form.Item>
                                                                            {
                                                                                length > 0 && status !== "error" ? (
                                                                                    <Text
                                                                                        type="secondary">{length}/{maxLength} Characters</Text>
                                                                                ) : length > 0 ? (
                                                                                    <Text
                                                                                        type="danger">{length}/{maxLength} Characters</Text>
                                                                                ) : ""
                                                                            }
                                                                        </div>
                                                                        <div className="col-1">
                                                                            {fields.length > 1 ? (
                                                                                <MinusCircleOutlined
                                                                                    className="dynamic-delete-button"
                                                                                    onClick={() => remove(field.name)}
                                                                                />
                                                                            ) : null}
                                                                        </div>

                                                                    </div>
                                                                </Form.Item>
                                                            )
                                                        })}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                Add Javascript
                                                            </Button>

                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                            {/*<Form.Item name="customScript">*/}

                                            {/*    <CodeMirror*/}
                                            {/*        value=""*/}
                                            {/*        height="auto"*/}
                                            {/*        extensions={[javascript()]}*/}
                                            {/*        onChange={onChangeCustomScript}*/}
                                            {/*    />*/}
                                            {/*    /!*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 25:15} onKeyUp={onChangeCustomScript}/>*!/*/}
                                            {/*</Form.Item>*/}
                                        </Col>
                                    </Form>
                                )
                            }

                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>

            <Modal title="Create New Languages" visible={isCreateLanguageModalOpen} onOk={handleCreateLanguageModal}
                   destroyOnClose={true} okText={"Save Changes"}
                   onCancel={handleCancelLanguageModal}>
                <Form
                    form={formLanguagesCreate}
                    name="languagesCreate"
                    autoComplete="off"
                >
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            Language:
                        </Col>
                        <Col span={18}>
                            <Form.Item name="code">
                                <Select onChange={onChangeLanguageCode} showSearch>
                                    {
                                        languages.map((lang, i) => {
                                            return (
                                                <Select.Option key={i} value={lang.code}>{lang.name}</Select.Option>
                                            )

                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal title="Edit New Languages" visible={isEditLanguageModalOpen} onOk={handleEditLanguageModal}
                   okText={"Save Changes"}
                   destroyOnClose={true}
                   onCancel={handleCancelEditLanguageModal}>
                <Form
                    form={formLanguagesEdit}
                    name="languagesEdit"
                    autoComplete="off"
                    initialValues={{code: languageCodeEdit}}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            Language:
                        </Col>
                        <Col span={18}>
                            <Form.Item name="code">
                                <Select onChange={onChangeEditLanguageCode} showSearch>
                                    {
                                        languages.map((lang, i) => {
                                            return (
                                                <Select.Option key={i} value={lang.code}>{lang.name}</Select.Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            Is Active:
                        </Col>
                        <Col span={18}>
                            <Form.Item valuePropName="checked">
                                <Switch defaultChecked={languageActiveEdit} onChange={onChangeEditLanguageActive}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal title="Version Control" visible={isCssVersionModalOpen} onOk={handleCssVersionControl}
                   okText={"Revert"} destroyOnClose={true}
                   onCancel={handleCancelCssModalVersion}>
                <div>
                    <Radio.Group className="w-100" onChange={handleCssVersionControlChanges}>
                        {
                            cssHistoryIDs.length > 0 && cssHistoryIDs.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1).map((item, i) => {
                                if(item?.id){
                                    return(
                                        <Radio.Button
                                            className={currentCssHistoryID === item?.id ? "w-100 version-control-active" : "w-100"}
                                            value={item.id}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <Tooltip placement="right" title={item.message}>

                                                        {item.message ? truncateString(item.message, 20) : "No Message"}
                                                    </Tooltip>

                                                </div>
                                                <div className="col-6 text-end">
                                                    {item.dateTime}
                                                </div>
                                            </div>
                                        </Radio.Button>
                                    )
                                }
                            })
                        }
                    </Radio.Group>
                </div>
            </Modal>

            <Modal title="Version Control" visible={isJsVersionModalOpen} onOk={handleJsVersionControl}
                   okText={"Revert"} destroyOnClose={true}
                   onCancel={handleCancelJsModalVersion}>
                <div>
                    <Radio.Group className="w-100" onChange={handleJsVersionControlChanges}>
                        {
                            jsHistoryIDs.length > 0 && jsHistoryIDs.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1).map((item, i) => (
                                <Radio.Button
                                    className={currentJsHistoryID === item.id ? "w-100 version-control-active" : "w-100"}
                                    value={item.id}>
                                    <div className="row">
                                        <div className="col-6">
                                            <Tooltip placement="right" title={item.message}>

                                                {item.message ? truncateString(item.message, 20) : "No Message"}
                                            </Tooltip>

                                        </div>
                                        <div className="col-6 text-end">
                                            {item.dateTime}
                                        </div>
                                    </div>
                                </Radio.Button>
                            ))
                        }
                    </Radio.Group>
                </div>
            </Modal>

            <Modal title="Css Commit Changes" visible={isCssCommitModalOpen} onOk={handleSaveNewVersionCss}
                   okText={"Commit"} destroyOnClose={true}
                   onCancel={handleCancelCssModalCommit}>
                <div>
                    <Form>
                        <Row gutter={[16, 16]}>
                            <Col span={4}>
                                Message:
                            </Col>
                            <Col span={20}>
                                <Form.Item name={"commit"} initialValue={cssCommitMessage}>
                                    <TextArea onKeyUp={onChangeCssCommitMessage} rows={5}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </div>
            </Modal>

            <Modal title="Js Commit Changes" visible={isJsCommitModalOpen} onOk={handleSaveNewVersionJs}
                   okText={"Commit"} destroyOnClose={true}
                   onCancel={handleCancelJsModalCommit}>
                <div>
                    <Form>
                        <Row gutter={[16, 16]}>
                            <Col span={4}>
                                Message:
                            </Col>
                            <Col span={20}>
                                <Form.Item name={"commit"} initialValue={jsCommitMessage}>
                                    <TextArea onKeyUp={onChangeJsCommitMessage} rows={5}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </div>
            </Modal>
        </>
    );

};

