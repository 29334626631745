import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, message, Modal, Popconfirm, Row, Select, Space, Table} from 'antd';

import {useNavigate} from "react-router-dom";
import {
    addNewSections,
    deleteDraftSitePages,
    deleteSectionCategory,
    deleteSectionCategoryImage,
    deleteSections,
    deleteSitePages,
    deleteSitePagesHistory,
    deleteStaticImage,
    editSections,
    getSections,
    searchSectionBySlug,
    searchSectionCategoryBySection,
    searchSectionPostingBySection,
    searchSections
} from "../../service";
import {QuestionCircleOutlined} from "@ant-design/icons";
import Keys from "../../_config";
import {LoadingScreen} from "../../layout/errorMessage";

const {Option} = Select;
export default function SiteSection() {
    const [sectionList, setSectionList] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [titleState, setTitleState] = useState("");
    const [titleEditState, setTitleEditState] = useState("");
    const [slugState, setSlugState] = useState("");
    const [sectionIDState, setSectionIDState] = useState("");
    const [slugEditState, setSlugEditState] = useState("");
    const [oldSlugEditState, setOldSlugEditState] = useState("");
    const [slugInvalid, setSlugInvalid] = useState(false);
    const navigate = useNavigate();

    const handleOpenModal = () => {
        setModalVisible(true)
    }

    const handleOpenEditModal = (value) => {
        setModalEditVisible(true)
        searchSections({
            documentID: value
        }).then((res) => {
            setSectionIDState(res._id)
            setTitleEditState(res.title)
            setSlugEditState(res.slug)
            setOldSlugEditState(res.slug)
        })
    }

    const handleCreateSection = () => {

        if (titleState !== "" && slugState !== "" && slugInvalid === false) {
            addNewSections({
                title: titleState,
                slug: slugState,
                documentID: "",
            }).then((res) => {
                message.success('Section created successfully');
                setModalVisible(false)
                setSlugState("")
                // dispatchSections();
                navigate(0)
            })

        } else {
            message.error('Please full fill requirement below');
        }

    }

    const handleEditSection = () => {

        if (titleEditState !== "" && slugEditState !== "" && slugInvalid === false) {
            editSections({
                title: titleEditState,
                slug: slugEditState,
                documentID: sectionIDState,
            }).then((res) => {
                message.success('Section updated successfully');
                setModalEditVisible(false)
                setSectionIDState("")
                setSlugEditState("")
                setTitleEditState("")
                // dispatchSections();
                navigate(0)
            })
        } else {
            message.error('Please full fill requirement below');
        }

    }

    const handleCancelSection = () => {
        setModalVisible(false)
        setSlugState("")
        setSlugInvalid(false)
    }

    const handleCancelEditSection = () => {
        setModalEditVisible(false)
        setSlugInvalid(false)
        setSectionIDState("")
        setSlugEditState("")
        setTitleEditState("")
    }

    const onChangeTitle = (e) => {
        setTitleState(e.target.value)
    }

    const onChangeTitleEdit = (e) => {
        setTitleEditState(e.target.value)
    }

    const onChangeSlug = (e) => {
        let slug = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')

        setSlugState(slug)

        setSlugInvalid(false)
        searchSectionBySlug({
            slug: slug
        }).then((res) => {
            if (res._id) {
                setSlugInvalid(true)
            }
        })
    }

    const onChangeSlugEdit = (e) => {
        let slug = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')

        setSlugEditState(slug)
        setSlugInvalid(false)
        if (slug !== oldSlugEditState) {
            searchSectionBySlug({
                slug: slug
            }).then((res) => {
                if (res._id) {
                    setSlugInvalid(true)
                }
            })
        }

    }

    const dispatchSections = () => {
        getSections().then(async (response) => {
            setSectionList(response)
        });
    };

    const deleteSection = (id) => {

        searchSectionCategoryBySection({
            documentID: id
        }).then(async (response) => {
            if (response.length > 0) {
                response.map((item, i) => {
                    deleteSectionCategory({
                        documentID: item._id
                    }).then((res) => {
                        deleteSectionCategoryImage({
                            ImageName: item.ImageName
                        })
                    })
                })
            }
        });

        searchSectionPostingBySection({
            documentID: id
        }).then(async (response) => {
            if (response.length > 0) {
                response.map((item, i) => {
                    if (item.historyIDs.length > 0) {
                        item.historyIDs.map((res, i) => {
                            deleteSitePagesHistory({
                                documentID: res.id
                            })
                        })
                    }

                    deleteDraftSitePages({
                        documentID: item._id
                    })

                    deleteSitePages({
                        documentID: item._id,
                    }).then((res) => {
                        if (item.featureImage !== undefined) {
                            deleteStaticImage({
                                fileName: /[^/]*$/.exec(item.featureImage)[0]
                            })
                        }
                        if (item.backgroundImage !== undefined) {
                            deleteStaticImage({
                                fileName: /[^/]*$/.exec(item.backgroundImage)[0]
                            })
                        }
                        console.log(item._id)


                        item.bannerImage.map((items, i) => {
                            if (items.bannerPath !== undefined) {
                                deleteStaticImage({
                                    fileName: /[^/]*$/.exec(items.bannerPath)[0]
                                })
                            }

                        })
                    })
                })
            }
        });

        deleteSections({
            documentID: id
        }).then((res) => {
            message.success('Section deleted successfully');
            navigate(0)
        })
    }

    const columns = [
        {
            title: 'Title',
            key: 'title',
            render: (_, record) => (
                <Space size="middle">
                    {record.title}
                </Space>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                const copyGetSectionCategory = async () => {
                    await navigator.clipboard.writeText(Keys.SERVER_URL + '/api/section/getSectionCategory?name='+ record.slug +'&lang=en');
                    message.success('API URL copied');
                }
                const copyGetSectionPosting = async () => {
                    await navigator.clipboard.writeText(Keys.SERVER_URL + '/api/section/getSectionPosting?name='+ record.slug +'&lang=en');
                    message.success('API URL copied');
                }
                return (

                    <Space size="middle">
                        <Button key={record.key} onClick={copyGetSectionCategory}>Copy Category API</Button>
                        <Button key={record.key} onClick={copyGetSectionPosting}>Copy Article API</Button>
                        <Button type="primary" key={record.key}
                                onClick={() => handleOpenEditModal(record._id)}>Edit</Button>
                        <Popconfirm
                            title="Are you sure ?"
                            onConfirm={() => deleteSection(record._id)}
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                        >
                            <Button type="danger">Delete</Button>
                        </Popconfirm>
                    </Space>

                )
            },
        },
    ];

    useEffect(() => {
        dispatchSections();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {
                        sectionList !== false ? (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <h3>Site Section</h3>
                                    </Col>
                                    <Col span={12} className="text-end">
                                        <Button type="primary" shape="round" onClick={handleOpenModal}>
                                            Add New Site Section
                                        </Button>
                                    </Col>
                                </Row>

                                <Col className="mt-3">
                                    <Table dataSource={sectionList} columns={columns}/>
                                </Col>
                            </>

                        ) : (
                            <LoadingScreen/>
                        )
                    }


                </div>
            </div>

            <Modal title="Add Site Section" visible={modalVisible} onOk={handleCreateSection} okText={"Save Changes"}
                   width={1000}
                   onCancel={handleCancelSection} destroyOnClose={true}>
                <Form
                    name="mediaCreate"
                    autoComplete="off"
                >
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            Title:
                        </Col>
                        <Col span={18}>
                            <Form.Item name="title" rules={[{required: true, message: "Title is required"}]}>
                                <Input onChange={onChangeTitle}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            Param:
                        </Col>
                        <Col span={18}>
                            <Form.Item name="slug" rules={[{required: true, message: "Title is required"}]}
                                       help={slugInvalid === false ? "" : "Invalid Param"}
                                       validateStatus={slugInvalid === true ? "error" : ""}>
                                <Input onChange={onChangeSlug}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col className="mt-4">
                        <Card title="Section API Example">
                            <Col>
                                <b>Category API:</b> {Keys.SERVER_URL}/api/section/getSectionCategory?name={slugState}
                            </Col>
                            <Col>
                                <b>Posting API:</b> {Keys.SERVER_URL}/api/section/getSectionPosting?name={slugState}
                            </Col>

                        </Card>
                    </Col>
                </Form>
            </Modal>

            <Modal title="Edit Site Section" visible={modalEditVisible} onOk={handleEditSection} okText={"Save Changes"}
                   width={1000}
                   onCancel={handleCancelEditSection} destroyOnClose={true}>
                <Form
                    name="mediaEdit"
                    autoComplete="off"
                >
                    {sectionIDState && sectionIDState ?
                        <>
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    Title:
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="title" initialValue={titleEditState}
                                               rules={[{required: true, message: "Title is required"}]}>
                                        <Input onChange={onChangeTitleEdit}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    Param:
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="slug" initialValue={slugEditState}
                                               rules={[{required: true, message: "Title is required"}]}
                                               help={slugInvalid === false ? "" : "Invalid Param"}
                                               validateStatus={slugInvalid === true ? "error" : ""}>
                                        <Input onChange={onChangeSlugEdit}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                        : ""
                    }


                    <Col className="mt-4">
                        <Card title="Section API Example">
                            <Col>
                                <b>Category API:</b> {Keys.SERVER_URL}/api/section/getSectionCategory?name={slugEditState}
                            </Col>
                            <Col>
                                <b>Posting API:</b> {Keys.SERVER_URL}/api/section/getSectionPosting?name={slugEditState}
                            </Col>

                        </Card>
                    </Col>
                </Form>
            </Modal>
        </>
    );
};

