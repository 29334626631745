import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Switch,
    Tabs,
    Tooltip,
    Typography
} from 'antd';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    addNewDraftSitePages,
    addNewUrl,
    addSitePagesHistory,
    deleteSitePagesHistory,
    editSitePages,
    getLanguages,
    getSidebars,
    getSitePagesHistory,
    pageChecker,
    removeUrl,
    searchDraftSitePages,
    searchPageID,
    searchSitePages,
    searchUrl,
    takeControlPageChecker,
    updatePageChecker
} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import {MinusCircleOutlined} from "@ant-design/icons";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {html} from "@codemirror/lang-html";

function truncateString(str, num) {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}

export default function EditSitePages() {

    const [languageList, setLanguageList] = useState(false);
    const [siteDetails, setSiteDetails] = useState("");
    const [friendlyURL, setFriendlyURL] = useState(false);
    const [currentFriendlyURL, setCurrentFriendlyURL] = useState(false);
    const [friendlyURLInvalid, setFriendlyURLInvalid] = useState(false);
    const [pageIDs, setPageID] = useState("");
    const [currentPageID, setCurrentPageID] = useState("");
    const [historyIDs, setHistoryIDs] = useState([]);
    const [versionID, setVersionID] = useState("");
    const [commitMessage, setCommitMessage] = useState("No Message");
    const [saveData, setSaveData] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommitModalOpen, setIsCommitModalOpen] = useState(false);
    const [pageIDInvalid, setPageIDInvalid] = useState(false);
    const [sidebarList, setSidebarList] = useState(false);
    const [pageActive, setPageActive] = useState();
    const [draftID, setDraftID] = useState("");
    const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [javascriptCount, setJavascriptCount] = useState([]);
    const [javascriptCountStatus, setJavascriptCountStatus] = useState("");
    const [pageActiveUUID, setPageActiveUUID] = useState(false);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)

    const location = useLocation();
    const navigate = useNavigate();
    const {TextArea} = Input;
    const {Option} = Select;
    const [formRef] = Form.useForm();
    const {Text, Link} = Typography;
    let {infoID} = useParams();

    const pushLanguagesData = async (item, values) => {

        let custom = {}
        let title = {}
        let metaDescription = {}
        let metaKeywords = {}


        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        //title
        var titleClass = "title-" + item.code
        var titleDetails = {
            language: item.code,
            title: values[titleClass],
        }
        title = titleDetails

        //Meta Description
        var descriptionClass = "seoDescription-" + item.code
        var descriptionDetails = {
            language: item.code,
            description: values[descriptionClass],
        }
        metaDescription = descriptionDetails;

        var keywordsClass = "seoKeyword-" + item.code
        var keywordsDetails = {
            language: item.code,
            keywords: values[keywordsClass],
        }
        metaKeywords = keywordsDetails;

        return {custom, metaDescription, metaKeywords, title}
    }

    const onFinishCommit = async () => {
        let values = saveData

        if (Array.isArray(values?.scripts) && values?.scripts.length > 0) {
            values.scripts = await values?.scripts.filter(value => value !== null && value !== "" && value !== undefined);
        }

        let CustomDetails = [];
        let MetaDescription = [];
        let MetaKeywords = [];
        let titles = [];
        let historyIds = [];


        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
                MetaDescription.push(v.metaDescription)
                MetaKeywords.push(v.metaKeywords)
                titles.push(v.title)
            })

        })

        addSitePagesHistory({
            title: titles,
            sidebarID: values?.sidebarID,
            pageID: pageIDs,
            details: CustomDetails,
            script: values?.script,
            scripts: values?.scripts,
            metaDescription: MetaDescription,
            metaKeywords: MetaKeywords,
        }).then(async (history) => {
            historyIds = historyIDs;
            let timeStamp = Math.round((new Date()).getTime() / 1000);
            let date = new Date(timeStamp * 1000)
            let lastModified = date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")

            await historyIds.push({
                timestamp: timeStamp,
                dateTime: lastModified,
                id: history._id,
                message: commitMessage
            })
            if (historyIds.length > 10) {
                let dateAry = [];
                historyIds.map((history, i) => {
                    dateAry.push(history.timestamp)
                })
                const oldest = dateAry.reduce((c, n) =>
                    n < c ? n : c
                );
                let deleteObj = historyIds.find(
                    (item) => item.timestamp === oldest
                )

                if (deleteObj) {
                    deleteSitePagesHistory({
                        documentID: deleteObj.id
                    }).then((res) => {
                        historyIds.splice(historyIds.indexOf(deleteObj), 1)
                        editSitePages(
                            {
                                title: titles,
                                sidebarID: values?.sidebarID,
                                dynamicPath: "/" + friendlyURL,
                                pageID: pageIDs,
                                details: CustomDetails,
                                script: values?.script,
                                scripts: values?.scripts,
                                metaDescription: MetaDescription,
                                metaKeywords: MetaKeywords,
                                active: pageActive,
                                documentID: infoID,
                                historyIDs: historyIds,
                                historyID: history._id,
                                draftStatus: "inactive",
                                lastModified: lastModified
                            }
                        ).then(async (res) => {

                            if (values?.seoUrl !== currentFriendlyURL) {
                                removeUrl({
                                    path: "/" + currentFriendlyURL
                                }).then((res) => {
                                    addNewUrl({
                                        path: "/" + values?.seoUrl,
                                        type: "Site Pages",
                                        referralID: infoID,
                                    })
                                })

                            }

                            await setSiteDetails(false)
                            setIsCommitModalOpen(false)
                            dispatchSitePageDetails()
                            message.success('Site page updated successfully');
                        })
                    })
                }

            } else {
                editSitePages(
                    {
                        title: titles,
                        sidebarID: values?.sidebarID,
                        dynamicPath: "/" + friendlyURL,
                        pageID: pageIDs,
                        details: CustomDetails,
                        script: values?.script,
                        scripts: values?.scripts,
                        metaDescription: MetaDescription,
                        metaKeywords: MetaKeywords,
                        active: pageActive,
                        documentID: infoID,
                        historyIDs: historyIds,
                        historyID: history._id,
                        draftStatus: "inactive",
                        lastModified: lastModified
                    }
                ).then(async (res) => {

                    if (values?.seoUrl !== currentFriendlyURL) {
                        removeUrl({
                            path: "/" + currentFriendlyURL
                        }).then((res) => {
                            addNewUrl({
                                path: "/" + values?.seoUrl,
                                type: "Site Pages",
                                referralID: infoID,
                            })
                        })

                    }

                    await setSiteDetails(false)
                    setIsCommitModalOpen(false)
                    dispatchSitePageDetails()
                    message.success('Site page updated successfully');
                })
            }
        })


    };

    const onFinishSave = async (values) => {
        await setSaveBtnLoading(true)
        let CustomDetails = [];
        let MetaDescription = [];
        let MetaKeywords = [];
        let titles = [];

        if (Array.isArray(values?.scripts) && values?.scripts.length > 0) {
            values.scripts = await values.scripts.filter(value => value !== null && value !== "" && value !== undefined);
        }

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
                MetaDescription.push(v.metaDescription)
                MetaKeywords.push(v.metaKeywords)
                titles.push(v.title)
            })

        })

        let timeStamp = Math.round((new Date()).getTime() / 1000);
        let date = new Date(timeStamp * 1000)
        let lastModified = date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US")

        editSitePages(
            {
                title: titles,
                sidebarID: values.sidebarID,
                dynamicPath: "/" + friendlyURL,
                pageID: pageIDs,
                details: CustomDetails,
                script: values.script,
                scripts: values.scripts,
                metaDescription: MetaDescription,
                metaKeywords: MetaKeywords,
                active: pageActive,
                documentID: infoID,
                draftStatus: "inactive",
                lastModified: lastModified
            }
        ).then((res) => {

            if (values.seoUrl !== currentFriendlyURL) {
                removeUrl({
                    path: "/" + currentFriendlyURL
                }).then((res) => {
                    addNewUrl({
                        path: "/" + values.seoUrl,
                        type: "Site Pages",
                        referralID: infoID,
                    })
                })

            }

            // navigate(-1)
            dispatchSitePageDetails()
            message.success('Site page updated successfully');
            setSaveBtnLoading(false)
        })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const dispatchSitePageDetails = () => {

        searchSitePages({
            documentID: infoID
        }).then(async (response) => {
            console.log(response)
            await formRef.setFieldsValue({
                pageID: response?.pageID,
                sidebarID: response?.sidebarID,
                scripts: response?.scripts?.filter(value => value !== null && value !== "" && value !== undefined),
                seoUrl: response?.dynamicPath?.substring(1)
            });
            setSiteDetails(response)
            setFriendlyURL(response?.dynamicPath?.substring(1))
            setCurrentFriendlyURL(response?.dynamicPath?.substring(1))
            setPageID(response?.pageID)
            setCurrentPageID(response?.pageID)
            setPageActive(response.active)
            setHistoryIDs(response.historyIDs !== undefined && response.historyIDs)
            setDraftID(response.draftID !== undefined && response.draftID)
            if (response.draftStatus !== undefined) {
                if (response.draftStatus === "inactive") {

                    setIsReady(true)
                } else {
                    setIsDraftModalOpen(true)

                    setIsReady(false)
                }
            } else {

                setIsReady(true)
            }
            if (response.draftID === undefined || response.draftID === "") {
                addNewDraftSitePages({

                    sitePageID: infoID,

                }).then((res) => {
                    console.log("res" + res)
                    setDraftID(res._id)
                    editSitePages({
                        documentID: infoID,
                        draftID: res._id
                    })
                })
            }

        });
    };

    const dispatchSidebars = () => {
        getSidebars().then(async (response) => {
            setSidebarList(response)
        });
    };

    const handleFriendlyUrl = (e) => {
        handleDraftSaving()
        let friendlyURL = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')
        if (currentFriendlyURL !== friendlyURL) {
            setFriendlyURL(friendlyURL)
            searchUrl({
                path: "/" + friendlyURL
            }).then((res) => {
                if (res === null) {
                    setFriendlyURLInvalid(false)
                } else {
                    setFriendlyURLInvalid(true)
                }
            })
        } else {
            setFriendlyURLInvalid(false)
            setFriendlyURL(friendlyURL)
        }
    }

    const handlePageID = (e) => {
        handleDraftSaving()

        if (currentPageID !== e.target.value) {
            let pageID = e.target.value.toLowerCase()
                .replace(/ /g, '-').replace(/[-]+/g, '-')
                .replace(/[^\w-]+/g, '');

            searchPageID({pageID: pageID}).then((res) => {
                if (res !== null) {
                    setPageIDInvalid(true)
                } else {
                    setPageIDInvalid(false)
                }
            })

            setPageID(pageID)
        } else {
            setPageIDInvalid(false)
            setPageID(e.target.value)
        }
    }

    const onChangePageActive = (e) => {
        handleDraftSaving()
        if (pageActive === true) {
            setPageActive(false)
        } else {
            setPageActive(true)
        }
    }

    const onChangeCommitMessage = (e) => {
        setCommitMessage(e.target.value)
    }

    const versionControlChanges = (e) => {
        setVersionID(e.target.value)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        getSitePagesHistory({
            documentID: versionID
        }).then((res) => {
            editSitePages(
                {
                    title: res.title,
                    sidebarID: res.sidebarID,
                    scripts: res.scripts,
                    details: res.details,
                    metaDescription: res.metaDescription,
                    metaKeywords: res.metaKeywords,
                    documentID: infoID,
                    historyID: versionID,
                }
            ).then((res) => {
                navigate(0)
                message.success('Data restored successfully');
                setIsModalOpen(false);
            })
        })


    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalCommit = async () => {
        setSaveData(formRef.getFieldsValue(true))
        setIsCommitModalOpen(true)
    };

    const handleCancelModalCommit = () => {
        setIsCommitModalOpen(false)
    };

    let draftStatus = 200;

    const comparePreviousData = (item, prev, current) => {
        let status = {}

        if (JSON.stringify(prev[item]) !== JSON.stringify(current[item])) {
            status = {
                notSame: true
            }
        }

        return status
    };

    const handleDraftSaving = async () => {
        return false
    }

    // const handleDraftSaving = async () => {
    //
    //     handlePageChecker()
    //
    //     let currentData = formRef.getFieldsValue(true);
    //     let previousData = siteDetails;
    //
    //     delete previousData['bannerImage'];
    //     delete previousData['categoryID'];
    //     delete previousData['defaultPath'];
    //     delete previousData['historyID'];
    //     delete previousData['historyIDs'];
    //     delete previousData['isDefault'];
    //     delete previousData['__v'];
    //     delete previousData['_id'];
    //     delete previousData['type'];
    //
    //     let CustomDetails = [];
    //     let MetaDescription = [];
    //     let MetaKeywords = [];
    //     let titles = [];
    //
    //     if (draftID !== "" && currentData["title-en"] !== undefined) {
    //
    //         await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, currentData))).then((res) => {
    //
    //             res.map((v, i) => {
    //                 CustomDetails.push(v.custom)
    //                 MetaDescription.push(v.metaDescription)
    //                 MetaKeywords.push(v.metaKeywords)
    //                 titles.push(v.title)
    //             })
    //
    //         })
    //
    //         let currentDataMassage = {
    //             active: pageActive,
    //             dynamicPath: "/" + currentData.seoUrl,
    //             details: CustomDetails,
    //             metaDescription: MetaDescription,
    //             metaKeywords: MetaKeywords,
    //             pageID: currentData.pageID,
    //             scripts: currentData.scripts,
    //             title: titles,
    //             sidebarID: currentData.sidebarID,
    //         }
    //
    //         let checkingValue = ["active", "dynamicPath", "details", "metaDescription", "metaKeywords", "pageID", "scripts", "title"]
    //
    //         let result = [];
    //         Promise.all(checkingValue.map((item, i) => comparePreviousData(item, previousData, currentDataMassage))).then((res) => {
    //             res.map((v, i) => {
    //                 if (Object.keys(v).length !== 0) {
    //                     result.push(v)
    //                 }
    //             })
    //         })
    //
    //
    //         updateDraftSitePages({
    //             documentID: draftID,
    //             title: currentDataMassage.title,
    //             sidebarID: currentDataMassage.sidebarID,
    //             sitePageID: infoID,
    //             pageID: currentDataMassage.pageID,
    //             dynamicPath: currentDataMassage.dynamicPath,
    //             details: currentDataMassage.details,
    //             script: currentDataMassage.script,
    //             scripts: currentDataMassage.scripts,
    //             metaDescription: currentDataMassage.metaDescription,
    //             metaKeywords: currentDataMassage.metaKeywords,
    //             categoryID: "",
    //             featureImage: "",
    //             backgroundImage: "",
    //             bannerImage: "",
    //             active: currentDataMassage.active,
    //         }).then((res) => {
    //             editSitePages({
    //                 documentID: infoID,
    //                 draftStatus: result.length > 0 ? "active" : "inactive"
    //             })
    //         })
    //
    //     }
    //
    // }

    const restoreDraft = async () => {
        searchDraftSitePages({
            documentID: draftID
        }).then(async (res) => {
            let draftObj = res;
            draftObj['bannerImage'] = siteDetails['bannerImage']
            draftObj['categoryID'] = siteDetails['categoryID']
            draftObj['defaultPath'] = siteDetails['defaultPath']
            draftObj['historyID'] = siteDetails['historyID']
            draftObj['historyIDs'] = siteDetails['historyIDs']
            draftObj['isDefault'] = siteDetails['isDefault']
            draftObj['_id'] = siteDetails['_id']
            draftObj['type'] = siteDetails['type']
            draftObj['lastModified'] = siteDetails['lastModified']

            setCurrentFriendlyURL(siteDetails.dynamicPath.substring(1))
            setCurrentPageID(siteDetails.pageID)
            formRef.setFieldsValue({
                pageID: res.pageID,
                sidebarID: res.sidebarID,
                scripts: res.scripts && res.scripts,
                seoUrl: res.dynamicPath.substring(1)
            });
            setPageID(res.pageID)
            setFriendlyURL(res.dynamicPath.substring(1))
            setPageActive(res.active)
            await setSiteDetails(draftObj)
            setIsDraftModalOpen(false)
            setIsReady(true)
        })
    }

    const cancelDraft = async () => {
        editSitePages({
            documentID: infoID,
            draftStatus: "inactive"
        }).then((res) => {
            setIsDraftModalOpen(false)
            setIsReady(true)
        })

    }

    const handleJavascriptCount = async () => {
        handleDraftSaving()
        let jsTextarea = document.getElementsByClassName("jsTextarea");
        let ary = []
        let status = [];
        for (let i = 0; i < jsTextarea.length; i++) {

            let currentData = formRef.getFieldsValue(true);
            let jsLength = currentData.scripts[i].length

            ary.push({
                className: "jsTextarea-" + i,
                length: jsLength,
                status: jsLength > 10000 ? "error" : true,
                maxLength: 10000
            })
            if (jsLength > 10000) {
                status.push("error")
            }

        }
        if (status.length > 0) {
            setJavascriptCountStatus("error")
        } else {
            setJavascriptCountStatus("")
        }
        setJavascriptCount(ary)
        console.log(ary)
    }

    const pageNotAvailable = () => {
        setPageActiveUUID(false)
        navigate(-1)
    }

    const handleTakeControl = () => {
        let uuids = localStorage.getItem("uuid")
        takeControlPageChecker({
            ip: uuids,
            currentPage: location.pathname
        }).then((res) => {
            updatePageChecker({
                currentPage: res,
                isActive: true,
                ip: uuids
            }).then((r) => {
                setPageActiveUUID(false)
            })

        })
    }

    const handlePageChecker = () => {
        let uuids = localStorage.getItem("uuid")
        pageChecker({
            currentPage: location.pathname,
            ip: uuids
        }).then((res) => {
            if (res.status === "available") {
                setPageActiveUUID(false)
                updatePageChecker({
                    currentPage: location.pathname,
                    isActive: true,
                    ip: uuids
                })
            } else {
                setPageActiveUUID(true)
            }
        })
    }


    useEffect(() => {
        dispatchLanguages();
        dispatchSitePageDetails();
        dispatchSidebars();
    }, []);


    return (
        <>

            <div className="sitepages">
                <div className="site-layout-background">
                    {
                        isReady === true ? (
                            <Form
                                name="siteCreate"
                                onFinish={onFinishSave}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                form={formRef}
                            >
                                <Row>
                                    <Col span={8}>
                                        <h3>Edit Site Pages</h3>
                                    </Col>
                                    <Col span={16} className="text-end">

                                        <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                            Back
                                        </Button>
                                        {
                                            historyIDs.length > 0 && (
                                                <Button shape="round" className="ms-2" onClick={showModal}>
                                                    Revert Previous Version
                                                </Button>
                                            )
                                        }

                                        <Button className="ms-2" shape="round" onClick={showModalCommit}
                                                disabled={friendlyURLInvalid === true || pageIDInvalid === true ? true : false || javascriptCountStatus === "error"}>
                                            Save as new version
                                        </Button>

                                        <Button type="primary" className="ms-2" shape="round" htmlType="submit"
                                                loading={saveBtnLoading}
                                                disabled={friendlyURLInvalid === true || pageIDInvalid === true ? true : false || javascriptCountStatus === "error"}>
                                            Save Changes
                                        </Button>

                                    </Col>

                                </Row>
                                <Col>
                                    Last saved as new
                                    version: <b>{historyIDs.length > 0 && historyIDs.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)[0].dateTime}</b><br/>
                                    Last saved
                                    changes: <b>{siteDetails.lastModified !== undefined ? siteDetails.lastModified : "-"}</b>
                                </Col>

                                {siteDetails && languageList.status === 200 && languageList.list.length > 0 ? (
                                    <Col>
                                        <Tabs>
                                            <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                                <Row gutter={[16, 16]}>
                                                    <Col span={12}>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                PageID:
                                                            </Col>
                                                            <Col>
                                                                <Form.Item
                                                                    name="pageID"
                                                                    validateStatus={pageIDInvalid === true ? "error" : ""}
                                                                    help={pageIDs !== "" ? pageIDInvalid === true ? "PageID is already in use" : "Your Page ID is " + pageIDs : ""}
                                                                >
                                                                    <Input onKeyUp={handlePageID}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Sidebar:
                                                            </Col>
                                                            <Col>
                                                                {sidebarList && sidebarList ? (
                                                                    <Form.Item name="sidebarID">
                                                                        <Select
                                                                            placeholder="Select an option"
                                                                            allowClear
                                                                            onChange={handleDraftSaving}
                                                                        >
                                                                            <Option value="0">No Sidebar</Option>
                                                                            {
                                                                                sidebarList && sidebarList.map((page, index) => (
                                                                                    <Option value={page._id}
                                                                                            key={index + 1}>{page.title}</Option>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                ) : ""}

                                                            </Col>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Friendly URL:
                                                            </Col>
                                                            <Col>
                                                                <Form.Item
                                                                    name="seoUrl"
                                                                    validateStatus={friendlyURLInvalid === true ? "error" : ""}
                                                                    help={friendlyURLInvalid === true ? "This URL is already in use" : ""}
                                                                >
                                                                    <Input onKeyUp={handleFriendlyUrl}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                Is Active:
                                                            </Col>
                                                            <Col>
                                                                <Form.Item valuePropName="checked">
                                                                    <Switch defaultChecked={pageActive}
                                                                            onChange={onChangePageActive}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </div>

                                                    </Col>
                                                    <Col span={12}>
                                                        <Card title="Friendly URL">
                                                            <p>{window.location.origin}/#/{friendlyURL}</p>
                                                        </Card>
                                                    </Col>

                                                    <Col span={24}>
                                                        <div className="mb-2">
                                                            <Col className="mb-2">
                                                                JavaScript:
                                                            </Col>
                                                            <Col>

                                                                <Form.List
                                                                    name="scripts"
                                                                >
                                                                    {(fields, {add, remove}, {errors}) => (
                                                                        <>
                                                                            {fields.map((field, index) => {

                                                                                let className = "jsTextarea-" + index;
                                                                                let length = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).length;
                                                                                let status = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).status;
                                                                                let maxLength = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).maxLength;
                                                                                return (
                                                                                    <Form.Item
                                                                                        required={false}
                                                                                        key={field.key}
                                                                                    >
                                                                                        <div className="row">
                                                                                            <div className="col-11">
                                                                                                <Form.Item
                                                                                                    {...field}
                                                                                                    style={{marginBottom: "7px"}}
                                                                                                >
                                                                                                    <CodeMirror
                                                                                                        className={"jsTextarea jsTextarea-" + index}
                                                                                                        value=""
                                                                                                        height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                                                                        extensions={[javascript()]}
                                                                                                        onKeyUp={handleJavascriptCount}
                                                                                                    />
                                                                                                </Form.Item>
                                                                                                {
                                                                                                    length > 0 && status !== "error" ? (
                                                                                                        <Text
                                                                                                            type="secondary">{length}/{maxLength} Characters</Text>
                                                                                                    ) : length > 0 ? (
                                                                                                        <Text
                                                                                                            type="danger">{length}/{maxLength} Characters</Text>
                                                                                                    ) : ""
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-1">
                                                                                                {fields.length > 1 ? (
                                                                                                    <MinusCircleOutlined
                                                                                                        className="dynamic-delete-button"
                                                                                                        onClick={() => remove(field.name)}
                                                                                                    />
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Form.Item>
                                                                                )
                                                                            })}
                                                                            <Form.Item>
                                                                                <Button
                                                                                    type="dashed"
                                                                                    onClick={() => add()}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                    }}
                                                                                >
                                                                                    Add Javascript
                                                                                </Button>

                                                                            </Form.Item>
                                                                        </>
                                                                    )}
                                                                </Form.List>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Tabs.TabPane>
                                            {siteDetails && languageList.list.map((lang, index) => (
                                                <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"}
                                                              key={"detail-" + index}
                                                              forceRender={true}>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={4}>
                                                            Title:
                                                        </Col>
                                                        <Col span={20}>
                                                            <Form.Item
                                                                name={"title-" + lang.code}
                                                                initialValue={siteDetails && siteDetails.title.find((res) => res.language === lang.code) !== undefined ? siteDetails.title.find((res) => res.language === lang.code).title : ""}
                                                                rules={[{
                                                                    required: true,
                                                                    message: 'Please input title-' + lang.code
                                                                }]}
                                                            >
                                                                <Input onKeyUp={handleDraftSaving}/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={4}>
                                                            Meta Descriptions:
                                                        </Col>
                                                        <Col span={20}>
                                                            <Form.Item name={"seoDescription-" + lang.code}
                                                                       initialValue={siteDetails && siteDetails.metaDescription.find((res) => res.language === lang.code) !== undefined ? siteDetails.metaDescription.find((res) => res.language === lang.code).description : ""}>
                                                                <TextArea rows={5} onKeyUp={handleDraftSaving}/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={4}>
                                                            Meta Keywords:
                                                        </Col>
                                                        <Col span={20}>
                                                            <Form.Item name={"seoKeyword-" + lang.code}
                                                                       initialValue={siteDetails && siteDetails.metaKeywords.find((res) => res.language === lang.code) !== undefined ? siteDetails.metaKeywords.find((res) => res.language === lang.code).keywords : ""}>
                                                                <TextArea rows={5} onKeyUp={handleDraftSaving}/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={4}>
                                                            Details:
                                                        </Col>
                                                        <Col span={20}>
                                                            <Form.Item name={"detail-" + lang.code}
                                                                       initialValue={siteDetails && siteDetails.details.find((res) => res.language === lang.code) !== undefined ? siteDetails.details.find((res) => res.language === lang.code).details : ""}>
                                                                {/*<TextArea spellCheck={false} onKeyUp={handleDraftSaving}*/}
                                                                {/*          rows={window.screen.height >= 1000 ? 20 : 10}/>*/}
                                                                <CodeMirror
                                                                    value=""
                                                                    height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                                    extensions={[html()]}
                                                                    onKeyUp={handleDraftSaving}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Tabs.TabPane>
                                            ))}
                                        </Tabs>
                                    </Col>
                                ) : (languageList.status === 200) ? (
                                    <Col className="mt-5">
                                        <EmptyLanguage/>
                                    </Col>
                                ) : ""
                                }
                            </Form>
                        ) : ""
                    }

                </div>
            </div>

            <Modal title="Version Control" visible={isModalOpen} onOk={handleOk} okText={"Revert"}
                   onCancel={handleCancel}>
                <div>
                    <Radio.Group className="w-100" onChange={versionControlChanges}>
                        {
                            historyIDs.length > 0 && historyIDs.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1).map((item, i) => (
                                <Radio.Button
                                    className={siteDetails.historyID === item.id ? "w-100 version-control-active" : "w-100"}
                                    value={item.id}>
                                    <div className="row">
                                        <div className="col-6">
                                            <Tooltip placement="right" title={item.message}>

                                                {item.message ? truncateString(item.message, 20) : "No Message"}
                                            </Tooltip>

                                        </div>
                                        <div className="col-6 text-end">
                                            {item.dateTime}
                                        </div>
                                    </div>
                                </Radio.Button>
                            ))
                        }
                    </Radio.Group>
                </div>
            </Modal>
            <Modal title="Commit Changes" visible={isCommitModalOpen} onOk={onFinishCommit} okText={"Commit"}
                   destroyOnClose={true}
                   onCancel={handleCancelModalCommit}>
                <div>
                    <Row gutter={[16, 16]}>
                        <Col span={4}>
                            Message:
                        </Col>
                        <Col span={20}>
                            <Form.Item name={"commit"}>
                                <TextArea onKeyUp={onChangeCommitMessage} rows={5}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Modal title="Draft recovery" visible={isDraftModalOpen} onOk={restoreDraft} onCancel={cancelDraft}>
                <div>
                    <Alert
                        message="Warning"
                        description="Do you want to restore unsaved draft?"
                        type="warning"
                        showIcon
                    />
                </div>
            </Modal>

            <Modal title="Warning" visible={pageActiveUUID}
                   okText={"Take Control"}
                   destroyOnClose={true}
                   onOk={handleTakeControl} onCancel={pageNotAvailable}
            >
                <div>
                    <Alert
                        message="Warning"
                        description="Someone is currently editing this page"
                        type="warning"
                        showIcon
                    />
                </div>
            </Modal>

        </>

    );


};
