import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Form, Input, message, Modal, Radio, Row, Select, Switch, Tabs, Tag, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Keys from "../../_config";
import {
    addNewSectionCategoryImage,
    deleteSectionCategoryImage,
    editSectionCategory,
    getLanguages,
    getSectionsCategory,
    pageChecker,
    searchSectionCategory,
    searchSectionCategoryBySlug,
    takeControlPageChecker,
    updatePageChecker,
    updateSectionCategoryMainCategory
} from "../../service";

export default function EditSectionCategory() {
    const [languageList, setLanguageList] = useState(false);
    const [sectionCategory, setSectionCategory] = useState(false);
    const [categoryType, setCategoryType] = useState(false);
    const [oriCategoryType, setOriCategoryType] = useState(false);
    const [fatherCategory, setFatherCategory] = useState("");
    const [mainSectionCategoryList, setMainSectionCategoryList] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [pageActiveUUID, setPageActiveUUID] = useState(false);

    const location = useLocation();
    let {infoID} = useParams();
    const navigate = useNavigate();
    const {TextArea} = Input;
    const {Option} = Select;
    const [slugState, setSlugState] = useState("");
    const [slugInvalid, setSlugInvalid] = useState(false);
    const [oldSlugState, setOldSlugState] = useState("");
    const [pageActive, setPageActive] = useState();

    const pushLanguagesData = async (item, values) => {
        let title = {}
        //title
        var titleClass = "title-" + item.code
        var titleDetails = {
            language: item.code,
            title: values[titleClass],
        }
        title = titleDetails

        return {title}
    }

    const onFinish = async (values) => {
        let titles = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {
            res.map((v, i) => {
                titles.push(v.title)
            })
        })


        if(categoryType === "sub" && (fatherCategory === "" || fatherCategory === "0")){
            message.error("Please choose a main category")

        }else{

            editSectionCategory({
                documentID: sectionCategory._id,
                title: titles,
                mainCategoryID: categoryType === "sub" ? fatherCategory : "0",
                type: categoryType,
                slug: values?.slug,
                active:pageActive,
            }).then((res) => {

                if(oriCategoryType === "main" && categoryType === "sub"){
                    updateSectionCategoryMainCategory({
                        documentID: sectionCategory._id,
                        newID: fatherCategory
                    })
                }

                if (values?.img !== undefined && values?.img.file.originFileObj) {
                    if (sectionCategory.ImageName !== "") {
                        deleteSectionCategoryImage({
                            ImageName: sectionCategory.ImageName
                        })
                    }
                    addNewSectionCategoryImage({
                        documentID: sectionCategory._id,
                        file: values?.img.file.originFileObj,
                    }).then((res) => {
                        dispatchSearchSectionCategory();
                        // dispatchMainSectionCategory();
                        message.success("Updated Successfully")
                    })

                } else {
                    dispatchSearchSectionCategory();
                    // dispatchMainSectionCategory();
                    message.success("Updated Successfully")
                }

            })
        }

    };

    // const dispatchMainSectionCategory = () => {
    //     getSectionsCategory({type: "main", mainCategoryID: "0",sectionID:sectionCategory.sectionID}).then(async (response) => {
    //         setMainSectionCategoryList(response)
    //     });
    // };

    const SectionImage = [
        {
            uid: '-1',
            name: sectionCategory.ImageName,
            status: 'done',
            url: Keys.SERVER_URL + sectionCategory.ImagePath,
            thumbUrl: Keys.SERVER_URL + sectionCategory.ImagePath,
        }
    ];


    const dispatchSearchSectionCategory = () => {
        searchSectionCategory({
            documentID: infoID
        }).then((res) => {
            setSlugState(res.slug)
            setOldSlugState(res.slug)
            setFatherCategory(res.mainCategoryID)
            setCategoryType(res.type)
            setOriCategoryType(res.type)
            setSectionCategory(res)
            setPageActive(res.active)

            getSectionsCategory({type: "main", mainCategoryID: "0",sectionID:res.sectionID}).then(async (response) => {
                setMainSectionCategoryList(response)
            });
        })
    }

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const onChangePageActive = (e) => {
        handleKeyUp()
        if (pageActive === true) {
            setPageActive(false)
        } else {
            setPageActive(true)
        }
    }

    const onChangeSlug = (e) => {
        handleKeyUp()
        let slug = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')
        setSlugState(slug)
        setSlugInvalid(false)
        if(slug !== oldSlugState){
            searchSectionCategoryBySlug({
                slug: slug
            }).then((res) => {
                if (res._id) {
                    setSlugInvalid(true)
                }
            })
        }

    }

    const onChangeType = (e) => {
        handleKeyUp()
        setCategoryType(e.target.value)
        if (e.target.value === "main") {
            setFatherCategory("0")
        }
    }

    const onChangeFatherCategory = (e) => {
        handleKeyUp()
        if (categoryType === "sub") {
            setFatherCategory(e)
        }
    }

    const pageNotAvailable = () => {
        setPageActiveUUID(false)
        navigate(-1)
    }

    const handleTakeControl = () => {
        let uuids = localStorage.getItem("uuid")
        takeControlPageChecker({
            ip: uuids,
            currentPage: location.pathname
        }).then((res) => {
            updatePageChecker({
                currentPage: res,
                isActive: true,
                ip: uuids
            }).then((r) => {
                setPageActiveUUID(false)
            })

        })
    }

    const handlePageChecker = () => {
        let uuids = localStorage.getItem("uuid")
        pageChecker({
            currentPage: location.pathname,
            ip: uuids
        }).then((res) => {
            if (res.status === "available") {
                setPageActiveUUID(false)
                updatePageChecker({
                    currentPage: location.pathname,
                    isActive: true,
                    ip: uuids
                })
            } else {
                setPageActiveUUID(true)
            }
        })
    }

    const handleKeyUp = () =>{
        handlePageChecker()
    }

    useEffect(() => {
        dispatchLanguages();
        dispatchSearchSectionCategory();
        // dispatchMainSectionCategory();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        name="siteCreate"
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col span={12}>
                                <h3>{"Edit Category"}</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit" disabled={slugInvalid === false ? false : true}>
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        <Col>
                            <Tabs>

                                {
                                    sectionCategory && (
                                        <Tabs.TabPane tab={"General"} key={"general"} forceRender={true}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={6}>
                                                    Type :
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item name={"type"} initialValue={sectionCategory.type}>
                                                        <Radio.Group onChange={onChangeType}>
                                                            <Radio.Button value="main">Main Category</Radio.Button>
                                                            <Radio.Button value="sub">Sub Category</Radio.Button>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {
                                                sectionCategory.type === "sub" || categoryType === "sub" ? (
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={6}>
                                                            Choose Main Category :
                                                        </Col>
                                                        <Col span={18}>
                                                            <Form.Item name="mainCategory"
                                                                       initialValue={sectionCategory.mainCategoryID !== "0" ? sectionCategory.mainCategoryID : ""}>
                                                                <Select
                                                                    placeholder="Select an option"
                                                                    allowClear
                                                                    onChange={onChangeFatherCategory}
                                                                >
                                                                    {
                                                                        mainSectionCategoryList && mainSectionCategoryList.map((list, index) => {
                                                                                if (sectionCategory._id !== list._id) {
                                                                                    return (
                                                                                        <Option value={list._id}
                                                                                                key={index + 1}>{list.title}</Option>
                                                                                    )
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                ) : ""
                                            }

                                            {languageList && languageList.list.map((lang, index) => (
                                                <Row gutter={[16, 16]}>
                                                    <Col span={6}>
                                                        Title <Tag color="blue" className="ms-2">{lang.title}</Tag> :
                                                    </Col>
                                                    <Col span={18}>
                                                        <Form.Item
                                                            name={"title-" + lang.code}
                                                            rules={[{required: true, message: "Title is required"}]}
                                                            initialValue={sectionCategory && sectionCategory.title.find((res) => res.language === lang.code) !== undefined ? sectionCategory.title.find((res) => res.language === lang.code).title : ""}
                                                        >
                                                            <Input onKeyUp={handleKeyUp}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            ))}

                                            <Row gutter={[16, 16]}>
                                                <Col span={6}>
                                                    Param :
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item
                                                        name={"slug"}
                                                        initialValue={sectionCategory.slug}
                                                        rules={[{required: true, message: "Slug is required"}]}
                                                        help={slugState !== "" && slugInvalid === false ? "Your param is " + slugState : "Invalid Param"}
                                                        validateStatus={slugInvalid === true?"error":""}
                                                    >
                                                        <Input onKeyUp={onChangeSlug}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={[16, 16]}>
                                                <Col span={6}>
                                                    Image :
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item name={"img"}>
                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                            defaultFileList={[...SectionImage]}
                                                        >
                                                            <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={[16, 16]}>
                                                <Col span={6}>
                                                    Is Active :
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item valuePropName="checked">
                                                        <Switch defaultChecked={pageActive}
                                                                onChange={onChangePageActive}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>



                                        </Tabs.TabPane>
                                    )
                                }


                            </Tabs>
                        </Col>


                    </Form>
                </div>
            </div>
            <Modal title="Warning" visible={pageActiveUUID}
                   okText={"Take Control"}
                   destroyOnClose={true}
                   onOk={handleTakeControl} onCancel={pageNotAvailable}
            >
                <div>
                    <Alert
                        message="Warning"
                        description="Someone is currently editing this page"
                        type="warning"
                        showIcon
                    />
                </div>
            </Modal>
        </>
    );


};

