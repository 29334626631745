import React, {useEffect, useLayoutEffect, useState} from "react";
import Keys from "../../_config";
import {
    getAllSitePages,
    getCdnLibrary,
    getCustomCss,
    getCustomScript,
    getDefaultFooters,
    getDefaultHeaders,
    getDefaultSitePages,
    getLanguages,
    getUrl,
    searchFooters,
    searchHeaders,
    searchSidebars,
    searchSitePagesWithFiltered,
    searchUrl
} from "../../service";
import {useLocation, useNavigate} from 'react-router-dom'
import {Helmet} from "react-helmet";
import disableScroll from 'disable-scroll';

export default function WebsiteClient() {
    const [defaultCss, setDefaultCss] = useState("");
    const [defaultCssReady, setDefaultCssReady] = useState(false);

    const [cdnScriptLists, setCdnScriptLists] = useState([]);
    const [cdnCssLists, setCdnCssLists] = useState([]);
    const [cdnReady, setCdnReady] = useState(false);

    const [pageID, setPageID] = useState("");
    const [bodyHtml, setBodyHtml] = useState("");
    const [bodyReady, setBodyReady] = useState(false);

    const [headerHtml, setHeaderHtml] = useState("");
    const [headerScript, setHeaderScript] = useState([]);
    const [headerLink, setHeaderLink] = useState([]);
    const [headerReady, setHeaderReady] = useState(false);

    const [footerHtml, setFooterHtml] = useState("");
    const [footerReady, setFooterReady] = useState(false);

    const [metaDescription, setMetaDescription] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [metaTitle, setMetaTitle] = useState(Keys.brandName);

    const [allSitePages, setAllSitePages] = useState([]);
    const [urls, setUrls] = useState([]);

    const [sidebarHtml, setSidebarHtml] = useState("");
    const [sidebarStatus, setSidebarStatus] = useState("");

    const [allReady, setAllReady] = useState(false);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    const parse = require('html-react-parser');
    const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    const filtering = ["header", "footer", "sidebar", "sites"]

    const dispatchCdnList = async () => {
        let script = [];
        let css = [];
        await getCdnLibrary().then(async (response) => {
            if (response.length > 0) {
                await Promise.all(response.map((item, i) => {

                    if (item.type === "script") {
                        let objScript = {}
                        objScript = {
                            "src": Keys.SERVER_URL + item.filePath,
                        }
                        script.push(objScript)
                    } else if (item.type === "css") {
                        let objCss = {}
                        objCss = {
                            "href": Keys.SERVER_URL + item.filePath,
                            "rel": "stylesheet"
                        }
                        css.push(objCss)
                    }
                }))
            }
        });

        await setCdnScriptLists(script)
        await setCdnCssLists(css)
        setCdnReady(true)

    }

    const dispatchCss = async () => {
        await getCustomCss().then(async (response) => {
            const cssDetails = response.list[0].minifiedCss && response.list[0].minifiedCss !== "" ? response.list[0].minifiedCss : response.list[0].details
            await setDefaultCss(cssDetails)
            setDefaultCssReady(true)
        });
    }

    const dispatchLanguage = () => {
        getLanguages().then((res) => {
            if (res.list.find((item) => item.code === localStorage.getItem("lang")) === undefined) {
                localStorage.setItem("lang", "en")
            }
        })
    }

    const dispatchDefaultScript = () => {

        getCustomScript().then(async (response) => {
            if (response.list) {
                response.list.length > 0 && response.list[0].scripts.map((value, i) => {
                    const elementID = document.getElementById("defaultScript-" + i)
                    if (!elementID) {
                        let result = value.replace("const ", "var ");
                        const scriptText = document.createTextNode(`${result}`);

                        const script = document.createElement("script");
                        script.setAttribute("id", "defaultScript-" + i)
                        script.setAttribute("class", "defaultScript")

                        script.appendChild(scriptText);
                        setTimeout(() => {
                            document.body.appendChild(script);
                        }, 1000)
                    }
                })
            }
        });

    };

    const dispatchPageScript = () => {
        if (location?.pathname === "/") {
            getDefaultSitePages().then((res) => {
                if (res?.scripts.length > 0) {
                    const elements = document.getElementsByClassName('pageScript');

                    fnRemoveChild(elements)

                    res?.scripts?.map((value, i) => {
                        let result = value?.replace("const ", "var ");
                        const scriptText = document.createTextNode(`${result}`);

                        const script = document.createElement("script");
                        script.setAttribute("id", "pageScript-" + i)
                        script.setAttribute("class", "pageScript")

                        script.appendChild(scriptText);
                        document.body.appendChild(script);
                        console.log("Pages Script Ready!")

                    })
                }
            })
        } else {
            searchUrl({
                path: location?.pathname
            }).then((res) => {

                if (res?.type === "Site Pages" || res?.type === "Posting") {
                    searchSitePagesWithFiltered({
                        documentID: res?.referralID
                    }).then(async (res) => {
                        try {
                            console.log(res)
                            if (res?.scripts?.length > 0) {
                                const elements = document.getElementsByClassName('pageScript');

                                fnRemoveChild(elements)

                                res.scripts.map((value, i) => {
                                    let result = value.replace("const ", "var ");
                                    const scriptText = document.createTextNode(`${result}`);

                                    const script = document.createElement("script");
                                    script.setAttribute("id", "pageScript-" + i)
                                    script.setAttribute("class", "pageScript")

                                    script.appendChild(scriptText);
                                    setTimeout(() => {
                                        if (script) {
                                            document?.body?.appendChild(script);
                                        }
                                    }, 1000)

                                    console.log("Pages Script Ready!")
                                })
                            }
                        } catch (e) {
                            console.log("cms err:" + e)
                        }
                    })
                }
            })
        }
    };

    const handleBodyHtml = async (res) => {
        if (!res.active) {
            navigate("/")
            return
        }

        const pageID = res.pageID && res.pageID
        setPageID(pageID)

        const languageDetails = res.details.find((item) => item.language === lang)

        const detail = languageDetails?.details ?? res.details.find((item) => item.language === "en")?.details
        if (detail) {
            setBodyHtml(detail)
        }

        const metaDescription = languageDetails?.metaDescription?.description ?? res.metaDescription.find((item) => item.language === "en")?.description
        if (metaDescription) {
            setMetaDescription(metaDescription)
        }

        const metaKeywords = languageDetails?.metaKeywords?.keywords ?? res.metaKeywords.find((item) => item.language === "en")?.keywords
        if (metaKeywords) {
            setMetaKeywords(metaKeywords)
        }

        const metaTitle = languageDetails?.title?.title ?? res.title.find((item) => item.language === "en")?.title
        if (metaTitle) {
            setMetaTitle(metaTitle)
        }

        if (res.sidebarID && res.sidebarID.length > 5) {
            const {details, scripts} = await searchSidebars({documentID: res.sidebarID})
            if (details) {
                const sidebarDetails = details.find((item) => item.language === lang)
                const sidebar = sidebarDetails?.details
                if (sidebar) {
                    setSidebarHtml(sidebar)
                    setSidebarStatus("available")

                    if (scripts) {
                        scripts.forEach((value, i) => {
                            const elementID = document.getElementById(`sidebarjs-${i}`)
                            if (!elementID) {
                                const result = value.replace("const ", "var ")
                                const scriptText = document.createTextNode(result)

                                const script = document.createElement("script")
                                script.setAttribute("id", `sidebarjs-${i}`)
                                script.setAttribute("class", "sidebarjs")
                                script.appendChild(scriptText)

                                setTimeout(() => {
                                    document.body.appendChild(script)
                                }, 500)
                            }
                        })
                    }
                } else {
                    setSidebarStatus("not available")
                }
            } else {
                setSidebarStatus("not available")
            }
        } else {
            setSidebarStatus("not available")
        }
    }

    const dispatchBodyHtml = async () => {
        let res;
        if (location.pathname === "/") {
            res = await getDefaultSitePages();
        } else {
            const searchRes = await searchUrl({path: location.pathname});
            if (searchRes.type === "Site Pages" || searchRes.type === "Posting") {
                res = await searchSitePagesWithFiltered({documentID: searchRes.referralID});
            }
        }
        await handleBodyHtml(res);
        setBodyReady(true);
    };

    const dispatchHeaderCdn = async () => {
        const [pathName] = location.pathname.split('/');
        const {script, link} = pathName === 'header'
            ? await searchUrl({path: location.pathname})
                .then(res => res.type === 'Header' ? searchHeaders({documentID: res.referralID}) : null)
            : await getDefaultHeaders();
        if (script && link) {
            setHeaderScript(script);
            setHeaderLink(link);
        }
    }

    const dispatchHeaderHtml = async () => {
        const [pathName] = location.pathname.split('/');
        const {details} = pathName === 'header'
            ? await searchUrl({path: location.pathname})
                .then(res => res.type === 'Header' ? searchHeaders({documentID: res.referralID}) : null)
            : await getDefaultHeaders();
        const header = details?.find(item => item.language === lang)?.details;
        await setHeaderHtml(header);
        setHeaderReady(true);
    }

    const dispatchFooterHtml = () => {
        if (location.pathname.split('/')[1] === "footer") {
            searchUrl({
                path: location.pathname
            }).then((res) => {
                if (res.type === "Footer") {
                    searchFooters({
                        documentID: res.referralID
                    }).then((res) => {
                        let footer = res.details && res.details.find((item) => item.language === lang).details
                        setFooterHtml(footer)
                        setFooterReady(true)
                    })
                }
            })
        } else {
            getDefaultFooters().then((res) => {
                let footer = res.details && res.details.find((item) => item.language === lang).details
                setFooterHtml(footer)
                setFooterReady(true)
            })
        }

    }

    const dispatchAllSitePage = () => {
        getAllSitePages().then((res) => {
            setAllSitePages(res)
        })
    }

    const dispatchSitePageUrl = () => {
        getUrl().then((res) => {
            setUrls(res)
        })
    }

    const removeScript = () => {
        try {
            const elements = document.getElementsByClassName('pageScript');
            fnRemoveChild(elements);
        } catch (e) {
            console.log("cms err:" + e)
        }
    };

    const fnRemoveChild = (elements) => {
        if (elements && elements?.length > 0) {
            for (let i = elements?.length - 1; i >= 0; i--) {
                try {
                    const element = elements[i];
                    // Check if the element has a parent node before attempting to remove it
                    if (element && element.parentNode) {
                        element.parentNode.removeChild(element);
                    }
                } catch (e) {
                    console.log("cms error:" + e)
                }
            }
        }
    }

    const filterUrl = async () => {
        await setSidebarStatus("");

        const pathSegments = location.pathname.split('/');
        const firstPathSegment = pathSegments[1];

        if (location.pathname === "/" && allSitePages.length > 0) {
            const homepage = allSitePages.find((item) => item.isDefault === true);
            if (homepage) {
                await handleBodyHtml(homepage);
            }
        } else if (!filtering.includes(firstPathSegment)) {
            const documentID = urls.find((item) => item.path === location.pathname)?.referralID;

            if (documentID) {
                const page = allSitePages.find((item) => item._id === documentID);
                if (page) {
                    await handleBodyHtml(page);
                }
            }
        }
    }

    const checkReady = () => {
        if (loading === false && bodyReady === true && cdnReady === true && defaultCssReady === true && headerReady === true && footerReady === true || filtering.includes(location.pathname.split('/')[1])) {
            setTimeout(() => {
                setAllReady(true)
            }, 400)
            // setAllReady(true)
        }
    }

    const fetchBefore = async () => {
        await Promise.allSettled([
            dispatchCss(),
            dispatchHeaderCdn(),
            dispatchCdnList(),
            dispatchLanguage(),
        ]);
    };

    const fetchAfter = async () => {
        await Promise.allSettled([
            dispatchBodyHtml(),
            dispatchHeaderHtml(),
            dispatchFooterHtml(),
            dispatchAllSitePage(),
            dispatchSitePageUrl(),
            dispatchDefaultScript(),
        ]);
    };

    const fetchEveryTime = async () => {
        await removeScript();
        await filterUrl();
        await dispatchPageScript();
        // await Promise.allSettled([removeScript(), filterUrl(), dispatchPageScript()]);
    };

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                await fetchBefore(); // Wait for fetchBefore() to complete before calling fetchAfter()
                await fetchAfter(); // Call fetchAfter() after fetchBefore() is complete
                window.scrollTo({top: 0, behavior: "smooth"});

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                await Promise.all([fetchEveryTime()]);
                window.scrollTo({top: 0, behavior: "smooth"});

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [location]);

    if (allReady === false) {
        checkReady()
        disableScroll.on();
    } else {
        disableScroll.off();
    }

    return (
        <div>
            <style>
                {defaultCss}
            </style>
            <Helmet title={metaTitle}
                    meta={[
                        {
                            name: `description`,
                            content: metaDescription
                        },
                        {
                            name: `keywords`,
                            content: metaKeywords
                        }
                    ]}
                    script={cdnScriptLists}
                    link={cdnCssLists}
            />
            <Helmet
                script={headerScript}
                link={headerLink}
            />
            {
                allReady === false ? (
                    <div id="loading-screen" style={{background: Keys.background}}>
                        <img className={Keys.LOGO_CLASSNAME + " logo"} src={Keys.LOGO}/>
                    </div>
                ) : ""
            }
            <div style={allReady === false ? {visibility: "hidden"} : {visibility: "unset"}}>

                {headerHtml && parse(headerHtml)}

                {
                    sidebarStatus === "available" ? (
                        <div id={pageID} className={"container"}>
                            <div className="row">
                                <div className="col-md-8">
                                    {bodyHtml && parse(bodyHtml)}
                                </div>
                                <div className="col-md-4">
                                    {sidebarHtml && parse(sidebarHtml)}
                                </div>
                            </div>
                        </div>
                    ) : sidebarStatus === "not available" ? (
                        <div id={pageID}>
                            {bodyHtml && parse(bodyHtml)}
                        </div>
                    ) : ""
                }
                {footerHtml && parse(footerHtml)}
            </div>
        </div>
    )


}