import React from 'react';

import Videojs from './video.js';
import 'video.js/dist/video-js.css';
import {Card, Col, List, Row} from 'antd';

const { Meta } = Card;
export default function UserGuide() {

    const data = [
        {
            videoPath: window.location.origin+'/cms_guide/create-site-pages.mp4',
            title: 'Site Pages',
            description: 'How to create a site page',
        },
        {
            videoPath: window.location.origin+'/cms_guide/create-header-footer-sidebar.mp4',
            title: 'Header, Footer & Sidebar',
            description: 'How to create header, footer and sidebar',
        },
        {
            videoPath: window.location.origin+'/cms_guide/create-site-section.mp4',
            title: 'Site Sections',
            description: 'How to create site section',
        },
        {
            videoPath: window.location.origin+'/cms_guide/add-image-plugin.mp4',
            title: 'Media Library & Plug-In',
            description: 'How to add image and plug in ',
        },
    ];


    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Row className="mb-3">
                        <Col span={12}>
                            <h3>User Guide</h3>
                        </Col>
                    </Row>
                    <List
                        grid={{
                            gutter: 8,
                            column: 3,
                        }}
                        dataSource={data}
                        renderItem={(item) => {

                            const videoJsOptions = {
                                autoplay: false,
                                playbackRates: [0.5, 1, 1.25, 1.5, 2],
                                width: "auto",
                                height: "250",
                                controls: true,
                                sources: [
                                    {
                                        src: item.videoPath,
                                        type: 'video/mp4',
                                    },
                                ],
                            };
                            return(
                                <List.Item>
                                    <Card
                                        cover={<Videojs {...videoJsOptions} />}
                                    >
                                        <Meta title={item.title} description={item.description} />
                                    </Card>
                                </List.Item>
                            )
                        }}
                    />
                </div>
            </div>

        </>
    );

};

