import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, message, Row, Select, Tabs, Typography, Upload} from 'antd';

import {useNavigate, useParams} from "react-router-dom";
import {
    addNewPosting,
    addNewPostingBackgroundImg,
    addNewPostingBannerImg,
    addNewPostingFeatureImg,
    addNewPostingStaticImage,
    addNewUrl,
    addSitePagesHistory,
    getAllSectionsCategory,
    getLanguages,
    getSidebars,
    searchPageID,
    searchUrl
} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import {MinusCircleOutlined, UploadOutlined} from "@ant-design/icons";
import CodeMirror from "@uiw/react-codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {html} from "@codemirror/lang-html";

export default function CreatePosting() {

    const [languageList, setLanguageList] = useState(false);
    const [friendlyURL, setFriendlyURL] = useState(false);
    const [friendlyURLInvalid, setFriendlyURLInvalid] = useState(false);
    const [pageIDs, setPageID] = useState("");
    const [pageIDInvalid, setPageIDInvalid] = useState(false);
    const [sidebarList, setSidebarList] = useState(false);
    const [sectionCategory, setSectionCategory] = useState(false);
    const [javascriptCount, setJavascriptCount] = useState([]);
    const [javascriptCountStatus, setJavascriptCountStatus] = useState("");
    const navigate = useNavigate();
    const {TextArea} = Input;
    const {Option} = Select;
    const {Text, Link} = Typography;
    let {infoID} = useParams();
    const [formRef] = Form.useForm();

    const pushLanguagesData = async (item, values) => {

        let custom = {}
        let title = {}
        let shortDescription = {}
        let metaDescription = {}
        let metaKeywords = {}
        let banner = {}
        let crmImage = {}
        let otherImage1 = {}
        let otherImage2 = {}
        let otherImage3 = {}
        let otherImage4 = {}

        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        //title
        var titleClass = "title-" + item.code
        var titleDetails = {
            language: item.code,
            title: values[titleClass],
        }
        title = titleDetails

        //Short Description
        var descriptionShortClass = "shortDescription-" + item.code
        var descriptionShortDetails = {
            language: item.code,
            description: values[descriptionShortClass],
        }
        shortDescription = descriptionShortDetails;

        //Meta Description
        var descriptionClass = "seoDescription-" + item.code
        var descriptionDetails = {
            language: item.code,
            description: values[descriptionClass],
        }
        metaDescription = descriptionDetails;

        var keywordsClass = "seoKeyword-" + item.code
        var keywordsDetails = {
            language: item.code,
            keywords: values[keywordsClass],
        }
        metaKeywords = keywordsDetails;

        var bannerClass = "banner-" + item.code

        if (values[bannerClass] !== undefined) {
            await addNewPostingBannerImg({
                bannerImage: values[bannerClass].file.originFileObj
            }).then((res) => {
                var bannerDetails = {
                    language: item.code,
                    bannerPath: res,
                }
                banner = bannerDetails;
            })

        }

        var CrmImageClass = "crm-image-" + item.code

        if (values[CrmImageClass] !== undefined) {
            await addNewPostingStaticImage({
                image: values[CrmImageClass].file.originFileObj
            }).then((res) => {
                var crmImageDetails = {
                    language: item.code,
                    path: res,
                }
                crmImage = crmImageDetails;
            })

        }

        var Other1ImageClass = "other-image-1-" + item.code

        if (values[Other1ImageClass] !== undefined) {
            await addNewPostingStaticImage({
                image: values[Other1ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage1Details = {
                    language: item.code,
                    path: res,
                }
                otherImage1 = otherImage1Details;
            })

        }

        var Other2ImageClass = "other-image-2-" + item.code

        if (values[Other2ImageClass] !== undefined) {
            await addNewPostingStaticImage({
                image: values[Other2ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage2Details = {
                    language: item.code,
                    path: res,
                }
                otherImage2 = otherImage2Details;
            })

        }

        var Other3ImageClass = "other-image-3-" + item.code

        if (values[Other3ImageClass] !== undefined) {
            await addNewPostingStaticImage({
                image: values[Other3ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage3Details = {
                    language: item.code,
                    path: res,
                }
                otherImage3 = otherImage3Details;
            })

        }

        var Other4ImageClass = "other-image-4-" + item.code

        if (values[Other4ImageClass] !== undefined) {
            await addNewPostingStaticImage({
                image: values[Other4ImageClass].file.originFileObj
            }).then((res) => {
                var otherImage4Details = {
                    language: item.code,
                    path: res,
                }
                otherImage4 = otherImage4Details;
            })

        }

        return {
            custom,
            shortDescription,
            metaDescription,
            metaKeywords,
            title,
            banner,
            crmImage,
            otherImage1,
            otherImage2,
            otherImage3,
            otherImage4
        }
    }

    const onFinish = async (values) => {

        let CustomDetails = [];
        let shortDescription = [];
        let MetaDescription = [];
        let MetaKeywords = [];
        let titles = [];
        let banner = [];
        let crmImage = [];
        let otherImage1 = [];
        let otherImage2 = [];
        let otherImage3 = [];
        let otherImage4 = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {

                CustomDetails.push(v.custom)
                shortDescription.push(v.shortDescription)
                MetaDescription.push(v.metaDescription)
                MetaKeywords.push(v.metaKeywords)
                titles.push(v.title)
                banner.push(v.banner)
                crmImage.push(v.crmImage)
                otherImage1.push(v.otherImage1)
                otherImage2.push(v.otherImage2)
                otherImage3.push(v.otherImage3)
                otherImage4.push(v.otherImage4)

            })
        })

        let urlStatus;
        let defaultUrl;

        do {
            defaultUrl = "/post/" + Math.floor(Math.random() * 9999);
            searchUrl({
                path: defaultUrl
            }).then((res) => {
                if (res === null) {
                    urlStatus = "not used"
                } else {
                    urlStatus = "used"
                }
            })

        } while (urlStatus === "used")

        addSitePagesHistory({
            title: titles,
            sidebarID: values?.sidebarID,
            pageID: pageIDs,
            details: CustomDetails,
            scripts: values?.scripts,
            shortDescription: shortDescription,
            metaDescription: MetaDescription,
            metaKeywords: MetaKeywords,
        }).then(async (history) => {
            let historyIds = [];
            let timeStamp = Math.round((new Date()).getTime() / 1000);
            let date = new Date(timeStamp * 1000)
            await historyIds.push({
                timestamp: timeStamp,
                dateTime: date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString("en-US"),
                id: history._id,
                message: "Initial Commit"
            })
            addNewPosting(
                {
                    title: titles,
                    sidebarID: values?.sidebarID,
                    dynamicPath: "/" + friendlyURL,
                    defaultPath: defaultUrl,
                    pageID: pageIDs,
                    sectionID: infoID,
                    categoryID: values?.categoryID,
                    bannerImage: banner,
                    crmImage: crmImage,
                    otherImage1: otherImage1,
                    otherImage2: otherImage2,
                    otherImage3: otherImage3,
                    otherImage4: otherImage4,
                    details: CustomDetails,
                    scripts: values?.scripts,
                    shortDescription: shortDescription,
                    metaDescription: MetaDescription,
                    metaKeywords: MetaKeywords,
                    historyIDs: historyIds,
                    historyID: history._id,
                }
            ).then((res) => {

                if (values?.featureImage) {
                    addNewPostingFeatureImg({
                        documentID: res._id,
                        file: values?.featureImage.file.originFileObj,
                    })
                }

                if (values?.backgroundImage) {
                    addNewPostingBackgroundImg({
                        documentID: res._id,
                        file: values?.backgroundImage.file.originFileObj,
                    })
                }
                if (values?.seoUrl !== undefined) {
                    addNewUrl({
                        path: "/" + values?.seoUrl,
                        type: "Posting",
                        referralID: res._id,
                    })
                }

                addNewUrl({
                    path: defaultUrl,
                    type: "Posting",
                    referralID: res._id,
                })

                navigate(-1)
                message.success('Article created successfully');
            })
        })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        errorInfo.errorFields && errorInfo.errorFields.map((err) => {
            message.error(err.errors)
        })
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const dispatchSidebars = () => {
        getSidebars().then(async (response) => {
            setSidebarList(response)
        });
    };

    const dispatchSectionCategory = () => {
        getAllSectionsCategory({
            sectionID: infoID
        }).then(async (response) => {
            response.map((res, i) => {
                if (res.type === "sub") {
                    let mainTitle = response.find((item) => item._id === res.mainCategoryID).title
                    response[i]["mainCategoryTitle"] = mainTitle
                }

            })
            setSectionCategory(response)
        });
    };

    const handleFriendlyUrl = (e) => {
        let friendlyURL = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')

        setFriendlyURL(friendlyURL)
        searchUrl({
            path: "/" + friendlyURL
        }).then((res) => {
            if (res === null) {
                setFriendlyURLInvalid(false)
            } else {
                setFriendlyURLInvalid(true)
            }
        })
    }

    const handlePageID = (e) => {
        let pageID = e.target.value.toLowerCase()
            .replace(/ /g, '-').replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '');

        searchPageID({pageID: pageID}).then((res) => {
            if (res !== null) {
                setPageIDInvalid(true)
            } else {
                setPageIDInvalid(false)
            }
        })

        setPageID(pageID)
    }

    const handleJavascriptCount = async () => {
        let jsTextarea = document.getElementsByClassName("jsTextarea");
        let ary = []
        let status = [];
        for (let i = 0; i < jsTextarea.length; i++) {
            let currentData = formRef.getFieldsValue(true);
            let jsLength = currentData.scripts[i].length
            ary.push({
                className: "jsTextarea-" + i,
                length: jsLength,
                status: jsLength > 10000 ? "error" : true,
                maxLength: 10000
            })
            if (jsLength > 10000) {
                status.push("error")
            }

        }
        if (status.length > 0) {
            setJavascriptCountStatus("error")
        } else {
            setJavascriptCountStatus("")
        }
        setJavascriptCount(ary)
        console.log(ary)
    }

    useEffect(() => {
        dispatchLanguages();
        dispatchSidebars();
        dispatchSectionCategory();
    }, []);


    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        name="siteCreate"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={formRef}
                    >
                        <Row>
                            <Col span={12}>
                                <h3>New Article</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit"
                                        disabled={friendlyURLInvalid === true || pageIDInvalid === true ? true : false || javascriptCountStatus === "error"}>
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        {languageList.status === 200 && languageList.list.length > 0 ? (
                            <Col>
                                <Tabs>
                                    <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <div className="mb-2">
                                                    <Col className="mb-2">
                                                        PageID:
                                                    </Col>
                                                    <Col>
                                                        <Form.Item
                                                            name="pageID"
                                                            validateStatus={pageIDInvalid === true ? "error" : ""}
                                                            help={pageIDs !== "" ? pageIDInvalid === true ? "PageID is already in use" : "Your Page ID is " + pageIDs : ""}
                                                        >
                                                            <Input onKeyUp={handlePageID}/>
                                                        </Form.Item>
                                                    </Col>
                                                </div>
                                                {sectionCategory && sectionCategory ?
                                                    <div className="mb-2">
                                                        <Col className="mb-2">
                                                            Category:
                                                        </Col>
                                                        <Col>
                                                            <Form.Item name="categoryID">
                                                                <Select
                                                                    mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    placeholder="Please select"
                                                                >
                                                                    {
                                                                        sectionCategory && sectionCategory.map((sec, index) => (
                                                                            <Option value={sec._id} key={index + 1}>
                                                                                {sec.type === "sub" ? sec.mainCategoryTitle + " > " + sec.title : sec.title}
                                                                            </Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </div> :
                                                    ""
                                                }

                                                <div className="mb-2">
                                                    <Col className="mb-2">
                                                        Sidebar:
                                                    </Col>
                                                    <Col>
                                                        {sidebarList && sidebarList ? (
                                                            <Form.Item name="sidebarID">
                                                                <Select
                                                                    placeholder="Select an option"
                                                                    allowClear
                                                                >
                                                                    <Option value="0">No Sidebar</Option>
                                                                    {
                                                                        sidebarList && sidebarList.map((page, index) => (
                                                                            <Option value={page._id}
                                                                                    key={index + 1}>{page.title}</Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        ) : ""}
                                                    </Col>
                                                </div>
                                                <div className="mb-2">
                                                    <Col className="mb-2">
                                                        Friendly URL:
                                                    </Col>
                                                    <Col>
                                                        <Form.Item
                                                            name="seoUrl"
                                                            validateStatus={friendlyURLInvalid === true ? "error" : ""}
                                                            help={friendlyURLInvalid === true ? "This URL is already in use" : ""}
                                                        >
                                                            <Input onKeyUp={handleFriendlyUrl}/>
                                                        </Form.Item>
                                                    </Col>
                                                </div>


                                                <div className="mb-2">
                                                    <Col className="mb-2">
                                                        Feature Image :
                                                    </Col>
                                                    <Col>
                                                        <Form.Item name={"featureImage"}>
                                                            <Upload
                                                                listType="picture"
                                                                maxCount={1}
                                                            >
                                                                <Button icon={<UploadOutlined/>}>Upload (Max:
                                                                    1)</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Col>
                                                </div>

                                                <div className="mb-2">
                                                    <Col className="mb-2">
                                                        Background Image :
                                                    </Col>
                                                    <Col>
                                                        <Form.Item name={"backgroundImage"}>
                                                            <Upload
                                                                listType="picture"
                                                                maxCount={1}
                                                            >
                                                                <Button icon={<UploadOutlined/>}>Upload (Max:
                                                                    1)</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Col>
                                                </div>

                                            </Col>
                                            <Col span={12}>
                                                <Card title="Friendly URL">
                                                    <p>{window.location.origin}/{friendlyURL}</p>
                                                </Card>
                                            </Col>

                                            <Col span={24}>
                                                <div className="mb-2">
                                                    <Col className="mb-2">
                                                        JavaScript:
                                                    </Col>
                                                    <Col>
                                                        <Form.List
                                                            name="scripts"
                                                        >
                                                            {(fields, {add, remove}, {errors}) => (
                                                                <>
                                                                    {fields.map((field, index) => {

                                                                        let className = "jsTextarea-" + index;
                                                                        let length = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).length;
                                                                        let status = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).status;
                                                                        let maxLength = javascriptCount.find((item) => item.className === className) && javascriptCount.find((item) => item.className === className).maxLength;
                                                                        return (
                                                                            <Form.Item
                                                                                required={false}
                                                                                key={field.key}
                                                                            >
                                                                                <div className="row">
                                                                                    <div className="col-11">
                                                                                        <Form.Item
                                                                                            {...field}
                                                                                            style={{marginBottom: "7px"}}
                                                                                        >
                                                                                            {/*<TextArea*/}
                                                                                            {/*    className={"jsTextarea jsTextarea-" + index}*/}
                                                                                            {/*    onKeyUp={handleJavascriptCount}*/}
                                                                                            {/*    spellCheck={false}*/}
                                                                                            {/*    rows={window.screen.height >= 1000 ? 20 : 10}/>*/}
                                                                                            <CodeMirror
                                                                                                className={"jsTextarea jsTextarea-" + index}
                                                                                                value=""
                                                                                                height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                                                                extensions={[javascript()]}
                                                                                                onKeyUp={handleJavascriptCount}
                                                                                            />
                                                                                        </Form.Item>
                                                                                        {
                                                                                            status !== "error" ? (
                                                                                                <Text
                                                                                                    type="secondary">{length}/{maxLength} Characters</Text>
                                                                                            ) : (
                                                                                                <Text
                                                                                                    type="danger">{length}/{maxLength} Characters</Text>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-1">
                                                                                        {fields.length > 1 ? (
                                                                                            <MinusCircleOutlined
                                                                                                className="dynamic-delete-button"
                                                                                                onClick={() => remove(field.name)}
                                                                                            />
                                                                                        ) : null}
                                                                                    </div>

                                                                                </div>
                                                                            </Form.Item>
                                                                        )
                                                                    })}
                                                                    <Form.Item>
                                                                        <Button
                                                                            type="dashed"
                                                                            onClick={() => add()}
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                        >
                                                                            Add Javascript
                                                                        </Button>

                                                                    </Form.Item>
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Tabs.TabPane>
                                    {languageList.list.map((lang, index) => (
                                        <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"} key={"detail-" + index}
                                                      forceRender={true}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Title:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name={"title-" + lang.code} rules={[{
                                                        required: true,
                                                        message: "Title " + lang.code.toUpperCase() + " is required"
                                                    }]}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Short Descriptions:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"shortDescription-" + lang.code}>
                                                        <TextArea rows={5}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Meta Descriptions:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"seoDescription-" + lang.code}>
                                                        <TextArea rows={5}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Meta Keywords:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"seoKeyword-" + lang.code}>
                                                        <TextArea rows={5}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Details:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"detail-" + lang.code}>
                                                        <CodeMirror
                                                            value=""
                                                            height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                            extensions={[html()]}
                                                        />
                                                        {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 20:10}/>*/}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Banner Image:
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={"banner-" + lang.code}>
                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                        >
                                                            <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4}>
                                                    Crm Image:
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={"crm-image-" + lang.code}>
                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                        >
                                                            <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Other Image 1:
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={"other-image-1-" + lang.code}>
                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                        >
                                                            <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4}>
                                                    Other Image 2:
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={"other-image-2-" + lang.code}>
                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                        >
                                                            <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Other Image 3:
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={"other-image-3-" + lang.code}>
                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                        >
                                                            <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4}>
                                                    Other Image 4:
                                                </Col>
                                                <Col span={7}>
                                                    <Form.Item name={"other-image-4-" + lang.code}>
                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                        >
                                                            <Button icon={<UploadOutlined/>}>Upload (Max: 1)</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    ))}
                                </Tabs>
                            </Col>
                        ) : (languageList.status === 200) ? (
                            <Col className="mt-5">
                                <EmptyLanguage/>
                            </Col>
                        ) : ""
                        }


                    </Form>
                </div>
            </div>
        </>
    );


};

