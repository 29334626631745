import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Form, Input, message, Modal, Row, Tabs} from 'antd';

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    editFooters,
    getLanguages,
    pageChecker,
    searchFooters,
    takeControlPageChecker,
    updatePageChecker
} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import CodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";

export default function EditFooter() {

    const [languageList, setLanguageList] = useState(false);
    const [footerDetails, setFooterDetails] = useState(false);
    const [pageActiveUUID, setPageActiveUUID] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const {TextArea} = Input;
    const [formRef] = Form.useForm();

    let { infoID } = useParams();

    const pushLanguagesData = async (item, values) => {

        let custom = {}

        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        return {custom}
    }

    const onFinish = async (values) => {

        let CustomDetails = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
            })
        })

        editFooters(
            {
                title: values?.title,
                details: CustomDetails,
                documentID:infoID
            }
        ).then((res) => {

            dispatchSitePageDetails();
            message.success('Site page updated successfully');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const dispatchSitePageDetails = () => {

        searchFooters({
            documentID: infoID
        }).then(async (response) => {
            setFooterDetails(response)
            formRef.setFieldsValue({
                title: response.title,
            });
        });
    };

    const pageNotAvailable = () => {
        setPageActiveUUID(false)
        navigate(-1)
    }

    const handleTakeControl = () => {
        let uuids = localStorage.getItem("uuid")
        takeControlPageChecker({
            ip: uuids,
            currentPage: location.pathname
        }).then((res) => {
            updatePageChecker({
                currentPage: res,
                isActive: true,
                ip: uuids
            }).then((r) => {
                setPageActiveUUID(false)
            })

        })
    }

    const handlePageChecker = () => {
        let uuids = localStorage.getItem("uuid")
        pageChecker({
            currentPage: location.pathname,
            ip: uuids
        }).then((res) => {
            if (res.status === "available") {
                setPageActiveUUID(false)
                updatePageChecker({
                    currentPage: location.pathname,
                    isActive: true,
                    ip: uuids
                })
            } else {
                setPageActiveUUID(true)
            }
        })
    }

    const handleKeyUp = () =>{
        handlePageChecker()
    }

    useEffect(() => {
        dispatchLanguages();
        dispatchSitePageDetails();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        name="siteCreate"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={formRef}
                    >
                        <Row>
                            <Col span={12}>
                                <h3>Edit Footer</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit">
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        {languageList.status === 200 && languageList.list.length > 0 ? (
                            <Col>
                                <Tabs>
                                    <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                        <Row gutter={[16, 16]}>
                                            <Col span={4}>
                                                Title:
                                            </Col>
                                            <Col span={20}>
                                                <Form.Item
                                                    name="title" rules={[{ required: true,message:"Title is required" }]}
                                                >
                                                    <Input onKeyUp={handleKeyUp}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Tabs.TabPane>
                                    {footerDetails && languageList.list.map((lang, index) => (
                                        <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"} key={"detail-" + index}
                                                      forceRender={true}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Details:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"detail-" + lang.code}
                                                               initialValue={footerDetails && footerDetails.details.find((res) => res.language === lang.code) !== undefined ? footerDetails.details.find((res) => res.language === lang.code).details : ""}>
                                                        {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 20:10}/>*/}
                                                        <CodeMirror
                                                            value=""
                                                            height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                            extensions={[html()]}
                                                            onKeyUp={handleKeyUp}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    ))}

                                </Tabs>
                            </Col>
                        ) : (languageList.status === 200) ? (
                            <Col className="mt-5">
                                <EmptyLanguage/>
                            </Col>
                        ) : ""
                        }


                    </Form>
                </div>
            </div>
            <Modal title="Warning" visible={pageActiveUUID}
                   okText={"Take Control"}
                   destroyOnClose={true}
                   onOk={handleTakeControl} onCancel={pageNotAvailable}
            >
                <div>
                    <Alert
                        message="Warning"
                        description="Someone is currently editing this page"
                        type="warning"
                        showIcon
                    />
                </div>
            </Modal>
        </>
    );


};

