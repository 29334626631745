import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Image, List, message, Modal, Row, Tooltip, Upload} from "antd";
import {
    CopyOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FundViewOutlined,
    InboxOutlined
} from "@ant-design/icons";
import {createMediaLibrary, deleteMediaLibrary, getMediaLibrary} from "../../service";
import Keys from "../../_config";
import {EmptyData, LoadingScreen} from "../../layout/errorMessage";

const {Dragger} = Upload;
const {confirm} = Modal;
const {Meta} = Card;

export default function MediaLibrary() {
    const [modalVisible, setModalVisible] = useState(false);
    const [mediaState, setMediaState] = useState([]);
    const [mediaList, setMediaList] = useState(false);

    const handleOpenModal = () => {
        setModalVisible(true)
    }

    const handleRemoveMedia = (documentID, fileName) => {
        confirm({
            title: "Do you Want to delete this file?",
            icon: <ExclamationCircleOutlined/>,

            onOk() {
                deleteMediaLibrary({
                    documentID: documentID,
                    fileName: fileName,
                }).then((response) => {
                    message.success('File deleted successfully');
                    dispatchMediaLibrary()
                });
            },

            onCancel() {
            },
        });
    };

    const handleCreateMedia = async () => {
        let totalMediaLength = mediaState.length
        await mediaState.map((value, index) => {
            createMediaLibrary(
                {
                    name: value.name,
                    file: value.originFileObj,
                    extension: value.name.substring(value.name.lastIndexOf('.') + 1, value.name.length),
                }
            ).then((res) => {
                if (index + 1 === totalMediaLength) {
                    dispatchMediaLibrary()
                }
            })
        })

        message.success('File created successfully');
        setModalVisible(false)

    }

    const handleCancelMedia = () => {
        setModalVisible(false)
    }

    const uploadMedia = {
        name: 'media',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        beforeUpload: () => {
            return false;
        }
    };

    const normFile = (e) => {
        setMediaState(e?.fileList)
    };

    const mediaCard = Array.isArray(mediaList) && mediaList.map((media, index) => {

        const copy = async () => {
            await navigator.clipboard.writeText(Keys.MEDIA_URL + media.fileName);
            message.success('URL copied');
        }

        const fileType = ["png", "jpeg", "gif", "tiff", "jpg"];
        return (
            <Col span={4} key={index}>
                <Card
                    style={{
                        width: 150,
                        border: "unset",
                        backgroundColor: "rgba(241,240,240,0.4)"

                    }}
                    bodyStyle={{padding: "7px"}}
                    cover={fileType.includes(media.fileType) ?

                        <div className="px-3">
                            <Image alt="example" src={`${Keys.MEDIA_URL}${media.fileName}`}
                                   style={{height: "150px", objectFit: "contain", border: "unset"}}/>
                        </div> :
                        <div className="px-3">
                            <Image alt="example" src={`${Keys.MEDIA_URL}img.png`}
                                   style={{height: "150px", objectFit: "contain", border: "unset"}}/>
                        </div>
                    }

                    actions={[
                        <a href={`${Keys.MEDIA_URL}${media.fileName}`} target="_blank">
                            <Tooltip placement="bottom" title={`View Image`}>
                                <FundViewOutlined key="view"/>
                            </Tooltip>
                        </a>,
                        <a onClick={copy}>
                            <Tooltip placement="bottom" title={`Copy Image`}>
                                <CopyOutlined key="copy"/>
                            </Tooltip>
                        </a>,
                        <a onClick={() => {
                            handleRemoveMedia(media._id, media.fileName);
                        }}>
                            <Tooltip placement="bottom" title={`Remove Image`}>
                                <DeleteOutlined key="delete"/>
                            </Tooltip>
                        </a>,
                    ]}

                >
                    <Meta title={<p style={{fontSize: "10px", margin: "unset"}}>{media.originFileName}</p>}/>
                </Card>
            </Col>
        )
    })

    const dispatchMediaLibrary = () => {
        getMediaLibrary().then(async (response) => {
            if (response.length > 0) {
                setMediaList(response)
            } else {
                setMediaList("")
            }
        });
    };

    useEffect(() => {
        dispatchMediaLibrary();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {mediaList !== false ?
                        <>
                            <Row className="mb-3">
                                <Col span={12}>
                                    <h3>Media Library</h3>
                                </Col>
                                <Col span={12} className="text-end">
                                    <Button type="primary" shape="round" onClick={handleOpenModal}>
                                        Add New Image
                                    </Button>
                                </Col>
                            </Row>

                            <Col>
                                {Array.isArray(mediaList) && mediaList !== false ?
                                    <List
                                        grid={{gutter: 6, column: 6}}
                                        dataSource={mediaList}
                                        pagination={{
                                            onChange: (page) => {
                                                console.log(page);
                                            },
                                            pageSize: 18,
                                            simple:true,

                                        }}
                                        renderItem={media => {
                                            const copy = async () => {
                                                await navigator.clipboard.writeText(Keys.MEDIA_URL + media.fileName);
                                                message.success('URL copied');
                                            }

                                            const fileType = ["png", "jpeg", "gif", "tiff", "jpg","webp"];
                                            return (
                                                <List.Item>
                                                    <Card
                                                        style={{
                                                            width: 150,
                                                            border: "unset",
                                                            backgroundColor: "rgba(241,240,240,0.4)"

                                                        }}
                                                        bodyStyle={{padding: "7px"}}
                                                        cover={fileType.includes(media.fileType) ?

                                                            <div className="px-3">
                                                                <Image alt="example" src={`${Keys.MEDIA_URL}${media.fileName}`}
                                                                       style={{height: "150px", objectFit: "contain", border: "unset"}}/>
                                                            </div> :
                                                            <div className="px-3">
                                                                <Image alt="example" src={`${Keys.MEDIA_URL}img.png`}
                                                                       style={{height: "150px", objectFit: "contain", border: "unset"}}/>
                                                            </div>
                                                        }

                                                        actions={[
                                                            <a href={`${Keys.MEDIA_URL}${media.fileName}`} target="_blank">
                                                                <Tooltip placement="bottom" title={`View Image`}>
                                                                    <FundViewOutlined key="view"/>
                                                                </Tooltip>
                                                            </a>,
                                                            <a onClick={copy}>
                                                                <Tooltip placement="bottom" title={`Copy Image`}>
                                                                    <CopyOutlined key="copy"/>
                                                                </Tooltip>
                                                            </a>,
                                                            <a onClick={() => {
                                                                handleRemoveMedia(media._id, media.fileName);
                                                            }}>
                                                                <Tooltip placement="bottom" title={`Remove Image`}>
                                                                    <DeleteOutlined key="delete"/>
                                                                </Tooltip>
                                                            </a>,
                                                        ]}

                                                    >
                                                        <Meta title={<p style={{fontSize: "10px", margin: "unset"}}>{media.originFileName}</p>}/>
                                                    </Card>
                                                </List.Item>
                                            )
                                        }
                                        }
                                    />
                                    :
                                    <Col className="text-center" style={{marginTop: "14%"}}>
                                        <EmptyData/>
                                    </Col>}
                            </Col>
                        </>

                        :
                        <LoadingScreen/>
                    }


                </div>
            </div>

            <Modal title="Add New Image" visible={modalVisible} onOk={handleCreateMedia} okText={"Save Changes"}
                   onCancel={handleCancelMedia} destroyOnClose={true}>
                <Form
                    name="mediaCreate"
                    autoComplete="off"
                >
                    <Col>

                        <Form.Item name="images" valuePropName="fileList"
                                   getValueFromEvent={normFile} noStyle>
                            <Dragger {...uploadMedia}>

                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">Click or drag image to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single Image or bulk Image upload only.
                                </p>
                            </Dragger>
                        </Form.Item>

                    </Col>
                </Form>
            </Modal>
        </>
    );
};

