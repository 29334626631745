import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Row, Tabs} from 'antd';

import {useNavigate} from "react-router-dom";
import {addNewFooters, addNewUrl, getLanguages, searchUrl} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import CodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";

export default function CreateFooter() {

    const [languageList, setLanguageList] = useState(false);
    const navigate = useNavigate();
    const {TextArea} = Input;


    const pushLanguagesData = async (item, values) => {

        let custom = {}
        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        return {custom}
    }

    const onFinish = async (values) => {

        let CustomDetails = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
            })
        })

        let urlStatus;
        let defaultUrl;

        do {
            defaultUrl = "/footer/" + Math.floor(Math.random() * 9999);
            searchUrl({
                path: defaultUrl
            }).then((res) => {
                if (res === null) {
                    urlStatus = "not used"
                } else {
                    urlStatus = "used"
                }
            })

        } while (urlStatus === "used")


        addNewFooters(
            {
                title: values.title,
                defaultPath: defaultUrl,
                details: CustomDetails,
            }
        ).then((res) => {
            addNewUrl({
                path: defaultUrl,
                type: "Footer",
                referralID: res._id,
            })

            navigate(-1)
            message.success('Footer created successfully');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };


    useEffect(() => {
        dispatchLanguages();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        name="siteCreate"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <Col span={12}>
                                <h3>New Footer</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit">
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        {languageList.status === 200 && languageList.list.length > 0 ? (
                            <Col>
                                <Tabs>
                                    <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                        <Row gutter={[16, 16]}>
                                            <Col span={4}>
                                                Title:
                                            </Col>
                                            <Col span={20}>
                                                <Form.Item
                                                    name="title" rules={[{ required: true,message:"Title is required" }]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Tabs.TabPane>
                                    {languageList.list.map((lang, index) => (
                                        <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"} key={"detail-" + index}
                                                      forceRender={true}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Details:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"detail-" + lang.code}>
                                                        {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 20:10}/>*/}
                                                        <CodeMirror
                                                            value=""
                                                            height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                            extensions={[html()]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    ))}

                                </Tabs>
                            </Col>
                        ) : (languageList.status === 200) ? (
                            <Col className="mt-5">
                                <EmptyLanguage/>
                            </Col>
                        ) : ""
                        }


                    </Form>
                </div>
            </div>
        </>
    );


};

