import React, {useEffect, useState} from "react";
import {Button, Col, Form, message, Popconfirm, Row, Select, Space, Table, Tag} from 'antd';
import {useNavigate} from "react-router-dom";
import {deleteHeaders, getHeadersListing, updateHeaders} from "../../service";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {LoadingScreen} from "../../layout/errorMessage";

const {Option} = Select;
export default function Header() {
    const navigate = useNavigate();
    const [headerList, setHeaderList] = useState(false);

    const dispatchHeaders = () => {
        getHeadersListing().then(async (response) => {
            setHeaderList(response)
        });
    };

    const handleDefaultHeader = (value) => {

        updateHeaders({
            documentID: value
        }).then((res) => {
            dispatchHeaders();
            message.success('Default header updated successfully');
        })
    }

    const deleteHeader = (id) => {
        deleteHeaders({
            documentID: id,
        }).then((res) => {
            dispatchHeaders();
            message.success('Header deleted successfully');
        });
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Status',
            key: 'isDefault',
            render: (_, record) => (
                <Space size="middle">
                    {(record.isDefault === true) ? <Tag color="success">
                        Default
                    </Tag> : <Tag color="error">
                        Not Default
                    </Tag>}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a href={window.location.origin + "/#" + record.defaultPath} target="_blank">
                        <Button>View</Button>
                    </a>
                    <Button type="primary" key={record.key}
                            onClick={() => navigate('/admin/header-edit/' + record._id)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => deleteHeader(record._id)}
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                    >
                        <Button type="danger">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        dispatchHeaders();
    }, []);


    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {
                        headerList !== false ? (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <h3>Header</h3>
                                    </Col>
                                    <Col span={12} className="text-end">
                                        <Button type="primary" shape="round"
                                                onClick={() => navigate('/admin/header-create')}>
                                            Add New Header
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form layout="vertical">
                                            {headerList && headerList ? (
                                                <Form.Item name="header" label="Select Header" className="w-100"
                                                           initialValue={(headerList && headerList.find(
                                                               (item) => item.isDefault === true)) ? headerList.find(
                                                               (item) => item.isDefault === true)._id : ""}>
                                                    <Select
                                                        placeholder="Select an option"
                                                        onChange={handleDefaultHeader}
                                                        allowClear
                                                    >
                                                        {
                                                            headerList && headerList.map((page, index) => (
                                                                <Option value={page._id}
                                                                        key={index}>{page.title}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            ) : ""}
                                        </Form>
                                    </Col>
                                </Row>

                                <Col>
                                    <Table dataSource={headerList} columns={columns}/>
                                </Col>
                            </>

                        ) : (
                            <LoadingScreen/>
                        )
                    }

                </div>
            </div>
        </>
    );
};

