import React, {useEffect, useState} from "react";
import {Button, Col, message, Popconfirm, Row, Space, Table, Tag} from 'antd';

import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    deleteDraftSitePages,
    deleteSitePages,
    deleteSitePagesHistory,
    deleteStaticImage,
    getAllSectionsCategory,
    getPostingListing,
    searchSitePages
} from "../../service";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {LoadingScreen} from "../../layout/errorMessage";


export default function PostingList() {
    const [postingList, setPostingList] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    let {infoID} = useParams();
    const dispatchSitePages = async () => {

        await setPostingList(false)

        await getAllSectionsCategory({
            sectionID:infoID
        }).then((allCategory)=>{
            getPostingListing({
                sectionID: infoID
            }).then(async (response) => {
                const searchCategoryID = async (res)=>{
                    let res1 = res
                    res1["categoryName"] = []
                    if(res.categoryID.length > 0){
                        res.categoryID.map((id)=>{
                            let categoryDetails = allCategory.length > 0 && allCategory.find((item)=>item._id === id)
                            if(categoryDetails && categoryDetails.type !== undefined && categoryDetails.type === "sub"){
                               let mainTitle =  allCategory.length > 0 && allCategory.find((item)=>item._id === categoryDetails.mainCategoryID).title
                                res1["categoryName"].push(mainTitle + " > " + categoryDetails.title)
                            }else{
                                categoryDetails && res1["categoryName"].push(categoryDetails.title)
                            }

                        })
                    }

                    return res1
                }

                await Promise.all(response.map((item,index)=>searchCategoryID(item))).then((res) => {
                    setPostingList(res)
                })
            });
        })


    };

    const deletePages = (id, historyIDs) => {
        searchSitePages({
            documentID: id
        }).then((res) => {
            if (res.featureImage !== undefined) {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(res.featureImage)[0]
                })
            }
            if (res.backgroundImage !== undefined) {
                deleteStaticImage({
                    fileName: /[^/]*$/.exec(res.backgroundImage)[0]
                })
            }

            res.bannerImage.map((item, i) => {
                if (item.bannerPath !== undefined) {
                    deleteStaticImage({
                        fileName: /[^/]*$/.exec(item.bannerPath)[0]
                    })
                }

            })

        })
        deleteSitePages({
            documentID: id,
        }).then((res) => {

            if (historyIDs.length > 0) {
                historyIDs.map((res, i) => {
                    deleteSitePagesHistory({
                        documentID: res.id
                    })
                })
            }
            deleteDraftSitePages({
                documentID: id
            })
            dispatchSitePages();
            message.success('Post deleted successfully');
        });
    }

    const columns = [
        {
            title: 'Title',
            key: 'title',
            render: (_, record) => (
                <Space size="middle">
                    {record.title[0].title}
                </Space>
            )
        },
        {
            title: 'Category',
            key: 'category',
            render: (_, record) => {

                return(
                    <Space size="middle">
                        {
                            record.categoryName.map((item)=>{
                                return(
                                    <Tag>
                                        {item}
                                    </Tag>
                                )
                            })
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Status',
            key: 'active',
            render: (_, record) => (
                <Space size="middle">
                    {(record.active === true) ? <Tag color="success">
                        Active
                    </Tag> : <Tag color="error">
                        Not Active
                    </Tag>}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={record.defaultPath} target="_blank">
                        <Button>View</Button>
                    </Link>
                    <Button type="primary" key={record.key}
                            onClick={() => navigate('/admin/postingEdit/' + record._id)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => deletePages(record._id, record.historyIDs)}
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                    >
                        <Button type="danger">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        dispatchSitePages();
    }, [location]);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {
                        postingList !== false ? (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <h3>List of Articles</h3>
                                    </Col>
                                    <Col span={12} className="text-end">
                                        <Button type="primary" shape="round"
                                                onClick={() => navigate('/admin/postingCreate/' + infoID)}>
                                            Add New Article
                                        </Button>
                                    </Col>
                                </Row>

                                <Col className="mt-3">
                                    <Table dataSource={postingList} columns={columns}/>
                                </Col>
                            </>

                        ) : (
                            <LoadingScreen/>
                        )
                    }



                </div>
            </div>
        </>
    );
};

