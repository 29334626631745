import React, {useEffect, useState} from "react";
import {Button, Col, message, Popconfirm, Row, Space, Table, Tag} from 'antd';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {deleteSectionCategory, deleteSectionCategoryImage, getSectionsCategory, searchSections} from "../../service";
import {LoadingScreen} from "../../layout/errorMessage";
import Keys from "../../_config";

export default function CategoryList() {
    const [sectionCategoryList, setSectionCategoryList] = useState(false);
    const [sectionSlug, setSectionSlug] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    let {infoID} = useParams();

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Status',
            key: 'active',
            render: (_, record) => (
                <Space size="middle">
                    {(record.active === true) ? <Tag color="success">
                        Active
                    </Tag> : <Tag color="error">
                        Not Active
                    </Tag>}
                </Space>
            ),
        },
        {
            title: 'Type',
            key: 'type',
            render: (_, record) => (
                <Space size="middle">
                    {(record.type === "main") ? <Tag color="blue">
                        Main Category
                    </Tag> : <Tag color="cyan">
                        Sub Category
                    </Tag>}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {

                const copyGetCategoryPosting = async () => {
                    await navigator.clipboard.writeText(Keys.SERVER_URL + '/api/category/getCategoryPosting?name='+ record.slug +'&section='+ sectionSlug +'&lang=en');
                    message.success('API URL copied');
                }

                return (
                    <Space size="middle">
                        {record.type === "main" ?
                            <Button key={record.key} onClick={() => navigate('/admin/addCategory/sub/' + record._id)}>Add
                                Sub Category</Button> : ""}
                        <Button key={record.key} onClick={copyGetCategoryPosting}>Copy Article API</Button>
                        <Button type="primary" key={record.key}
                                onClick={() => navigate('/admin/editCategory/' + record._id)}>Edit</Button>
                        <Popconfirm
                            title="Are you sure ?"
                            onConfirm={() => deleteSectionsCategory(record._id, record.ImageName)}
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                        >
                            <Button type="danger">Delete</Button>
                        </Popconfirm>
                    </Space>
                )
            },
        },
    ];

    const deleteSectionsCategory = (id, ImageName) => {

        deleteSectionCategory({
            documentID: id
        }).then((res) => {
            if (ImageName !== "") {
                deleteSectionCategoryImage({
                    ImageName: ImageName
                })
            }

            dispatchSectionCategory();
            message.success("Deleted Successfully")
        })

    }

    const dispatchSectionCategory = async () => {
        await setSectionCategoryList(false)
        await getSectionsCategory({type: "main", mainCategoryID: "0", sectionID: infoID}).then(async (response) => {
            let sourceData = [];
            await Promise.all(response.map((res, i) => getSectionsCategory({
                    type: "sub",
                    mainCategoryID: res._id,
                    sectionID: infoID
                }).then((sub) => {
                    let main = {}
                    if (sub.length > 0) {
                        main = {
                            key: i + 1,
                            _id: res._id,
                            title: res.title,
                            active: res.active,
                            children: sub,
                            ImageName: res.ImageName,
                            slug: res.slug,
                            type: "main",
                        }
                    } else {
                        main = {
                            key: i + 1,
                            _id: res._id,
                            title: res.title,
                            active: res.active,
                            ImageName: res.ImageName,
                            slug: res.slug,
                            type: "main",
                        }
                    }
                    sourceData.push(main)
                })
            ))
            setSectionCategoryList(sourceData)

        });
    };

    const dispatchSections = () => {
        searchSections({
            documentID: infoID
        }).then((res) => {
            setSectionSlug(res.slug)
        })
    };

    useEffect(() => {
        dispatchSectionCategory();
        dispatchSections();
    }, [location]);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">

                    {
                        sectionCategoryList !== false ? (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <h3>List of Categories</h3>
                                    </Col>
                                    <Col span={12} className="text-end">
                                        <Button type="primary" shape="round"
                                                onClick={() => navigate('/admin/addCategory/main/' + infoID)}>
                                            Add Main Category
                                        </Button>
                                    </Col>
                                </Row>

                                <Col className="mt-3">
                                    <Table
                                        columns={columns}
                                        dataSource={sectionCategoryList}
                                    />
                                </Col>
                            </>

                        ) : (
                            <LoadingScreen/>
                        )
                    }

                </div>
            </div>
        </>
    );
};

