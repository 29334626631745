import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Col, Form, Input, message, Modal, Row, Space, Tabs} from 'antd';

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    editHeaders,
    getLanguages,
    pageChecker,
    searchHeaders,
    takeControlPageChecker,
    updatePageChecker
} from "../../service";
import {EmptyLanguage} from "../../layout/errorMessage";
import {MinusCircleOutlined} from "@ant-design/icons";
import CodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";

export default function EditHeader() {

    const [languageList, setLanguageList] = useState(false);
    const [headerDetails, setHeaderDetails] = useState(false);
    const [pageActiveUUID, setPageActiveUUID] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const {TextArea} = Input;
    const [formRef] = Form.useForm();

    let {infoID} = useParams();

    const pushLanguagesData = async (item, values) => {

        let custom = {}

        //custom
        var customClass = "detail-" + item.code
        var customDetails = {
            language: item.code,
            details: values[customClass],
        }
        custom = customDetails

        return {custom}
    }

    const onFinish = async (values) => {

        let CustomDetails = [];

        await Promise.all(languageList.list.map((item, index) => pushLanguagesData(item, values))).then((res) => {

            res.map((v, i) => {
                CustomDetails.push(v.custom)
            })
        })

        editHeaders(
            {
                title: values?.title,
                details: CustomDetails,
                documentID: infoID,
                script: values?.script,
                link: values?.link,
            }
        ).then((res) => {

            dispatchSitePageDetails();
            message.success('Site page updated successfully');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dispatchLanguages = () => {
        getLanguages().then(async (response) => {
            setLanguageList(response)
        });
    };

    const dispatchSitePageDetails = () => {

        searchHeaders({
            documentID: infoID
        }).then(async (response) => {
            setHeaderDetails(response)
            formRef.setFieldsValue({
                title: response.title,
                script: response.script,
                link: response.link
            });
        });
    };

    const pageNotAvailable = () => {
        setPageActiveUUID(false)
        navigate(-1)
    }

    const handleTakeControl = () => {
        let uuids = localStorage.getItem("uuid")
        takeControlPageChecker({
            ip: uuids,
            currentPage: location.pathname
        }).then((res) => {
            updatePageChecker({
                currentPage: res,
                isActive: true,
                ip: uuids
            }).then((r) => {
                setPageActiveUUID(false)
            })

        })
    }

    const handlePageChecker = () => {
        let uuids = localStorage.getItem("uuid")
        pageChecker({
            currentPage: location.pathname,
            ip: uuids
        }).then((res) => {
            if (res.status === "available") {
                setPageActiveUUID(false)
                updatePageChecker({
                    currentPage: location.pathname,
                    isActive: true,
                    ip: uuids
                })
            } else {
                setPageActiveUUID(true)
            }
        })
    }

    const handleKeyUp = () =>{
        handlePageChecker()
    }

    useEffect(() => {
        dispatchLanguages();
        dispatchSitePageDetails();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">
                    <Form
                        name="siteCreate"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={formRef}
                    >
                        <Row>
                            <Col span={12}>
                                <h3>Edit Header</h3>
                            </Col>
                            <Col span={12} className="text-end">
                                <Button type="primary" shape="round" htmlType="submit">
                                    Save Changes
                                </Button>
                                <Button shape="round" className="ms-2" onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Col>
                        </Row>

                        {languageList.status === 200 && languageList.list.length > 0 ? (
                            <Col>
                                <Tabs>
                                    <Tabs.TabPane tab={"General"} key={1} forceRender={true}>

                                        <Row gutter={[16, 16]}>
                                            <Col span={4}>
                                                Title:
                                            </Col>
                                            <Col span={20}>
                                                <Form.Item
                                                    name="title"
                                                    rules={[{required: true, message: "Title is required"}]}
                                                >
                                                    <Input onKeyUp={handleKeyUp}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Card size="small" title="Script CDN" style={{width: "100%"}}>
                                            <Row gutter={[16, 16]} className="mt-3">
                                                <Col span={24}>
                                                    <Form.List name="script">
                                                        {(fields, {add, remove}) => (
                                                            <>
                                                                {fields.map(({key, name, ...restField}) => (
                                                                    <Space
                                                                        key={key}
                                                                        style={{
                                                                            display: 'flex',
                                                                            marginBottom: 8,
                                                                        }}
                                                                        align="baseline"
                                                                    >
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'src']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing src',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Src" onKeyUp={handleKeyUp}/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'integrity']}
                                                                        >
                                                                            <Input placeholder="Integrity" onKeyUp={handleKeyUp}/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'crossorigin']}
                                                                        >
                                                                            <Input placeholder="Cross Origin" onKeyUp={handleKeyUp}/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'referrerpolicy']}
                                                                        >
                                                                            <Input placeholder="Referrer Policy" onKeyUp={handleKeyUp}/>
                                                                        </Form.Item>
                                                                        <MinusCircleOutlined
                                                                            onClick={() => remove(name)}/>
                                                                    </Space>
                                                                ))}

                                                                <Form.Item>
                                                                    <Button type="dashed" onClick={() => add()} block>
                                                                        Add Script CDN
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <Card size="small" title="Link CDN" style={{width: "100%", marginTop: "3%"}}>
                                            <Row gutter={[16, 16]} className="mt-3">
                                                <Col span={24}>
                                                    <Form.List name="link">
                                                        {(fields, {add, remove}) => (
                                                            <>
                                                                {fields.map(({key, name, ...restField}) => (
                                                                    <Space
                                                                        key={key}
                                                                        style={{
                                                                            display: 'flex',
                                                                            marginBottom: 8,
                                                                        }}
                                                                        align="baseline"
                                                                    >
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'href']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing Href',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Href" onKeyUp={handleKeyUp}/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'rel']}
                                                                        >
                                                                            <Input placeholder="Rel" onKeyUp={handleKeyUp} />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'integrity']}
                                                                        >
                                                                            <Input placeholder="Integrity" onKeyUp={handleKeyUp}/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'crossorigin']}
                                                                        >
                                                                            <Input placeholder="Cross Origin" onKeyUp={handleKeyUp}/>
                                                                        </Form.Item>

                                                                        <MinusCircleOutlined
                                                                            onClick={() => remove(name)}/>
                                                                    </Space>
                                                                ))}

                                                                <Form.Item>
                                                                    <Button type="dashed" onClick={() => add()} block>
                                                                        Add Link CDN
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </Col>
                                            </Row>
                                        </Card>

                                    </Tabs.TabPane>
                                    {headerDetails && languageList.list.map((lang, index) => (
                                        <Tabs.TabPane tab={lang.code.toUpperCase() + " Detail"} key={"detail-" + index}
                                                      forceRender={true}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={4}>
                                                    Details:
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name={"detail-" + lang.code}
                                                               initialValue={headerDetails && headerDetails.details.find((res) => res.language === lang.code) !== undefined ? headerDetails.details.find((res) => res.language === lang.code).details : ""}>
                                                        {/*<TextArea spellCheck={false} rows={window.screen.height >= 1000? 20:10}/>*/}
                                                        <CodeMirror
                                                            value=""
                                                            height={window.screen.height >= 1000 ? "500px" : "400px"}
                                                            extensions={[html()]}
                                                            onKeyUp={handleKeyUp}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    ))}

                                </Tabs>
                            </Col>
                        ) : (languageList.status === 200) ? (
                            <Col className="mt-5">
                                <EmptyLanguage/>
                            </Col>
                        ) : ""
                        }


                    </Form>
                </div>
            </div>
            <Modal title="Warning" visible={pageActiveUUID}
                   okText={"Take Control"}
                   destroyOnClose={true}
                   onOk={handleTakeControl} onCancel={pageNotAvailable}
            >
                <div>
                    <Alert
                        message="Warning"
                        description="Someone is currently editing this page"
                        type="warning"
                        showIcon
                    />
                </div>
            </Modal>
        </>
    );


};

