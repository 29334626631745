import React, {useEffect, useState} from "react";
import {Button, Col, Form, message, Popconfirm, Row, Select, Space, Table, Tag} from 'antd';
import {useNavigate} from "react-router-dom";
import {deleteFooters, getFootersListing, updateFooters} from "../../service";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {LoadingScreen} from "../../layout/errorMessage";

const {Option} = Select;
export default function Footer() {
    const navigate = useNavigate();
    const [footerList, setFooterList] = useState(false);

    const dispatchFooters = () => {
        getFootersListing().then(async (response) => {
            setFooterList(response)
        });
    };

    const handleDefaultFooter = (value) => {

        updateFooters({
            documentID: value
        }).then((res) => {
            dispatchFooters();
            message.success('Default footer updated successfully');
        })
    }

    const deleteFooter = (id) => {
        deleteFooters({
            documentID: id,
        }).then((res) => {
            dispatchFooters();
            message.success('Footer deleted successfully');
        });
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Status',
            key: 'isDefault',
            render: (_, record) => (
                <Space size="middle">
                    {(record.isDefault === true) ? <Tag color="success">
                        Default
                    </Tag> : <Tag color="error">
                        Not Default
                    </Tag>}
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a href={window.location.origin + "/#" + record.defaultPath} target="_blank">
                        <Button>View</Button>
                    </a>
                    <Button type="primary" key={record.key}
                            onClick={() => navigate('/admin/footer-edit/' + record._id)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => deleteFooter(record._id)}
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                    >
                        <Button type="danger">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        dispatchFooters();
    }, []);

    return (
        <>
            <div className="sitepages">
                <div className="site-layout-background">


                    {
                        footerList !== false ? (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <h3>Footer</h3>
                                    </Col>
                                    <Col span={12} className="text-end">
                                        <Button type="primary" shape="round"
                                                onClick={() => navigate('/admin/footer-create')}>
                                            Add New Footer
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form layout="vertical">
                                            {footerList && footerList ? (
                                                <Form.Item name="footer" label="Select Footer" className="w-100"
                                                           initialValue={(footerList && footerList.find(
                                                               (item) => item.isDefault === true)) ? footerList.find(
                                                               (item) => item.isDefault === true)._id : ""}>
                                                    <Select
                                                        placeholder="Select an option"
                                                        onChange={handleDefaultFooter}
                                                        allowClear
                                                    >
                                                        {
                                                            footerList && footerList.map((page, index) => (
                                                                <Option value={page._id}
                                                                        key={index}>{page.title}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            ) : ""}
                                        </Form>
                                    </Col>
                                </Row>

                                <Col>
                                    <Table dataSource={footerList} columns={columns}/>
                                </Col>
                            </>

                        ) : (
                            <LoadingScreen/>
                        )
                    }

                </div>
            </div>
        </>
    );
};

