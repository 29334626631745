import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
    getSectionsCategoryByID,
    getSubSectionCategory,
    searchSectionBySlug,
    searchSectionCategoryBySlug,
    searchSectionMainCategoryBySection,
    searchSectionPostingBySection,
    searchSectionPostingBySectionCategory
} from "../../service";
import Keys from "../../_config";

export function GetSectionCategory() {
    const [sectionCategoryData, setSectionCategoryData] = useState([]);
    let {sectionSlug} = useParams();
    const location = useLocation();
    const dispatchSectionCategory = (lang) => {
        searchSectionBySlug({
            slug: sectionSlug
        }).then((res) => {
            searchSectionMainCategoryBySection({
                documentID: res._id
            }).then(async (response) => {
                let finalResponse = {}
                finalResponse["MainCategoryCount"] = response.length
                finalResponse["data"] = []

                await Promise.all(response.map((main, i) => getSubSectionCategory({
                    mainCategoryID: main._id
                }).then((sub) => {
                    main["subCategoryCount"] = sub.length
                    main["ImageUrl"] = main.ImagePath ? Keys.SERVER_URL + main.ImagePath : ""
                    delete main["ImagePath"]
                    delete main["ImageURL"]
                    delete main["ImageName"]
                    main["subCategory"] = [];
                    sub.map((subCategory, indexSub) => {
                        subCategory["title"] = subCategory.title.find((item) => item.language === lang).title
                        subCategory["ImageUrl"] = subCategory.ImagePath ? Keys.SERVER_URL + subCategory.ImagePath : ""
                        delete subCategory["ImagePath"]
                        delete subCategory["ImageURL"]
                        delete subCategory["ImageName"]
                        main["subCategory"].push(subCategory)
                    })

                    main["title"] = main.title.find((item) => item.language === lang).title
                    finalResponse["data"].push(main)
                })))

                Promise.all(finalResponse["data"]).then(function () {
                    setSectionCategoryData(finalResponse)
                })
            });
        })

    };
    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let lang = params.get('lang')
        if (lang === null) {
            lang = "en"
        }

        dispatchSectionCategory(lang);
    }, []);


    return JSON.stringify(sectionCategoryData, null, 4);
}

export function GetSectionPosting() {
    const [sectionPostingData, setSectionPostingData] = useState([]);
    let {sectionSlug, categorySlug} = useParams();
    const location = useLocation();
    const dispatchSectionCategory = (lang) => {

        if (!categorySlug) {

            searchSectionBySlug({
                slug: sectionSlug
            }).then((res) => {
                if (!categorySlug) {

                    searchSectionPostingBySection({
                        documentID: res._id
                    }).then(async (response) => {

                        let finalResponse = {}
                        finalResponse["postingCount"] = response.length
                        finalResponse["data"] = []

                        const pushObj = async (item) => {
                            let categoryIDs = {}
                            //title

                            await getSectionsCategoryByID({
                                documentID: item
                            }).then((sub) => {

                                if(sub){
                                    categoryIDs = {
                                        title: sub.title.find((item) => item.language === "en") && sub.title.find((item) => item.language === "en").title,
                                        documentID: sub._id
                                    }

                                }

                            })
                            return {categoryIDs}
                        }

                        const result = response.map(async (posting, i) => {

                            let categoryIDs = [];
                            await Promise.all(posting.categoryID.map((item, index) => pushObj(item))).then((res) => {
                                res.map((v, i) => {
                                    categoryIDs.push(v.categoryIDs)
                                })

                            })

                            let bannerPath = posting.bannerImage.find((item) => item.language === lang) && posting.bannerImage.find((item) => item.language === lang).bannerPath;
                            posting["title"] = posting.title.find((item) => item.language === lang) && posting.title.find((item) => item.language === lang).title
                            posting["bannerImage"] = bannerPath !== false ? Keys.SERVER_URL + bannerPath : ""
                            posting["details"] = posting.details.find((item) => item.language === lang) && posting.details.find((item) => item.language === lang).details
                            posting["metaDescription"] = posting.metaDescription.find((item) => item.language === lang) && posting.metaDescription.find((item) => item.language === lang).description
                            posting["metaKeywords"] = posting.metaKeywords.find((item) => item.language === lang) && posting.metaKeywords.find((item) => item.language === lang).keywords
                            posting["categoryID"] = categoryIDs
                            posting["featureImage"] = posting.featureImage !== undefined ? Keys.SERVER_URL + posting.featureImage : ""
                            posting["backgroundImage"] = posting.backgroundImage !== undefined ? Keys.SERVER_URL + posting.backgroundImage : ""


                            return posting;
                        })

                        Promise.all(result).then((res) => {
                            finalResponse["data"].push(res)
                            setSectionPostingData(finalResponse)

                        })
                    });

                }

            })
        } else {

            searchSectionCategoryBySlug({
                slug: categorySlug
            }).then((res) => {
                if (res.type === "main") {

                    let AllCategoryID = [res._id];

                    getSubSectionCategory({
                        mainCategoryID: res._id
                    }).then((subRes) => {
                        subRes.map((sub) => {
                            AllCategoryID.push(sub._id)
                        })

                        searchSectionPostingBySectionCategory({
                            documentID: res.sectionID,
                            categoryID: AllCategoryID
                        }).then((response) => {
                            let finalResponse = {}
                            finalResponse["postingCount"] = response.length
                            finalResponse["data"] = []

                            const pushObj = async (item) => {
                                let categoryIDs = {}
                                //title

                                await getSectionsCategoryByID({
                                    documentID: item
                                }).then((sub) => {

                                    categoryIDs = {
                                        title: sub.title.find((item) => item.language === "en") && sub.title.find((item) => item.language === "en").title,
                                        documentID: sub._id
                                    }

                                })
                                return {categoryIDs}
                            }

                            const result = response.map(async (posting, i) => {

                                let categoryIDs = [];
                                await Promise.all(posting.categoryID.map((item, index) => pushObj(item))).then((res) => {
                                    res.map((v, i) => {
                                        categoryIDs.push(v.categoryIDs)
                                    })

                                })

                                posting["title"] = posting.title.find((item) => item.language === lang) && posting.title.find((item) => item.language === lang).title
                                posting["bannerImage"] = posting.bannerImage.find((item) => item.language === lang) && posting.bannerImage.find((item) => item.language === lang).bannerPath
                                posting["details"] = posting.details.find((item) => item.language === lang) && posting.details.find((item) => item.language === lang).details
                                posting["metaDescription"] = posting.metaDescription.find((item) => item.language === lang) && posting.metaDescription.find((item) => item.language === lang).description
                                posting["metaKeywords"] = posting.metaKeywords.find((item) => item.language === lang) && posting.metaKeywords.find((item) => item.language === lang).keywords
                                posting["categoryID"] = categoryIDs


                                return posting;
                            })

                            Promise.all(result).then((res) => {
                                finalResponse["data"].push(res)
                                setSectionPostingData(finalResponse)

                            })


                        })

                    })

                } else {
                    searchSectionPostingBySectionCategory({
                        documentID: res.sectionID,
                        categoryID: res._id
                    }).then((response) => {
                        let finalResponse = {}
                        finalResponse["postingCount"] = response.length
                        finalResponse["data"] = []

                        const pushObj = async (item) => {
                            let categoryIDs = {}
                            //title

                            await getSectionsCategoryByID({
                                documentID: item
                            }).then((sub) => {

                                categoryIDs = {
                                    title: sub.title.find((item) => item.language === "en") && sub.title.find((item) => item.language === "en").title,
                                    documentID: sub._id
                                }

                            })
                            return {categoryIDs}
                        }

                        const result = response.map(async (posting, i) => {

                            let categoryIDs = [];
                            await Promise.all(posting.categoryID.map((item, index) => pushObj(item))).then((res) => {
                                res.map((v, i) => {
                                    categoryIDs.push(v.categoryIDs)
                                })

                            })

                            posting["title"] = posting.title.find((item) => item.language === lang) && posting.title.find((item) => item.language === lang).title
                            posting["bannerImage"] = posting.bannerImage.find((item) => item.language === lang) && posting.bannerImage.find((item) => item.language === lang).bannerPath
                            posting["details"] = posting.details.find((item) => item.language === lang) && posting.details.find((item) => item.language === lang).details
                            posting["metaDescription"] = posting.metaDescription.find((item) => item.language === lang) && posting.metaDescription.find((item) => item.language === lang).description
                            posting["metaKeywords"] = posting.metaKeywords.find((item) => item.language === lang) && posting.metaKeywords.find((item) => item.language === lang).keywords
                            posting["categoryID"] = categoryIDs


                            return posting;
                        })

                        Promise.all(result).then((res) => {
                            finalResponse["data"].push(res)
                            setSectionPostingData(finalResponse)

                        })
                    })
                }

            })
        }


    };

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let lang = params.get('lang')
        if (lang === null) {
            lang = "en"
        }
        dispatchSectionCategory(lang);
    }, []);

    return <code>{JSON.stringify(sectionPostingData, null, 4)}</code>
}
